import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'shards-react'

import MainFooter from '../components/layout/MainFooter'

const EmptyLayout = ({ children, noNavbar, noFooter }) => (
  <Container fluid>
    <Row>
      <Col
        className='main-content p-0'
        lg={{ size: 10, offset: 2 }}
        md={{ size: 9, offset: 3 }}
        sm='12'
        tag='main'
      >
        {children}
        {!noFooter && <MainFooter />}
      </Col>
    </Row>
  </Container>
)

EmptyLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
}

EmptyLayout.DefaultdefaultProps = {
  noNavbar: false,
  noFooter: false
}

export default EmptyLayout
