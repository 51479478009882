import React from 'react'

import { Dispatcher, Constants } from '../../../flux'

class NavbarToggle extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    })
  }

  render() {
    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href='#'
          onClick={this.handleClick}
          className='nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center'
        >
          <i className='material-icons'>&#xE5D2;</i>
        </a>
      </div>
    )
  }
}

export default NavbarToggle
