import React, { Component } from 'react'
import { Dispatcher, Constants, Store } from '../../flux'
import Pagination from 'react-js-pagination'

class ExercisePagination extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.state = this.getAppState()
    this.state.activePage = 1
    this.pageChange = this.pageChange.bind(this)
  }

  componentDidMount() {
    Store.addChangeListener(this._onChange)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  getAppState() {
    return {
      exercisePagination: Store.getExercisePagination()
    }
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  pageChange(pageNumber) {
    this.setState({
      activePage: pageNumber
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: '', page: pageNumber, pageSize: 10 }
    })
  }

  getExerciseCount() {
    if ('count' in this.state.exercisePagination) {
      return this.state.exercisePagination.count
    }
    return 0
  }

  render() {
    const itemsPerPage = 10
    if (this.getExerciseCount() > itemsPerPage) {
      return (
        <div style={{ padding: 10 }}>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.getExerciseCount()}
            pageRangeDisplayed={15}
            onChange={page => this.pageChange(page)}
            itemClass='pagination-test'
            linkClass='pagination-test'
            activeClass='pagination-test'
            activeLinkClass='pagination-active'
            nextPageText='Next'
            prevPageText='Previous'
            hideFirstLastPages={true}
          />
        </div>
      )
    } else {
      return <div />
    }
  }
}
export default ExercisePagination
