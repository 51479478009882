import React, { Component } from 'react'
import {
  FormInput,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Button,
  CardBody,
  CardFooter,
  FormTextarea,
  Modal,
  ModalBody,
  ModalHeader
} from 'shards-react'

import PageTitle from '../components/common/PageTitle'
import { Store, Dispatcher, Constants } from '../flux'

import PropTypes from 'prop-types'
import store from '../flux/store'

import API from '../utils/api.js'

class MyRecovery extends Component {
  constructor(props) {
    super(props)

    this._onChange = this._onChange.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()

    this.state.copySuccess = false
    this.state.pdfWorking = false
    this.state.open = false
    this.toggle = this.toggle.bind(this)
    this.handleGoalChange = this.handleGoalChange.bind(this)
    this.handleGoalStatementChange = this.handleGoalStatementChange.bind(this)
    this.handleGoalUpdateClick = this.handleGoalUpdateClick.bind(this)
    this.copyCodeToClipboard = this.copyCodeToClipboard.bind(this)
    this.handleConditionDescriptionChange = this.handleConditionDescriptionChange.bind(
      this
    )
  }
  getAppState() {
    return {
      myDetails: store.getMyDetails(),
      pdfDownloadWorking: store.getPDFDownloadWorking()
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    Store.addChangeListener(this._onChange)
    setTimeout(function() {
      Dispatcher.dispatch({
        actionType: Constants.GET_MY_DETAILS
      })

      Dispatcher.dispatch({
        actionType: Constants.SET_MENU_INVISIBLE
      })
    }, 1)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  forceLogin() {
    if (localStorage.getItem('token') === null) {
      this.props.history.push('/landing/')
    }
  }

  formatDate(dateString) {
    const date = new Date(dateString)
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return date.toLocaleDateString('en-US', options)
  }

  colourItem(item) {
    if (item <= 2) {
      return 'red'
    } else if (item == 3) {
      return 'orange'
    } else {
      return 'green'
    }
  }

  handleGoalChange(e) {
    const myDetailsNew = this.state.myDetails
    myDetailsNew.goal = e.target.value
    this.setState({
      myDetails: myDetailsNew
    })
  }

  handleConditionDescriptionChange(e) {
    const myDetailsNew = this.state.myDetails
    myDetailsNew.condition_description = e.target.value
    this.setState({
      myDetails: myDetailsNew
    })
  }

  handleGoalStatementChange(e) {
    const myDetailsNew = this.state.myDetails
    myDetailsNew.goal_statement = e.target.value
    this.setState({
      myDetails: myDetailsNew
    })
  }

  handleGoalUpdateClick(e) {
    const { myDetails } = this.state
    Dispatcher.dispatch({
      actionType: Constants.PATCH_MY_DETAILS,
      payload: {
        goal: myDetails.goal,
        goal_statement: myDetails.goal_statement,
        condition_description: myDetails.condition_description
      }
    })
    this.props.history.push('/routines/')
  }

  calculateProgressPercentage(myDetails) {
    if (myDetails.goal < 1) {
      return 100
    }
    return Math.floor((myDetails.completed / myDetails.goal) * 100)
  }

  ensureNoMappingError(myDetails) {
    if (myDetails['completed_details'] == undefined) {
      myDetails['completed_details'] = []
    }
    if (myDetails['setup_progress'] == undefined) {
      myDetails['setup_progress'] = {
        goal_setup: false,
        routines_setup: false,
        routine_completed: false
      }
    }
    if (myDetails['routines'] == undefined) {
      myDetails['routines'] = []
    }
    console.log(myDetails)
    return myDetails
  }

  copyCodeToClipboard() {
    const textField = document.createElement('textarea')
    textField.innerText =
      'https://play.google.com/store/apps/details?id=com.jacobfionn.recover'
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    this.setState({ copySuccess: true })
  }

  navigateToRoutineDetail(routine) {
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_ROUTINE,
      payload: routine
    })
    this.props.history.push('/routine-details/', { goBackToSetup: true })
  }

  toggle() {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    this.check500()
    this.forceLogin()
    const { open } = this.state
    const myDetails = this.ensureNoMappingError(this.state.myDetails)
    const url = API.getDownloadPDFURL()
    return (
      <Container fluid className='main-content-container px-4'>
        <Row
          style={{
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Col lg='8' className='mb-4'>
            <Card style={{ padding: 10, backgroundColor: '#DFDFDFDF' }}>
              <Card>
                <CardHeader>
                  <h6
                    style={{
                      fontSize: '18px',
                      // fontFamily: "AzoSans",
                      fontWeight: 500,
                      alignItems: 'center'
                    }}
                  >
                    Details of {myDetails.username}
                  </h6>
                </CardHeader>
                <CardBody>
                  <Row form>
                    <Col md='12' className='form-group'>
                      <label htmlFor='feFirstName'>
                        Description Of Condition
                      </label>
                      <FormTextarea
                        onChange={this.handleConditionDescriptionChange}
                        value={myDetails.condition_description}
                      />
                    </Col>
                    {/* Goal Text */}
                    <Col md='12' className='form-group'>
                      <label htmlFor='feFirstName'>Goal Statement</label>
                      <FormTextarea
                        onChange={this.handleGoalStatementChange}
                        value={myDetails.goal_statement}
                      />

                      <label htmlFor='feEmail'>
                        Number Of Routines To Complete
                      </label>
                      <FormInput
                        id='feEmail'
                        type='number'
                        onChange={this.handleGoalChange}
                        value={myDetails.goal}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button theme='success' onClick={this.handleGoalUpdateClick}>
                    {!myDetails.setup_progress.goal_setup ? 'Submit' : 'Update'}
                  </Button>
                  <Button
                    theme='light'
                    style={{ marginLeft: 20 }}
                    onClick={() => this.props.history.push('/routines/')}
                  >
                    Skip, Go To Routines
                  </Button>
                </CardFooter>
              </Card>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

MyRecovery.propTypes = {
  /**
   * The component's title.
   */
  selectedRoutine: PropTypes.object
}

MyRecovery.defaultProps = {
  selectedRoutine: {
    name: 'New Routine',
    length: '30',
    description: '',
    exercises: []
  }
}

export default MyRecovery
