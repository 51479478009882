import React, { Component } from 'react'
import { Alert, Container, Row, Col, Card, Button } from 'shards-react'

import ExerciseDetails from '../components/exercise_library/ExerciseDetails'
import { Store, Dispatcher, Constants } from '../flux'

const EXERCISE_TYPE_DURATION = 1

class UpdateExercise extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    this.handleUpdateExerciseClick = this.handleUpdateExerciseClick.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.renderGoBackToRoutine = this.renderGoBackToRoutine.bind(this)
  }
  getAppState() {
    const selectedExercise = Store.getSelectedExercise()
    selectedExercise['exerciseImage'] = 'Upload an image...'
    const selectedExerciseModifications = {}
    selectedExerciseModifications.name = selectedExercise.name
    selectedExerciseModifications.description = selectedExercise.description
    selectedExerciseModifications.length = selectedExercise.length
    selectedExerciseModifications.type = selectedExercise.type
    selectedExerciseModifications.category = selectedExercise.category

    return {
      selectedExerciseModifications: selectedExerciseModifications,
      selectedExercise: selectedExercise,
      exerciseCategories: Store.getCategories(),
      imageUploaderLabel: 'Upload an image...',
      updateExerciseWorking: Store.getUpdateExerciseWorking(),
      updateExerciseComplete: Store.getUpdateExerciseComplete()
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleEnterPressed)
    Store.addChangeListener(this._onChange)
    Store.setUpdateExerciseWorking(false)
    Store.setUpdateExerciseComplete(false)
    Dispatcher.dispatch({ actionType: Constants.GET_EXERCISE_CATEGORIES })
    this.setState(this.getAppState())
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnterPressed)
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  handleFormChange(e, field) {
    if (this.state.selectedExerciseModifications) {
      const toUpdate = { ...this.state.selectedExerciseModifications }
      toUpdate[field] = e.target.value
      this.setState({ selectedExerciseModifications: toUpdate })
    } else {
    }
  }

  handleCategoryChange(e) {
    if (this.state.selectedExerciseModifications) {
      const index = e.nativeEvent.target.selectedIndex
      const toUpdate = { ...this.state.selectedExerciseModifications }
      toUpdate.category = e.target.value
      toUpdate.category_name = e.nativeEvent.target[index].text
      this.setState({ selectedExerciseModifications: toUpdate })
    } else {
    }
  }

  handleTypeChange(e) {
    if (this.state.selectedExerciseModifications) {
      const toUpdate = { ...this.state.selectedExerciseModifications }
      toUpdate.type = e.target.value
      if (toUpdate.type == EXERCISE_TYPE_DURATION) {
        toUpdate.type_name = 'Seconds'
      } else {
        toUpdate.type_name = 'Repetitions'
      }
      console.log(toUpdate)
      this.setState({ selectedExerciseModifications: toUpdate })
    } else {
      console.log('Type undefined')
    }
  }

  isPositiveInteger(number) {
    if (typeof number === 'number' && number % 1 === 0) {
      return true
    }
  }

  handleUpdateExerciseClick(e) {
    const { selectedExerciseModifications, selectedExercise } = this.state
    if (selectedExerciseModifications.name == '') {
      return
    }
    if (!this.isPositiveInteger(selectedExerciseModifications.length)) {
      selectedExerciseModifications.length = selectedExercise.length
      console.log('Failed to set length')
    } else {
      console.log('Length is positive')
    }
    Dispatcher.dispatch({
      actionType: Constants.PATCH_EXERCISE,
      payload: {
        exerciseUpdates: selectedExerciseModifications,
        selectedExercise: selectedExercise,
        coach: Store.getSelectedRoutine().uuid ? false : true
      }
    })
  }

  handleLengthChange(e) {
    if (!this.state.selectedExerciseModifications) {
      console.log('Exercise modifications not defined')
      return
    }
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const toUpdate = { ...this.state.selectedExerciseModifications }
      toUpdate.length = parseInt(e.target.value)
      this.setState({ selectedExerciseModifications: toUpdate })
    }
  }

  handleImageChange(e) {
    const toUpdate = { ...this.state.selectedExerciseModifications }
    toUpdate.image = e.target.files[0]
    this.setState({
      selectedExerciseModifications: toUpdate,
      imageUploaderLabel: e.target.value
    })
  }

  handleKeyDown(e) {
    if (e.keyCode === 13) {
      this.handleUpdateExerciseClick(e)
    }
  }

  getEventHandlers() {
    return {
      updateClick: e => this.handleUpdateExerciseClick(e),
      lengthChange: e => this.handleLengthChange(e),
      nameChange: e => this.handleFormChange(e, 'name'),
      descriptionChange: e => this.handleFormChange(e, 'description'),
      typeChange: e => this.handleTypeChange(e),
      categoryChange: e => this.handleCategoryChange(e),
      imageChange: e => this.handleImageChange(e),
      keyDown: e => this.handleKeyDown(e)
    }
  }

  renderGoBackToRoutine() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.newRoutine
    ) {
      return (
        <Button
          style={{ border: '1px lightgrey solid' }}
          theme='light'
          onClick={() => {
            this.props.history.push('/new', { dontRefresh: true })
          }}
        >
          {' '}
          Back to exercise program
        </Button>
      )
    } else {
      return (
        <Button
          style={{ border: '1px lightgrey solid' }}
          theme='light'
          onClick={() => {
            this.props.history.push('/editor')
          }}
        >
          {' '}
          Back to exercise program
        </Button>
      )
    }
  }

  renderAlert() {
    if (this.state.updateExerciseWorking) {
      return (
        <Alert theme='warning'>
          <i className='fa fa-info mx-2' />{' '}
          <span style={{ fontSize: 15 }}>Saving...</span>
        </Alert>
      )
    }
    if (this.state.updateExerciseComplete) {
      return (
        <Alert theme='success'>
          <i className='fa fa-info mx-2' />{' '}
          <span style={{ fontSize: 15 }}>Saved</span>
        </Alert>
      )
    }
  }

  render() {
    const {
      selectedExercise,
      exerciseCategories,
      selectedExerciseModifications,
      imageUploaderLabel
    } = this.state
    const clientModifiedExercise = selectedExercise.client ? true : false
    const clientCreatedExercise =
      clientModifiedExercise && selectedExercise.original === null
        ? true
        : false

    return (
      <Container
        style={{
          minHeight: '100vh',
          paddingTop: 10,
          backgroundColor: 'whitesmoke'
        }}
        fluid
        className='main-content-container px-4'
      >
        <Row>
          <Col lg='6' style={{ paddingBottom: '20px' }}>
            <ExerciseDetails
              title={
                'name' in selectedExercise
                  ? selectedExercise.name
                  : 'An error has occured'
              }
              eventHandlers={this.getEventHandlers()}
              exercise={selectedExercise}
              exerciseModifications={selectedExerciseModifications}
              categories={exerciseCategories}
              imageUploaderLabel={imageUploaderLabel}
              clientCreatedExercise={clientCreatedExercise}
              clientModifiedExercise={clientModifiedExercise}
              renderGoBackToRoutine={this.renderGoBackToRoutine}
            />
          </Col>
          <Col lg='6' style={{ paddingBottom: '20px' }}>
            <div>{this.renderAlert()}</div>
            <div>
              <img
                style={{ borderRadius: 5 }}
                width='100%'
                height='auto'
                src={selectedExercise.image}
              />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default UpdateExercise
