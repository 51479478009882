import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormTextarea
} from 'shards-react'

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }))

const CreateRoutine = ({ eventHandlers, buttonText }) => (
  <Card small className='h-100'>
    {/* Card Header */}

    <CardBody className='d-flex flex-column'>
      <Form className='quick-post-form'>
        {/* Title */}
        <FormGroup>
          <FormInput
            onChange={eventHandlers.handleNameChange}
            id='feFirstName'
            placeholder={'Enter a new name'}
          />
        </FormGroup>

        {/* Body */}
        <FormGroup>
          <FormTextarea
            onChange={eventHandlers.handleDescriptionChange}
            placeholder='Description'
          />
        </FormGroup>

        {/* Create Draft */}
        <FormGroup className='mb-0'>
          <Button theme='accent' onClick={eventHandlers.createRoutine}>
            {buttonText}
          </Button>
        </FormGroup>
      </Form>
    </CardBody>
  </Card>
)

CreateRoutine.propTypes = {
  /**
   * The component's title.
   */
  routine: PropTypes.object
}

CreateRoutine.defaultProps = {
  title: 'Routine Details',
  routine: {
    name: 'New Routine',
    length: '30',
    description: '',
    exercises: []
  },
  buttonText: 'Test'
}

export default CreateRoutine
