import React from 'react'
import { CardBody, CardFooter, Container, Card } from 'shards-react'
import PageTitle from '../components/common/PageTitle'

const Disclaimer = () => (
  <Container style={{ padding: 50 }}>
    <div>
      By entering this website you are agreeing to accept all parts of this
      disclaimer. Thus, if you do not agree to the disclaimer below, STOP now,
      and do not use our website.
      <br />
      <br />
      This website is not a licensed medical care provider and represents that
      it has no expertise in diagnosing, examining, or treating medical
      conditions of any kind, or in determining the effect of any specific
      exercise on a medical condition. This information and exercise programs
      are general medical advice only. The text and exercises , including all
      graphics, text and images, (collectively ‘the content’) are intended for
      information purposes only. The content is not a substitute for
      professional medical advice, diagnosis or treatment. Always seek the
      advice of your healthcare professional with any questions you may have
      regarding a medical condition or its treatment. Never disregard
      professional medical advice or delay or in seeking it because of something
      you have read or seen on this website. Always read the instructions
      accompanying each exercise prior to performing the exercise. Cease
      performing exercise if you experience any pain, discomfort, dizziness or
      tingling. In such event, promptly contact your healthcare professional
      prior to continuing with any aspect of this exercise sheet. It is a
      condition that you consult your healthcare professional before beginning
      any exercises on this website.
      <br /> <br />
      You should understand that when participating in any exercise or exercise
      program, there is the possibility of physical injury. If you engage in
      this exercise or exercise program, you agree that you do so at your own
      risk, are voluntarily participating in these activities, assume all risk
      of injury to yourself, and agree to release and discharge this website
      from any and all claims or causes of action, known or unknown, arising out
      of this website and associated software. We aim to accurately represent
      the information provided on this website and and associated software. You
      are acknowledging that you are participating voluntarily in using our
      website and app and you alone are solely and personally responsible for
      your results. You acknowledge that you take full responsibility for your
      health, life and well-being, as well as the health, lives and well-being
      of your family and children (born and unborn, as applicable), and all
      decisions now or in the future.
    </div>
  </Container>
)

export default Disclaimer
