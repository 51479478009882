import React, { Component } from 'react'
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from 'shards-react'

import { Dispatcher, Constants } from '../../../flux'

class SearchForm extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    e.preventDefault()
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: e.target.value, page: 1 }
    })
  }

  render() {
    return (
      <Form onChange={this.onChange} className='main-navbar__search'>
        <InputGroup seamless className='ml-3'>
          <InputGroupAddon type='prepend'>
            <InputGroupText>
              <i className='material-icons'>search</i>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput
            className='navbar-search'
            placeholder='Search for exercises...'
          />
        </InputGroup>
      </Form>
    )
  }
}
export default SearchForm
