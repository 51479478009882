import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  CardBody
} from 'shards-react'

function renderDeleteButton(
  clientModifiedExercise,
  clientCreatedExercise,
  deleteButtonHandler
) {
  if (clientCreatedExercise) {
    return (
      <Button theme='danger' onClick={deleteButtonHandler}>
        Delete exercise
      </Button>
    )
  }
  if (clientModifiedExercise) {
    return (
      <Button theme='warning' onClick={deleteButtonHandler}>
        Reset to original
      </Button>
    )
  }
}

const ExerciseDetails = ({
  eventHandlers,
  exerciseModifications,
  title,
  categories,
  buttonText,
  imageUploaderLabel,
  renderGoBackToRoutine
}) => (
  <div style={{ width: '100%' }}>
    <ListGroup flush>
      <ListGroupItem className='p-3'>
        <Row>
          <Col>
            <Form>
              <Row form>
                <div className='custom-file mb-3'>
                  <input
                    type='file'
                    onChange={eventHandlers.imageChange}
                    className='custom-file-input'
                    id='customFile2'
                  />
                  <label className='custom-file-label' htmlFor='customFile2'>
                    {imageUploaderLabel}
                  </label>
                </div>
              </Row>
              <Row form>
                {/* Name */}
                <Col md='6' className='form-group'>
                  <label htmlFor='feFirstName'>Name</label>
                  <FormInput
                    id='feFirstName'
                    value={exerciseModifications.name}
                    onChange={eventHandlers.nameChange}
                    onKeyDown={eventHandlers.keyDown}
                    placeholder={'This field is required'}
                    required
                  />
                </Col>
                {/* Catgeory */}
                <Col md='6' className='form-group'>
                  <label htmlFor='feInputState'>Category</label>
                  <FormSelect
                    id='feInputState'
                    onChange={eventHandlers.categoryChange}
                    defaultValue={exerciseModifications.category}
                  >
                    <option value='none' selected disabled hidden>
                      {' '}
                      Select a Category
                    </option>
                    {categories.map((category, idx) => (
                      <option value={category.uuid}>{category.name}</option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
              <Row form>
                {/* Length */}
                <Col md='6' className='form-group'>
                  <label htmlFor='feEmail'>Length</label>
                  <FormInput
                    id='feEmail'
                    type='number'
                    value={exerciseModifications.length}
                    onChange={eventHandlers.lengthChange}
                    onKeyDown={eventHandlers.keyDown}
                  />
                </Col>
                {/* Exercise Type */}
                <Col md='6' className='form-group'>
                  <label htmlFor='feInputState'>Units</label>
                  <FormSelect
                    id='feInputState'
                    onChange={eventHandlers.typeChange}
                    defaultValue={exerciseModifications.type}
                  >
                    <option value='1'>Seconds</option>
                    <option value='2'>Repetitions</option>
                  </FormSelect>
                </Col>
              </Row>
              <Row form>
                {/* Description */}
                <Col>
                  <label htmlFor='feDescription'>Description</label>

                  <FormGroup>
                    <FormTextarea
                      rows='5'
                      onChange={eventHandlers.descriptionChange}
                      value={exerciseModifications.description}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <span style={{ marginRight: 5 }}>{renderGoBackToRoutine()}</span>
              <Button theme='accent' onClick={eventHandlers.updateClick}>
                {buttonText}
              </Button>

              {/* {renderDeleteButton(
                  clientModifiedExercise,
                  clientCreatedExercise,
                  eventHandlers
                )} */}
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </div>
)

ExerciseDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  exercise: PropTypes.object,
  updateExerciseClick: PropTypes.func
}

ExerciseDetails.defaultProps = {
  title: 'Exercise Details',
  exercise: {
    name: 'New Exercise',
    length: '30',
    description: '',
    category: '',
    category_name: ''
  },
  eventHandlers: {
    updateClick: () => console.log('Update exercise clicked'),
    lengthChange: () => console.log('Length changed'),
    nameChange: () => console.log('Name changed'),
    categoryChange: () => console.log('Category changed'),
    typeChange: () => console.log('Type changed'),
    descriptionChange: () => console.log('Description changed'),
    keyDown: () => console.log('Key pressed'),
    imageChange: () => console.log('Image changed')
  },
  categories: [],
  requireFields: true,
  buttonText: 'Update Exercise'
}

export default ExerciseDetails
