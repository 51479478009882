import {
  Alert,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  FormSelect,
  Button
} from 'shards-react'

import arrayMove from 'array-move'
import { Store, Dispatcher, Constants } from '../flux'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PersonSharpIcon from '@material-ui/icons/PersonSharp'
import DescriptionIcon from '@material-ui/icons/Description'
import React, { Component } from 'react'
import ExerciseLibrary from './ExerciseLibrary'
import ExerciseLibraryPopup from './ExerciseLibraryPopup'
import ReactLoading from 'react-loading'
import EditableExercise from '../components/routine_library/EditableExercise'
import CurrentExercises from '../components/routine_library/CurrentExercises'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import api from '../utils/api'

class App extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.renderExercises = this.renderExercises.bind(this)
    this.state = this.getAppState()
    this.closeExerciseModal = this.closeExerciseModal.bind(this)
    this.editExercise = this.editExercise.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
  }

  getAppState() {
    return {
      name: Store.getRoutineCurrentName(),
      addExerciseOpen: Store.getExerciseLibraryOpen(),
      exercises: Store.getExerciseLibrary(),
      selectedRoutine: Store.getSelectedRoutine(),
      categories: Store.getCategories(),
      addExerciseWorking: Store.getAddingExerciseWorking(),
      selectRoutineWorking: Store.getSelectRoutineWorking(),
      routineCopyWorking: Store.getRoutineCopyWorking(),
      routineEditMode: Store.getRoutineEditMode(),
      client: Store.getMyDetails(),
      category: Store.getRoutineCurrentCategory()
    }
  }
  componentDidMount() {
    Store.addChangeListener(this._onChange)
    Store.setExerciseSearchTerm('')
    Dispatcher.dispatch({ actionType: Constants.GET_CATEGORIES })

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.dontRefresh
    ) {
      console.log('Same program.  ')
    } else {
      {
        Dispatcher.dispatch({
          actionType: Constants.SET_SELECTED_ROUTINE,
          payload: { name: '', exercises: [] }
        })
      }
    }
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  editExercise(exercise) {
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_EXERCISE,
      payload: exercise
    })
    this.props.history.push('/edit-exercise/', {
      goBackToRoutine: true,
      newRoutine: true
    })
  }

  renderExercises(routine) {
    return (
      <EditableExercise
        routine={routine}
        eventHandlers={{}}
        routineEditMode={true}
        editExercise={this.editExercise}
      />
    )
  }

  addExercise() {
    return (
      <Modal
        size='lg'
        open={this.state.addExerciseOpen}
        toggle={() => Store.toggleExerciseLibraryOpen()}
      >
        <ModalBody
          style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}
        >
          <ExerciseLibraryPopup
            history={this.props.history}
            newRoutine={true}
          />
        </ModalBody>
      </Modal>
    )
  }

  handleAddRoutine() {
    let routine = Store.getSelectedRoutine()
    routine.name = this.state.name
    routine.category = this.state.category
    let exercise_uuids = []
    for (let i = 0; i < routine.exercises.length; i++) {
      exercise_uuids.push(routine.exercises[i].uuid)
    }
    routine.exercise_uuids = exercise_uuids
    if (!routine.uuid) {
      console.log('POSTED')
      api.postRoutine(routine, this.props.history)
    } else {
      console.log('PATCHED')
      api.patchRoutineExercises(
        { exercises: exercise_uuids },
        routine,
        this.props.history
      )
    }
    Store.setRoutineCurrentName('')
    Store.setRoutineCurrentCategory('')
  }

  closeExerciseModal() {
    this.setState({ addExerciseOpen: false })
  }

  handleNameChange(e) {
    Store.setRoutineCurrentName(e.target.value)
  }

  handleCategoryChange(e) {
    Store.setRoutineCurrentCategory(e.target.value)
  }

  render() {
    const modal = this.addExercise()
    if (this.state.routineCopyWorking) {
      return (
        <div
          style={{
            backgroundColor: 'lightslategrey',
            color: 'white',
            padding: 25,
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h3 style={{ color: 'white' }}>Opening...</h3>
        </div>
      )
    }

    return (
      <div>
        {modal}
        <div style={{ bottom: 50, right: 25, position: 'fixed', zIndex: 3000 }}>
          <div>
            {' '}
            <FormSelect
              style={{ width: 150 }}
              id='feInputState'
              value={Store.getRoutineCurrentCategory()}
              onChange={this.handleCategoryChange}
            >
              <option value='none' selected disabled hidden>
                {' '}
                Select a Category
              </option>
              {this.state.categories.map((category, idx) => (
                <option value={category.uuid}>{category.name}</option>
              ))}
            </FormSelect>
          </div>

          <Button
            size='md'
            style={{
              width: 150,
              marginTop: 10,
              border: '1px lightgrey solid'
            }}
            onClick={() => this.handleAddRoutine()}
            theme='success'
            disabled={!this.state.name}
          >
            {this.state.selectRoutineWorking ? 'Working' : 'Save Routine'}
          </Button>
        </div>
        <div
          style={{
            position: 'fixed',
            zIndex: 999,
            height: 50,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            alignItems: 'center',
            display: 'flex',
            width: '100%'
          }}
        >
          {/* left */}
          <div style={{ width: '100%' }}>
            {' '}
            <span
              onClick={() => {
                this.props.history.push('/routines')
              }}
              style={{ cursor: 'pointer', marginLeft: 10, color: 'white' }}
            >
              Back to library
            </span>{' '}
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {' '}
            <span style={{ color: 'white' }}>
              {' '}
              <FormInput
                style={{ display: 'inline-block' }}
                value={Store.getRoutineCurrentName()}
                onChange={this.handleNameChange}
                id='feFirstName'
                placeholder={'Name (required)'}
              />
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <span>
              {' '}
              <span
                style={{ cursor: 'pointer', color: 'white', marginRight: 10 }}
                onClick={() => {
                  Store.toggleExerciseLibraryOpen()
                }}
              >
                <AddCircleIcon style={{ marginBottom: 3, marginRight: 5 }} />
                Add exercise{' '}
              </span>
            </span>
          </div>
        </div>
        <div
          style={{
            minHeight: '100vh',
            paddingTop: 60,
            justifyContent: 'center',
            backgroundColor: 'lightslategrey',
            display: 'flex',
            width: '100%',
            flexDirection: 'row'
          }}
        >
          <div
            style={{
              lineHeight: 1.3,
              paddingLeft: '50px',
              paddingRight: '50px',

              boxShadow: 'rgba(0, 0, 0, 0.36) 2px 4px 4px',

              width: '60%',
              backgroundColor: 'white'
            }}
          >
            <table
              style={{
                margin: 0,
                borderCollapse: 'separate',
                borderSpacing: '0 15px',
                width: '100%',
                paddingTop: '5px'
              }}
            >
              {this.renderExercises(this.state.selectedRoutine)}
            </table>
          </div>
        </div>
      </div>
    )
  }
}
export default App
