import { Row, Col, Container } from 'shards-react'

import React, { Component } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Store, Dispatcher, Constants } from '../../flux'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const SortableItem = SortableElement(
  ({ value, sortIndex, props, removeExercise, editExercise, copyExercise }) => (
    <tr style={{ cursor: 'pointer' }}>
      <td
        style={{
          verticalAlign: 'top',
          borderBottom: '1px solid #ddd',
          paddingRight: '30px'
        }}
      >
        <img src={value.image} style={{ width: '200px', height: 'auto' }} />{' '}
      </td>
      <td
        style={{
          color: '#191919',
          paddingBottom: '15px',
          // verticalAlign: "top",
          borderBottom: '1px solid #ddd',
          width: '100%'
        }}
      >
        <div style={{ padding: 2, backgroundColor: 'whitesmoke' }}>
          <span
            style={{
              // borderRadius: 3,
              //  fontWeight: "bolder",
              color: 'black',
              // fontWeight: '500',
              backgroundColor: 'whitesmoke'
            }}
          >
            {value.name}
          </span>

          <span
            style={{
              padding: 0,
              cursor: 'pointer',
              color: 'white',
              float: 'right',
              // borderRadius: 3,
              paddingLeft: 2,
              paddingRight: 2,
              // borderRadius: 15
            }}
          >
            <i
              style={{

                cursor: 'pointer',
                color: 'lightseagreen'
              }}
              size={2}
              onClick={() => editExercise(value)}
              class='material-icons sm-12'
            >
              tune
            </i>
            <i
              size={2}
              onClick={() => copyExercise(value)}
              class='material-icons sm-12'
              style={{ marginLeft: '10px', color: "lightseagreen" }}
            >
              content_copy
            </i>

            <span
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
                color: 'white',
                float: 'right'
              }}
            >
              {' '}
              <i
                size={2}
                style={{ color: 'lightseagreen' }}
                onClick={() => removeExercise(value.order)}
                class='material-icons sm-12'
              >
               clear
              </i>
            </span>
          </span>
        </div>
        <div style={{ marginTop: 5, padding: 2 }}>
          {' '}
          <span style={{ fontWeight: 500 }}>{value.length} </span>
          {value.type_name}{' '}
        </div>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            color: 'black',
            paddingTop: '10px'
          }}
        >
          {' '}
          {value.description}{' '}
        </div>{' '}
      </td>
    </tr>
  )
)

const SortableList = SortableContainer(
  ({ items, props, removeExercise, editExercise, copyExercise }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            editExercise={editExercise}
            removeExercise={removeExercise}
            copyExercise={copyExercise}
            disabled={!props.routineEditMode}
            key={`item-${value}`}
            index={index}
            value={value}
            sortIndex={index}
            props={props}
          />
        ))}
      </div>
    )
  }
)

class CurrentExercises extends Component {
  constructor(props) {
    super(props)

    console.log(Store.getSelectedRoutine())
    this.state = {
      items: []
    }
    this.getAppState = this.getAppState.bind(this)
    this._onChange = this._onChange.bind(this)
    this.removeExercise = this.removeExercise.bind(this)
    this.copyExercise = this.copyExercise.bind(this)

    // Field Edit Functions
  }

  // ************* State Management ***********************

  componentDidMount() {
    Store.addChangeListener(this._onChange)
    this.setState(this.getAppState)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  getAppState() {
    return {
      selectedRoutine: Store.getSelectedRoutine(),
      items: Store.getSelectedRoutine().exercises,
      routineEditMode: Store.getRoutineEditMode()
    }
  }

  // ************* Editor Functions **********************

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex)
    }))

    let routineCopy = Store.getSelectedRoutine()
    routineCopy.exercises = this.state.items

    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_ROUTINE,
      payload: routineCopy
    })

    let exerciseIDs = []
    for (let i = 0; i < this.state.items.length; i++) {
      exerciseIDs.push(this.state.items[i].uuid)
    }

    Dispatcher.dispatch({
      actionType: Constants.PATCH_ROUTINE_EXERCISES,
      payload: {
        routineExerciseUpdates: { exercises: exerciseIDs },
        selectedRoutine: Store.getSelectedRoutine()
      }
    })
  }

  removeExercise(exerciseOrder) {
    let newRoutine = Store.getSelectedRoutine()
    let newItems = [...this.state.items]
    newRoutine.exercises = newItems

    for (let i = 0; i < newRoutine.exercises.length; i++) {
      if (newRoutine.exercises[i].order === exerciseOrder) {
        newRoutine.exercises.splice(i, 1)
      }
    }
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_ROUTINE,
      payload: newRoutine
    })

    let exerciseIDs = []
    for (let i = 0; i < newRoutine.exercises.length; i++) {
      exerciseIDs.push(newRoutine.exercises[i].uuid)
    }

    Dispatcher.dispatch({
      actionType: Constants.PATCH_ROUTINE_EXERCISES,
      payload: {
        routineExerciseUpdates: { exercises: exerciseIDs },
        selectedRoutine: newRoutine
      }
    })
  }

  copyExercise(exercise) {
    if (!this.state.selectedRoutine.uuid) {
      let selectedRoutine = Store.getSelectedRoutine()
      exercise.order = selectedRoutine.exercises.length + 1
      selectedRoutine.exercises.push(exercise)
      Dispatcher.dispatch({
        actionType: Constants.SET_SELECTED_ROUTINE,
        payload: selectedRoutine
      })
    } else {
      Dispatcher.dispatch({
        actionType: Constants.PATCH_ROUTINE,
        payload: {
          routineUpdates: { exercise: exercise.uuid },
          selectedRoutine: Store.getSelectedRoutine()
        }
      })
    }
  }

  // ************* Field Edit Mode **********************

  render() {
    if (this.state.items && this.state.items.length > 0) {
      return (
        <div>
          <div style={{}}>
            <SortableList
              axis={'y'}
              items={this.state.items}
              onSortEnd={this.onSortEnd}
              props={this.props}
              removeExercise={this.removeExercise}
              copyExercise={this.copyExercise}
              editExercise={this.props.editExercise}
              distance={1}
            />
          </div>
          <div style={{ margin: 40, textAlign: 'center' }}>
            <span
              onClick={() => {
                Store.toggleExerciseLibraryOpen()
              }}
              style={{ cursor: 'pointer' }}
            >
              {' '}
              <AddCircleIcon
                style={{
                  color: 'lightseagreen',
                  marginBottom: 3,
                  marginRight: 5
                }}
              />{' '}
              Add exercise
            </span>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ margin: 40, textAlign: 'center' }}>
          <span
            onClick={() => {
              Store.toggleExerciseLibraryOpen()
            }}
            style={{ cursor: 'pointer' }}
          >
            {' '}
            <AddCircleIcon
              style={{
                color: 'lightseagreen',
                marginBottom: 3,
                marginRight: 5
              }}
            />{' '}
            Add exercise
          </span>
        </div>
      )
    }
  }
}

export default CurrentExercises
