import { Modal, ModalBody } from 'shards-react'

import { Store, Dispatcher, Constants } from '../flux'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import DescriptionIcon from '@material-ui/icons/Description'
import React, { Component } from 'react'
import ExerciseLibraryPopup from './ExerciseLibraryPopup'
import ReactLoading from 'react-loading'
import EditableExercise from '../components/routine_library/EditableExercise'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
// import PDFGenerator from "./PDFGenerator";

class App extends Component {
  constructor(props) {
    super(props)
    this.addExercise = this.addExercise.bind(this)
    this._onChange = this._onChange.bind(this)
    this.renderExercises = this.renderExercises.bind(this)
    this.state = this.getAppState()
    this.state.installAppOpen = false
    this.closeExerciseModal = this.closeExerciseModal.bind(this)
    this.editExercise = this.editExercise.bind(this)
    this.renderInstallAppModal = this.renderInstallAppModal.bind(this)
    this.handleAddRoutine = this.handleAddRoutine.bind(this)
  }

  getAppState() {
    return {
      addExerciseOpen: Store.getExerciseLibraryOpen(),
      pdfDownloadWorking: Store.getPDFDownloadWorking(),
      exercises: Store.getExerciseLibrary(),
      selectedRoutine: Store.getSelectedRoutine(),
      exerciseCategories: Store.getCategories(),
      addExerciseWorking: Store.getAddingExerciseWorking(),
      selectRoutineWorking: Store.getSelectRoutineWorking(),
      routineCopyWorking: Store.getRoutineCopyWorking(),
      routineEditMode: Store.getRoutineEditMode(),
      client: Store.getMyDetails()
    }
  }
  componentDidMount() {
    Store.addChangeListener(this._onChange)
    if (
      this.state.selectedRoutine.client_modifications != this.state.client.uuid
    ) {
      Dispatcher.dispatch({
        actionType: Constants.ENSURE_CLIENT_COPY,
        payload: {
          uuid: this.state.selectedRoutine.uuid,
          history: this.props.history
        }
      })
    }

    Dispatcher.dispatch({
      actionType: Constants.GET_ROUTINE_DETAILS,
      payload: {
        uuid: this.state.selectedRoutine.uuid
      }
    })

    Store.setExerciseSearchTerm('')
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  editExercise(exercise) {
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_EXERCISE,
      payload: exercise
    })
    this.props.history.push('/edit-exercise/', { goBackToRoutine: true })
  }

  renderExercises(routine) {
    return (
      <EditableExercise
        routine={routine}
        eventHandlers={{}}
        routineEditMode={true}
        editExercise={this.editExercise}
      />
    )
  }

  addExercise() {
    return (
      <Modal
        size='lg'
        open={this.state.addExerciseOpen}
        toggle={() => Store.toggleExerciseLibraryOpen()}
      >
        <ModalBody
          style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}
        >
          <ExerciseLibraryPopup history={this.props.history} />
        </ModalBody>
      </Modal>
    )
  }

  renderInstallAppModal() {
    return (
      <Modal
        open={this.state.installAppOpen}
        size='md'
        toggle={() => {
          this.setState({ installAppOpen: false })
        }}
      >
        <ModalBody>
          <div style={{ color: 'black' }}>
            <b>username</b> {this.state.client.username}
          </div>
          <div style={{ color: 'black' }}>
            <b>password</b> {this.state.client.app_password}
          </div>
          <div style={{ display: 'flex' }}>
            <span style={{ marginTop: 15 }}>
              <div>
                <a
                  target='_blank'
                  rel='noopener'
                  style={{
                    display: 'inline-block',
                    overflow: 'hidden',
                    borderRadius: '13px',
                    width: '250px',
                    height: '83px'
                  }}
                  href='https://play.google.com/store/apps/details?id=com.jacobfionn.recover'
                >
                  <img
                    alt='Android app on Google Play'
                    src='https://developer.android.com/images/brand/en_app_rgb_wo_60.png'
                  />
                </a>
              </div>
              <div>
                {' '}
                <img
                  width={180}
                  src='http://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.jacobfionn.recover&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L'
                  alt='qr code'
                />
              </div>
            </span>

            <span style={{ marginTop: 15 }}>
              <div>
                <a
                  href='https://apps.apple.com/us/app/recoverhub/id1560641825?itsct=apps_box&itscg=30200'
                  target='_blank'
                  rel='noopener'
                >
                  <img
                    src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us/'
                    alt='Download on the App Store'
                    style={{ width: '180px' }}
                  />
                </a>
              </div>
              <div style={{ marginTop: 25 }}>
                <img
                  width={190}
                  src='https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/544b3a9ee4414eb5417ffc0c033fc4c1/577c3f4f9b6cce80e425c51f8f90ca4b.png'
                />
              </div>
            </span>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  handleAddRoutine(uuid) {
    Dispatcher.dispatch({
      actionType: Constants.ASSIGN_ROUTINE,
      payload: { routine_uuid: uuid, history: null }
    })
    this.setState({ installAppOpen: true })
  }

  closeExerciseModal() {
    this.setState({ addExerciseOpen: false })
  }

  render() {
    const modal = this.addExercise()
    const installAppModal = this.renderInstallAppModal()
    if (this.state.routineCopyWorking) {
      return (
        <div
          style={{
            backgroundColor: 'lightslategrey',
            color: 'white',
            padding: 25,
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h3 style={{ color: 'white' }}>Opening...</h3>
        </div>
      )
    }

    return (
      <div>
        <div style={{ bottom: 50, right: 25, position: 'fixed', zIndex: 3000 }}>
          <span
            onClick={() => {
              this.handleAddRoutine(this.state.selectedRoutine.uuid)
            }}
            style={{
              cursor: 'pointer',

              display: 'inline-block',
              textAlign: 'center',
              width: 60,
              marginRight: 10
            }}
          >
            <PhoneIphoneIcon style={{ color: 'white', fontSize: 48 }} />
          </span>

          <span
            onClick={() => {
              Dispatcher.dispatch({
                actionType: Constants.DOWNLOAD_PDFS,
                payload: { routine: this.state.selectedRoutine.uuid }
              })
            }}
            style={{
              cursor: 'pointer',

              display: 'inline-block',
              textAlign: 'center',
              width: 60
            }}
          >
            {this.state.pdfDownloadWorking ? (
              <ReactLoading type='spin' color='white' width={50} height={50} />
            ) : (
              <DescriptionIcon style={{ color: 'white', fontSize: 50 }} />
            )}
          </span>
          {/* Client side PDF generation: waiting on fixes for image loading */}
          {/* <PDFGenerator />  */}
        </div>
        {modal}
        {installAppModal}
        <div
          style={{
            position: 'fixed',
            zIndex: 999,
            height: 50,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            alignItems: 'center',
            display: 'flex',
            width: '100%'
          }}
        >
          {/* left */}
          <div style={{ width: '100%' }}>
            {' '}
            <span
              onClick={() => {
                this.props.history.push('/routines')
              }}
              style={{ cursor: 'pointer', marginLeft: 10, color: 'white' }}
            >
              <ArrowBackIosIcon style={{ marginBottom: 2, fontSize: '12px' }} />
              Back to library
            </span>{' '}
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {' '}
            <span style={{ color: 'white' }}>
              {' '}
              {this.state.selectedRoutine.name}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <span>
              {' '}
              <span
                style={{ cursor: 'pointer', color: 'white', marginRight: 10 }}
                onClick={() => {
                  Store.toggleExerciseLibraryOpen()
                }}
              >
                <AddCircleIcon style={{ marginBottom: 3, marginRight: 5 }} />
                Add exercise{' '}
              </span>
            </span>
          </div>
        </div>
        <div
          style={{
            minHeight: '100vh',
            paddingTop: 60,
            justifyContent: 'center',
            backgroundColor: 'lightslategrey',
            display: 'flex',
            width: '100%',
            flexDirection: 'row'
          }}
        >
          <div
            style={{
              lineHeight: 1.3,
              paddingLeft: '50px',
              paddingRight: '50px',

              boxShadow: 'rgba(0, 0, 0, 0.36) 2px 4px 4px',

              width: '60%',
              backgroundColor: 'white'
            }}
          >
            <table
              style={{
                margin: 0,
                borderCollapse: 'separate',
                borderSpacing: '0 15px',
                paddingTop: '5px',
                width: '100%'
              }}
            >
              {this.renderExercises(this.state.selectedRoutine)}
            </table>
          </div>
        </div>
      </div>
    )
  }
}
export default App
