import React, { Component } from 'react'
import { Alert, Button } from 'shards-react'

import { Store, Dispatcher, Constants } from '../flux'
import { Row } from 'shards-react'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      password: '',
      password1: '',
      password2: '',
      token: '',
      registerText: 'Register',
      loginText: 'Login',
      loginErrors: '',
      registerErrors: '',
      personalUse: false,
      registerView: new URLSearchParams(this.props.location.search).get(
        'signup'
      )
    }
    this._onChange = this._onChange.bind(this)
    this.onRegister = this.onRegister.bind(this)
    this.onLogin = this.onLogin.bind(this)
    this.getAlert = this.getAlert.bind(this)
    this.check500 = this.check500.bind(this)
    this.changeRadio = this.changeRadio.bind(this)
    this.checkAlreadyLoggedIn = this.checkAlreadyLoggedIn.bind(this)
    this.renderForm = this.renderForm.bind(this)
  }

  componentDidMount() {
    console.log(this.props.location.search)
    this.checkAlreadyLoggedIn()
    Dispatcher.dispatch({
      actionType: Constants.REGISTER_ERRORS,
      payload: ''
    })
    Dispatcher.dispatch({
      actionType: Constants.LOGIN_ERRORS,
      payload: ''
    })
    Store.addChangeListener(this._onChange)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }

  getAppState() {
    return {
      loginWorking: Store.getLoginWorking(),
      registerWorking: Store.getRegisterWorking(),
      loginErrors: Store.getLoginErrors(),
      registerErrors: Store.getRegisterErrors(),
      personalUse: Store.getPersonalUse()
    }
  }

  onLogin(e) {
    e.preventDefault()
    Dispatcher.dispatch({
      actionType: Constants.LOGIN,
      payload: {
        username: this.state.username,
        password: this.state.password,
        history: this.props.history
      }
    })
  }

  onRegister(e) {
    e.preventDefault()
    Dispatcher.dispatch({
      actionType: Constants.REGISTER,
      payload: {
        username: this.state.username,
        email: this.state.email,
        password1: this.state.password1,
        password2: this.state.password2,
        history: this.props.history
      }
    })
  }

  _onChange() {
    this.setState(this.getAppState())
  }

  getAlert() {
    const { loginErrors, registerErrors } = this.state
    if (loginErrors) {
      return (
        <Alert className='mb-0' theme='warning'>
          {' '}
          <i className='fa fa-info mx-2' />{' '}
          <span style={{ fontSize: 12 }}>{loginErrors}</span>
        </Alert>
      )
    }
    if (registerErrors) {
      // unpack them
      let errors = registerErrors[0]

      return (
        <Alert className='mb-0' theme='warning'>
          {' '}
          <i className='fa fa-info mx-2' />{' '}
          <span style={{ fontSize: 12 }}>{errors}</span>
        </Alert>
      )
    }
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  changeRadio() {
    Store.switchPersonalUse()
  }

  checkAlreadyLoggedIn() {
    if (Store.getToken() && Store.getPrevToken()) {
      this.props.history.push('/routines/')
    }
  }

  renderForm() {
    if (this.state.registerView) {
      return (
        <div style={{ width: 350 }}>
          <form class='auth-form' onSubmit={e => this.onRegister(e)}>
            <input
              class='auth-form-control'
              type='text'
              name='username'
              id='username'
              placeholder='Username'
              required
              onChange={e => {
                this.setState({ username: e.target.value })
              }}
            />

            <input
              class='auth-form-control'
              type='text'
              name='email'
              id='email'
              placeholder='Email'
              required
              onChange={e => {
                this.setState({ email: e.target.value })
              }}
            />

            <input
              class='auth-form-control'
              type='password'
              name='password'
              id='password'
              placeholder='Password'
              required
              onChange={e => {
                this.setState({ password1: e.target.value })
              }}
            />

            <input
              class='auth-form-control'
              type='password'
              name='passwordRepeat'
              id='passwordRepeat'
              placeholder='Password (again)'
              required
              onChange={e => {
                this.setState({ password2: e.target.value })
              }}
            />

            <Button
              theme='success'
              type='submit'
              style={{
                fontSize: 16,
                marginTop: 5,
                marginBottom: 10,
                width: '100%'
              }}
            >
              {' '}
              {this.state.registerWorking ? 'Registering' : 'Register'}
            </Button>
            <Button
              theme='light'
              type='submit'
              style={{
                fontSize: 16,
                border: '1px lightgrey solid',
                width: '100%'
              }}
              onClick={() => {
                this.setState({
                  loginErrors: '',
                  registerErrors: '',
                  registerView: false
                })
              }}
            >
              Go Back
            </Button>
          </form>
        </div>
      )
    } else {
      return (
        <div style={{ width: 350 }}>
          <form class='auth-form' onSubmit={e => this.onLogin(e)}>
            <input
              class='auth-form-control'
              type='text'
              name='username'
              id='username'
              placeholder='Username'
              required
              onChange={e => {
                this.setState({ username: e.target.value })
              }}
            />

            <input
              class='auth-form-control'
              type='password'
              name='password'
              id='password'
              placeholder='Password'
              required
              onChange={e => {
                this.setState({ password: e.target.value })
              }}
            />

            <Button
              theme='success'
              type='submit'
              style={{
                fontSize: 16,
                padding: 10,
                color: 'white',
                margin: 5,
                width: '100%',
                backgroundColor: '#20B2AA'
              }}
            >
              {this.state.loginWorking ? 'Logging In' : 'Login'}
            </Button>
          </form>
          <hr class='style-eight' />
          {/* <a

          class="link"
          href="javascript:void(0)"
          onClick={() => {
            this.setState({
              loginErrors: "",
              registerErrors: "",
              registerView: true
            });
          }}
        >
          Create Account
        </a> */}

          <Button
            theme='light'
            type='submit'
            style={{
              fontSize: 16,
              border: '1px lightgrey solid',
              width: '100%'
            }}
            onClick={() => {
              this.setState({
                loginErrors: '',
                registerErrors: '',
                registerView: true
              })
            }}
          >
            {' '}
            Create Account{' '}
          </Button>
        </div>
      )
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: 'whitesmoke' }}>
        {' '}
        {/* <Row
          style={{
            alignItems: "center",
            height: 50,
            marginRight: 20,
            // backgroundColor: "#20B2AA"
            borderBottom: "1px solid #d9d9d9"
          }}
        >
          <span
            style={{
              marginLeft: 44
            }}
          >
            <span
              style={{
                color: "lightseagreen",
                fontSize: 20,
                fontFamily: "Montserrat, sans-serif"
              }}
            >
              RecoverHub
            </span>
          </span>
        </Row> */}
        {this.getAlert()}
        <div
          style={{
            // border: "1px solid red",
            padding: 100,
            display: 'flex',
            minHeight: '90vh',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              width: '100%',
              textAlign: 'center'
            }}
          >
            <div
              style={{
                // color: "#005863",
                fontSize: 30
              }}
            >
              <div />
              <span
                style={{
                  borderRadius: 3,
                  cursor: 'pointer',
                  marginRight: 3,
                  // backgroundColor: "#e4e6eb",
                  // padding: 3,
                  // paddingLeft: 5,
                  // paddingRight: 5,
                  color: 'black',
                  marginLeft: 0,
                  // backgroundColor: "#2daea85a",
                  // width: 150,
                  display: 'inline-block',
                  height: 35,
                  textAlign: 'center'
                }}
              >
                Sign in to{' '}
                <span
                  style={{
                    color: 'lightseagreen',
                    fontFamily: 'Montserrat, sans-serif'
                  }}
                >
                  RecoverHub{' '}
                </span>
              </span>
            </div>

            <div style={{color: "black", marginBottom: 40, marginTop: 40, fontSize: 18 }}>
              {this.state.registerView
                ? "Create an account. It's free."
                : 'To get access to your digital programs'}
            </div>
          </div>

          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            {this.renderForm()}
          </div>
        </div>
      </div>
    )
  }
}

export default App
