import React, { Component } from 'react'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button
} from 'shards-react'

import { Store, Dispatcher, Constants } from '../flux'

import PropTypes from 'prop-types'
import store from '../flux/store'

class MyRecovery extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    this.handleGoalChange = this.handleGoalChange.bind(this)
    this.handleGoalStatementChange = this.handleGoalStatementChange.bind(this)
    this.handleGoalUpdateClick = this.handleGoalUpdateClick.bind(this)
    this.getTable = this.getTable.bind(this)
    this.getTableItems = this.getTableItems.bind(this)
  }
  getAppState() {
    return {
      myDetails: store.getMyDetails()
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    Store.addChangeListener(this._onChange)
    setTimeout(function() {
      Dispatcher.dispatch({
        actionType: Constants.GET_MY_DETAILS
      })
      Dispatcher.dispatch({
        actionType: Constants.SET_MENU_INVISIBLE
      })
    }, 1)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  forceLogin() {
    if (localStorage.getItem('token') === null) {
      this.props.history.push('/landing/')
    }
  }

  displayAppDownloadReminder() {
    if ('first_download_complete' in this.state.myDetails) {
      if (this.state.myDetails.first_download_complete == false) {
        return (
          <Alert className='mb-0' theme='warning'>
            {' '}
            <i className='fa fa-info mx-2' />
            The next step is to select a routine from the Routine Library and
            complete it by downloading the app{' '}
            <a
              target='_blank'
              href='https://play.google.com/store/apps/details?id=com.jacobfionn.recover'
            >
              here
            </a>
          </Alert>
        )
      }
    }
  }

  formatDate(dateString) {
    const date = new Date(dateString)
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return date.toLocaleDateString('en-US', options)
  }

  handleGoalChange(e) {
    const myDetailsNew = this.state.myDetails
    myDetailsNew.goal = e.target.value
    this.setState({
      myDetails: myDetailsNew
    })
  }

  handleGoalStatementChange(e) {
    const myDetailsNew = this.state.myDetails
    myDetailsNew.goal_statement = e.target.value
    this.setState({
      myDetails: myDetailsNew
    })
  }

  handleGoalUpdateClick(e) {
    const { myDetails } = this.state
    Dispatcher.dispatch({
      actionType: Constants.PATCH_MY_DETAILS,
      payload: {
        goal: myDetails.goal,
        goal_statement: myDetails.goal_statement
      }
    })
  }

  calculateProgressPercentage(myDetails) {
    if (myDetails.goal < 1) {
      return 100
    }
    return Math.floor((myDetails.completed / myDetails.goal) * 100)
  }

  ensureNoMappingError(myDetails) {
    if (myDetails['completed_details'] == undefined) {
      myDetails['completed_details'] = []
    }
    console.log(myDetails)
    return myDetails
  }

  getPainDuringActivityColour(position) {
    if (position > 6) {
      return 'red'
    }
    if (position > 4) {
      return 'orange'
    }
    return 'green'
  }

  getDifficultyColour(position) {
    if (position > 8) {
      return 'red'
    }
    if (position > 7) {
      return 'orange'
    }
    if (position > 3) {
      return 'green'
    }
    return 'red'
  }

  getEnjoymentColour(position) {
    if (position > 5) {
      return 'green'
    }
    if (position > 2) {
      return 'orange'
    }
    return 'red'
  }

  getTableItems(myDetails) {
    if (!myDetails) {
      return []
    }
    const boldHighlight = ({ children, highlightIndex }) => (
      <strong style={{ fontWeight: 'bold' }}>{children}</strong>
    )
    return myDetails.completed_details.map(completed => (
      <div style={{ padding: 5 }}>
        <Row
          style={{
            borderRadius: '15px 15px 15px 15px',
            // mozBorderRadius: "10px 10px 10px 10px",
            // webkitBorderRadius: "10px 10px 10px 10px",
            borderBottom: '1px solid #d9d9d9',
            // webkitBoxShadow: "0px 11px 10px -8px rgba(196,196,196,1)",
            // mozBoxShadow: "0px 11px 5px -8px rgba(196,196,196,1)",
            // boxShadow: "0px 11px 5px -8px rgba(196,196,196,1)",

            paddingTop: 15,
            // paddingBottom: 15,
            backgroundColor: '#daefee'
          }}
        >
          <Col style={{ paddingLeft: 0 }} lg='4'>
            <img src={completed.routine_image} width='200px' height='auto' />{' '}
          </Col>
          <Col lg='6'>
            <div style={{ fontSize: 22 }}>
              {this.formatDate(completed.completed_at)}
            </div>
            <div>
              <span className='muted' style={{ fontSize: 16 }}>
                {completed.routine_name}
              </span>
            </div>
            <div style={{ fontSize: 12, paddingTop: 10 }}>
              {/* <div>
                Pain During Activity{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: this.getPainDuringActivityColour(
                      completed.pain_during_activity
                    )
                  }}
                >
                  {" "}
                  {completed.pain_during_activity} / 10
                </span>
              </div>
              <div>
                Difficulty{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: this.getDifficultyColour(completed.difficulty)
                  }}
                >
                  {completed.difficulty} / 10
                </span>
              </div> */}
              <div>
                Effectiveness{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    color: this.getEnjoymentColour(completed.enjoyment)
                  }}
                >
                  {' '}
                  {completed.enjoyment} / 10
                </span>
              </div>
              <div>
                Comment <br /> {completed.comment}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    ))
  }

  getTable(myDetails) {
    if (!myDetails.completed_details) {
      return <p>Loading ...</p>
    }
    if (
      (myDetails.completed_details.length == 0) &
      this.state.myDetailsWorking
    ) {
      return <p>Loading ...</p>
    }
    if (myDetails.completed_details.length == 0) {
      return <p>No results</p>
    }

    return this.getTableItems(myDetails)
  }

  render() {
    this.check500()
    this.forceLogin()
    const myDetails = this.ensureNoMappingError(this.state.myDetails)
    return (
      <Row style={{ margin: 10, paddingTop: 10 }}>
        <Col md='2' lg='2'>
          <div>
            <div>
              <span
                onClick={() => {
                  this.props.history.push('/routines')
                }}
                style={{
                  backgroundColor: 'whitesmoke',
                  color: 'black',

                  display: 'inline',
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: 8,
                  borderRadius: 5,
                  cursor: 'pointer',
                  border: '1px solid #d9d9d9'
                }}
              >
                Back to Routines
              </span>
            </div>
          </div>
          <div style={{ paddingTop: 15 }}>
            <h5>Summary</h5>
            <span style={{ fontSize: 15 }}>
              <b>Completed</b> {myDetails.completed_details.length} routines
            </span>
            <div style={{ paddingTop: 10 }}>
              <ul
                style={{
                  paddingLeft: 0,
                  textAlign: 'left',
                  listStyleType: 'none',
                  fontSize: 14
                }}
              >
                <li />
                {/* <li>
                  Pain During Activity{" "}
                  {myDetails.completed_averages
                    ? myDetails.completed_averages.pain_during_activity
                    : "-"}{" "}
                </li>
                <li>
                  Difficulty{" "}
                  {myDetails.completed_averages
                    ? myDetails.completed_averages.difficulty
                    : "-"}{" "}
                </li> */}
                <li>
                  Effectiveness{' '}
                  {myDetails.completed_averages
                    ? myDetails.completed_averages.enjoyment
                    : '-'}{' '}
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col lg='8' md='6' sm='6'>
          {this.getTable(myDetails)}
        </Col>
        <Col md='2' lg='2'>
          <div style={{ paddingTop: 10, fontSize: 14 }}>
            <h4>Interpreting Results</h4>

            <p>
              The most important metric is if their routine is working for them,
              and they feel they are making progress. We use the term
              effectiveness to indicate this. There are many factors which
              attribute to the ultimate effectiveness rating of a program based
              on the individual. You should use the effectiveness rating as a
              signal to check if the routine is working. If the effectiveness is
              low, check the comments to see the reason.
            </p>
          </div>
        </Col>
      </Row>
    )
  }
}

MyRecovery.propTypes = {
  /**
   * The component's title.
   */
  selectedRoutine: PropTypes.object
}

MyRecovery.defaultProps = {
  selectedRoutine: {
    name: 'New Routine',
    length: '30',
    description: '',
    exercises: []
  }
}

export default MyRecovery
