import React, { Component } from 'react'
import {
  Card,
  Container,
  Row,
  Col,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from 'shards-react'

import { Store, Dispatcher, Constants } from '../flux'

class DoRoutine extends Component {
  constructor(props) {
    super(props)
    this.state = this.getAppState()
    this._onChange = this._onChange.bind(this)
  }

  getAppState() {
    return {
      routine: Store.getSelectedRoutine()
    }
  }

  _onChange() {
    this.setState(this.getAppState)
  }

  getRoutineImage(routine) {
    if (routine.exercises && routine.exercises.length > 0) {
      return routine.exercises[0].image
    } else {
      return null
    }
  }

  componentDidMount() {
    Store.addChangeListener(this._onChange)
    Dispatcher.dispatch({
      actionType: Constants.GET_ROUTINE_DETAILS,
      payload: {
        client: this.props.match.params.client,
        slug: this.props.match.params.slug
      }
    })
  }

  render() {
    if (this.state.routine) {
      return (
        <div className='dr-container'>
          <div className='dr-card'>
            <div className='dr-header'>
              <span>{this.state.routine.name}</span>
            </div>
            <div className='dr-current-exercise'>
              <img src={this.getRoutineImage(this.state.routine)} />
            </div>
            <div className='dr-current-exercise-timer'>
              <div className='dr-timer-left'>
                <span>02:45</span>
              </div>
              <div className='dr-timer-right'>
                <span>Hip Lift</span>
              </div>
            </div>
            <div className='dr-current-exercise-description-button'>
              <span>Show Exercise Description</span>
            </div>
            <div className='dr-current-exercise-list'>
              <div style={{ border: 1 }}>
                <img src={this.getRoutineImage(this.state.routine)} />
                <div />
                <div>8 Reps</div>
              </div>
              <div>
                <img src={this.getRoutineImage(this.state.routine)} />
                <div />
                <div>02m 01s</div>
              </div>
              <div>
                <img src={this.getRoutineImage(this.state.routine)} />
                <div />
                <div>03m 01s</div>
              </div>
              <div>
                <img src={this.getRoutineImage(this.state.routine)} />
                <div />
                <div>8 reps</div>
              </div>
            </div>

            <div className='dr-current-exercise-button'>
              <span className='dr-current-exercise-button-text'>Start</span>
            </div>
          </div>
        </div>
      )
    }
    return <p>Loading</p>
  }
}

export default DoRoutine
