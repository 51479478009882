import React, { Component } from 'react'
import {
  Alert,
  FormInput,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Button,
  CardBody,
  CardFooter,
  FormTextarea,
  Modal,
  ModalBody,
  ModalHeader
} from 'shards-react'

import Loader from 'react-loader-spinner'

import { Store, Dispatcher, Constants } from '../flux'

class MyClients extends Component {
  constructor(props) {
    super(props)
    this.state = this.getAppState()
    this._onChange = this._onChange.bind(this)
    this.getStudentList = this.getStudentList.bind(this)
  }

  componentDidMount() {
    Store.addChangeListener(this._onChange)
    Dispatcher.dispatch({ actionType: Constants.GET_STUDENT_LIST })
  }

  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }

  getAppState() {
    return {
      students: Store.getStudentList(),
      studentsWorking: Store.getStudentsWorking()
    }
  }

  _onChange() {
    this.setState(this.getAppState())
  }

  getAlert() {
    const { loginErrors, registerErrors } = this.state
    if (loginErrors) {
      return (
        <Alert className='mb-0' theme='warning'>
          {' '}
          <i className='fa fa-info mx-2' /> {loginErrors}
        </Alert>
      )
    }
    if (registerErrors) {
      // unpack them
      let errors = ''
      for (let i = 0; i < registerErrors.length; i++) {
        errors += '\n ' + registerErrors[i]
      }

      return (
        <Alert className='mb-0' theme='warning'>
          {' '}
          <i className='fa fa-info mx-2' /> {errors}
        </Alert>
      )
    }
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  getStudentList() {
    let students = ''
    if (this.state.students.length == 0) {
      students = (
        <p style={{ marginTop: 10, fontSize: 12, color: 'grey' }}>
          {' '}
          Click{' '}
          <span style={{ color: 'black', fontWeight: 'bold' }}>
            New Client
          </span>{' '}
          to create your first client.{' '}
        </p>
      )
    } else {
      students = this.state.students.map(student => (
        <div>
          <Button
            theme='light'
            onClick={e => this.switchToStudent(e, student)}
            className='client-list-item'
            style={{
              cursor: 'pointer',
              padding: 10,
              fontSize: 16,
              width: '100%'
            }}
          >
            <span style={{ color: 'black' }} href={'javascript:void(0)'}>
              {student.username}
            </span>
          </Button>
        </div>
      ))
    }

    return (
      <div style={{ height: 300 }}>
        <Button
          // class="registerClientButton"
          theme='success'
          style={{ width: '100%', fontSize: 16 }}
          onClick={() => {
            this.props.history.push('/new-client')
          }}
          theme='success'
          value='Add Client'
        >
          New Client
        </Button>{' '}
        {students}
        {/* <Button
          theme="light"
          onClick={e => this.switchToPersonalAccount(e)}
          className="client-list-item"
          style={{
            cursor: "pointer",
            padding: 10,
            fontSize: 16,
            width: "100%",
            marginLeft: 0,
            paddingTop: 10,
            borderRadius: 2,
            borderBottomStyle: "solid",
            borderBottomWidth: 1,

            borderColor: "lightgrey"
            // fontWeight: "bold"
          }}
        > */}
        {/* <span style={{ color: "black" }} href={"javascript:void(0)"}>
            My Account
          </span> */}
        {/* </Button> */}
      </div>
    )
  }

  switchToStudent(e, student) {
    const jumpTo = ''
    for (let i = 0; i < this.state.students.length; i++) {
      if (this.state.students[i].username == student.username) {
        Dispatcher.dispatch({
          actionType: Constants.SET_CURRENT_TOKEN,
          payload: { token: this.state.students[i].token }
        })
        Store.setUsername(student.username)
        this.props.history.push('/routines/', { hardRefresh: true })
      }
    }
  }

  switchToPersonalAccount(e) {
    this.props.history.push('/routines/', { hardRefresh: true })
  }

  render() {
    let { students } = this.state
    if (students == undefined) {
      students = []
    }
    let studentsDisplay = ''
    if (!this.state.studentsWorking) {
      studentsDisplay = this.getStudentList()
    } else {
      studentsDisplay = (
        <Loader
          type='Puff'
          color='#39b6af'
          height={100}
          width={100}
          timeout={300000} //3 secs
        />
      )
    }
    return (
      <div class='auth-main-container'>
        <div class='auth-logo'>
          <span style={{ fontSize: 32, fontFamily: 'Montserrat, sans-serif' }}>
            Recover <span> </span>
            <span
              style={{
                fontSize: 28,
                borderRadius: 8,
                padding: 8,
                backgroundColor: '#39b6af',
                color: 'white',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: 10
              }}
            >
              Hub
            </span>
          </span>

          <span style={{ marginBottom: 20, marginTop: 20 }}>Clients List</span>
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div style={{ width: 300 }}>{studentsDisplay}</div>
        </div>
      </div>
    )
  }
}

export default MyClients
