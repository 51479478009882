import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'shards-react'

import ExerciseDetails from '../components/exercise_library/ExerciseDetails'
import { Store, Dispatcher, Constants } from '../flux'
import API from '../utils/api'

class NewExercise extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    this.handleCreateExerciseClick = this.handleCreateExerciseClick.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.renderGoBackToRoutine = this.renderGoBackToRoutine.bind(this)
  }
  getAppState() {
    return {
      selectedExercise: Store.getSelectedExercise(),
      exerciseCategories: Store.getCategories(),
      newExercise: {
        name: '',
        description: '',
        type: 1,
        length: 30
      },
      imageUploaderLabel: 'Upload an image...',
      selectedRoutine: Store.getSelectedRoutine(),
      exerciseCreateWorking: Store.getExerciseCreateWorking()
    }
  }
  componentDidMount() {
    Dispatcher.dispatch({
      actionType: Constants.SET_MENU_INVISIBLE
    })
    document.addEventListener('keydown', this.handleEnterPressed)
    Store.addChangeListener(this._onChange)
    Dispatcher.dispatch({ actionType: Constants.GET_EXERCISE_CATEGORIES })
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_EXERCISE,
      payload: this.state.newExercise
    })
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnterPressed)
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  handleFormChange(e, field) {
    const toUpdate = { ...this.state.newExercise }
    toUpdate[field] = e.target.value
    this.setState({ newExercise: toUpdate })
  }

  handleCategoryChange(e) {
    const index = e.nativeEvent.target.selectedIndex
    const toUpdate = { ...this.state.newExercise }
    toUpdate.category = e.target.value
    toUpdate.category_name = e.nativeEvent.target[index].text
    this.setState({ newExercise: toUpdate })
  }

  handleImageChange(e) {
    const toUpdate = { ...this.state.newExercise }
    toUpdate.image = e.target.files[0]
    let label = e.target.value.split('\\')
    label = label[label.length - 1]
    this.setState({
      newExercise: toUpdate,
      imageUploaderLabel: label
    })
  }

  handleCreateExerciseClick(e) {
    const { newExercise } = this.state
    if (newExercise.name == '') {
      return
    }
    if (this.props.newRoutine) {
      API.postExercise(newExercise, this.state.selectedRoutine, true)
    } else {
      API.postExercise(newExercise, this.state.selectedRoutine, false)
    }
  }

  handleLengthChange(e) {
    const toUpdate = { ...this.state.newExercise }
    toUpdate.length = parseInt(e.target.value) | 0
    this.setState({ newExercise: toUpdate })
  }

  handleKeyDown(e) {
    if (e.keyCode === 13) {
      this.handleCreateExerciseClick(e)
    }
  }

  getEventHandlers() {
    return {
      updateClick: e => this.handleCreateExerciseClick(e),
      lengthChange: e => this.handleLengthChange(e),
      nameChange: e => this.handleFormChange(e, 'name'),
      descriptionChange: e => this.handleFormChange(e, 'description'),
      typeChange: e => this.handleFormChange(e, 'type'),
      categoryChange: e => this.handleCategoryChange(e),
      imageChange: e => this.handleImageChange(e),
      keyDown: e => this.handleKeyDown(e)
    }
  }
  forceLogin() {
    if (localStorage.getItem('token') == null) {
      this.props.history.push('/auth/')
    }
  }

  renderGoBackToRoutine() {
    return (
      <Button
        style={{ border: '1px lightgrey solid' }}
        theme='light'
        onClick={() => {
          this.props.goBack()
        }}
      >
        Go Back To Exercises
      </Button>
    )
  }

  render() {
    this.forceLogin()
    const {
      selectedExercise,
      exerciseCategories,
      newExercise,
      imageUploaderLabel,
      exerciseCreateWorking
    } = this.state

    return (
      <div style={{ display: 'flex', width: '100%', margin: 0 }}>
        {/* <Row
          style={{
            padding: 10,
            alignItems: "center",
            justifyContent: "center"
          }}
        > */}
        {/* <Col lg="8" style={{ paddingBottom: "20px" }}> */}
        <ExerciseDetails
          eventHandlers={this.getEventHandlers()}
          categories={exerciseCategories}
          buttonText={exerciseCreateWorking ? 'Working...' : 'Create Exercise'}
          exerciseModifications={newExercise}
          imageUploaderLabel={imageUploaderLabel}
          renderGoBackToRoutine={this.renderGoBackToRoutine}
        />
        {/* </Col> */}
        {/* // </Row> */}
      </div>
    )
  }
}

export default NewExercise
