import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'shards-react'

const DefaultLayout = ({ children }) => (
  <Container fluid style={{ padding: 0 }}>
    {children}
  </Container>
)

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
}

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
}

export default DefaultLayout
