import React from 'react'
import { Link } from 'react-router-dom'
import { Store, Dispatcher, Constants } from '../../../../flux'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from 'shards-react'

export default class UserActions extends React.Component {
  constructor(props) {
    super(props)
    this.getAppState = this.getAppState.bind(this)
    this._onChange = this._onChange.bind(this)
    this.selectedRoutineName = this.renderSelectedRoutineName.bind(this)
    this.state = this.getAppState()

    this.toggleUserActions = this.toggleUserActions.bind(this)
  }

  getAppState() {
    return {
      visible: false,
      selectedRoutine: Store.getSelectedRoutine()
    }
  }

  componentDidMount() {
    Store.addChangeListener(this._onChange)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }
  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    })
  }

  renderSelectedRoutineImage() {
    const { selectedRoutine } = this.state
    if (this.state.selectedRoutine.image) {
      return (
        <img
          className='user-avatar rounded-circle mr-2'
          src={selectedRoutine.image}
          alt='Selected Routine'
          width='40'
          height='40'
        />
      )
    } else {
      return <span className='d-none d-md-inline-block' />
    }
  }

  renderSelectedRoutineName() {
    const { selectedRoutine } = this.state
    if (this.state.selectedRoutine.name) {
      return <span className='d-none d-md-inline-block' />
    } else {
    }
  }

  render() {
    const { selectedRoutine } = this.state
    let selectedRoutineImage = this.renderSelectedRoutineImage()
    let selectedRoutineName = this.selectedRoutineName()
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className='text-nowrap px-3'>
          {selectedRoutineImage}
          {selectedRoutineName}
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to='user-profile'>
            <i className='material-icons'>&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to='edit-user-profile'>
            <i className='material-icons'>&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to='file-manager-list'>
            <i className='material-icons'>&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to='transaction-history'>
            <i className='material-icons'>&#xE896;</i> Transactions
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to='/' className='text-danger'>
            <i className='material-icons text-danger'>&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    )
  }
}
