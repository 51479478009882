/* eslint jsx-a11y/anchor-is-valid: 0 */
import Highlighter from 'react-highlight-words'

import React from 'react'
import { Button, Container, Row, Col, Card } from 'shards-react'

import 'rc-pagination/assets/index.css'
import { Store, Dispatcher, Constants } from '../flux'

import ExercisePagination from '../components/exercise_library/ExercisePagination'
import withWindowDimensions from '../components/common/withWindowDimensions'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ReactLoading from 'react-loading'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Select from 'react-select'
import NewExercise from '../views/NewExercise'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderWidth: 0,
    color: 'black'
    // padding: 20,
  }),
  control: (provided, state) => ({
    ...provided,
    borderWidth: 0,
    color: 'black',
    backgroundColor: 'whitesmoke'
    // padding: 20,
  })
}

class ExerciseLibrary extends React.Component {
  constructor(props) {
    super(props)
    this.handlePageChange = this.handlePageChange.bind(this)
    this._onChange = this._onChange.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    this.forceLogin = this.forceLogin.bind(this)
    this.getTableItems = this.getTableItems.bind(this)
    this.getTable = this.getTable.bind(this)
    this.assignToRoutine = this.assignToRoutine.bind(this)
    this.state.categoriesOpen = false
    this.renderCategories = this.renderCategories.bind(this)
    this.state.createExercise = false
    this.toggleViewCreateExercise = this.toggleViewCreateExercise.bind(this)
  }

  getAppState() {
    return {
      exerciseLibraryWorking: Store.getExerciseLibraryWorking(),
      exerciseLibrary: Store.getExerciseLibrary(),
      exerciseSearchTerm: Store.getExerciseSearchTerm(),
      exercisePagination: Store.getExercisePagination(),
      categories: Store.getCategories(),
      selectedCategory: Store.getSelectedCategory(),
      selectedRoutine: Store.getSelectedRoutine(),
      page: 1,
      exerciseTotal: Store.getExerciseTotal()
    }
  }
  componentDidMount() {
    Store.addChangeListener(this._onChange)
    Dispatcher.dispatch({
      actionType: Constants.FETCH_TOTALS
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: {
        exercise: this.state.exerciseSearchTerm,
        page: 1,
        pageSize: 1000,
        category: null
      }
    })
    Dispatcher.dispatch({
      actionType: Constants.GET_CATEGORIES
    })
    // Store.setSelectedCategory(null);
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  handlePageChange(page, pageSize) {
    this.setState({
      page: page
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: '', page: page, pageSize: 1000 }
    })
  }

  getExerciseCount() {
    if ('count' in this.state.exercisePagination) {
      return this.state.exercisePagination.count
    }
    return 0
  }

  onRoutineChange(e) {
    console.log(e.target.value)
  }

  navigateToExerciseDetail(exercise) {
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_EXERCISE,
      payload: exercise
    })

    this.props.history.push('/exercise-details')
  }

  assignToRoutine(exercise) {
    if (!this.state.selectedRoutine.uuid) {
      console.log('LOCOAL ROUTINE')
      let selectedRoutine = Store.getSelectedRoutine()
      exercise.order = selectedRoutine.exercises.length + 1
      selectedRoutine.exercises.push(exercise)
      Dispatcher.dispatch({
        actionType: Constants.SET_SELECTED_ROUTINE,
        payload: selectedRoutine
      })
      Store.closeExerciseModal()
    } else {
      Dispatcher.dispatch({
        actionType: Constants.PATCH_ROUTINE,
        payload: {
          routineUpdates: { exercise: exercise.uuid },
          selectedRoutine: this.state.selectedRoutine
        }
      })
    }
  }

  forceLogin() {
    if (Store.getToken() == null) {
      this.props.history.push('/landing/')
    }
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  handleCategorySearch(category) {
    Store.setSelectedCategory(category)
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: '', page: 1, category: category, pageSize: 99 }
    })
  }

  getTableItems() {
    if (!this.state.exerciseLibrary) {
      return []
    }

    return this.state.exerciseLibrary.map(exercise => (
      <figure
        class='grid_item grid_item-2'
        onClick={() => this.assignToRoutine(exercise)}
      >
        <div class='text'>{exercise.name}</div>

        {/* <div class="text-details">
            Contains {exercise.exercises.length} Exercise(s) <br></br> 
          </div> */}
        <img class='grid_image' src={exercise.image} alt='' />
      </figure>
    ))
  }

  getTable() {
    if (!this.state.exerciseLibrary) {
      return (
        <div
          style={{
            textAlign: 'center',
            // borderTop: "1px solid #d9d9d9",
            paddingTop: 50
          }}
        >
          <div style={{ paddingTop: 10 }} className='error__content'>
            <ReactLoading
              className='searchLoading'
              type='bubbles'
              color='lightseagreen'
              width={100}
              height={100}
            />
          </div>
        </div>
      )
    }
    if (
      (this.state.exerciseLibrary.length == 0) &
      this.state.exerciseLibraryWorking
    ) {
      return (
        <div
          style={{
            textAlign: 'center',
            // borderTop: "1px solid #d9d9d9",
            paddingTop: 50
          }}
        >
          <div style={{ paddingTop: 10 }} className='error__content'>
            <ReactLoading
              className='searchLoading'
              type='bubbles'
              color='lightseagreen'
              width={100}
              height={100}
            />
          </div>
        </div>
      )
    }

    if (this.state.exerciseLibrary.length == 0) {
      return (
        <div
          style={{
            textAlign: 'center',

            paddingTop: 50
          }}
        >
          <div className='error__content'>
            <h2>No Results</h2>
            {/* <h3>{Store.getRoutineSearchTerm()}</h3> */}
            <p>We could not find any exercises matching your search</p>
          </div>
        </div>
      )
    }

    return <div class='grid-container-small'> {this.getTableItems()} </div>
  }

  changeVisibility(category) {
    // if (category.number_of_routines) {
    return (
      <span style={{ padding: 5 }}>
        <span class='dot' style={{ backgroundColor: category.colour }} />
        <span style={{ color: 'black' }}> {category.name} </span>
        <span
          style={{
            fontSize: 10,
            color: 'black',
            marginTop: 5,
            marginRight: 5,
            float: 'right'
          }}
        >
          {category.number_of_exercises}
        </span>
      </span>
    )
    // }
    return ''
  }
  renderCategories(categories) {
    if (this.state.categoriesOpen || !this.props.isMobileSized) {
      return (
        <ul
          style={{
            paddingLeft: 0,
            textAlign: 'left',
            listStyleType: 'none',
            cursor: 'pointer',
            color: '#9e9e9e'
          }}
        >
          <li
            class='category-highlight'
            style={{
              // color: "#058C42",
              color: this.state.selectedCategory == null ? 'black' : 'black',
              width: '90%',
              backgroundColor:
                this.state.selectedCategory == null ? 'white' : 'white',
              fontSize: 22,
              borderRadius: 5
            }}
            onClick={() => this.handleCategorySearch(null)}
          >
            <span style={{ padding: 5 }}>
              <span
                class='dot'
                style={{ marginRight: 10, backgroundColor: 'pink' }}
              />
              <span>All </span>

              <span
                style={{
                  fontSize: 12,
                  color: 'black',
                  marginTop: 5,
                  marginRight: 10,
                  float: 'right'
                }}
              >
                {this.state.exerciseTotal}
              </span>
            </span>
          </li>
          {categories.map(category => (
            <li
              class='category-highlight'
              style={{
                color:
                  this.state.selectedCategory == category.name
                    ? 'black'
                    : '#636970',
                backgroundColor:
                  this.state.selectedCategory == category.name
                    ? 'white'
                    : 'white',
                width: '90%',
                fontSize: 20,
                borderRadius: 5
                // color: "#058C42"
              }}
              onClick={() => this.handleCategorySearch(category.name)}
            >
              {this.changeVisibility(category)}
            </li>
          ))}
        </ul>
      )
    }
  }

  handleExerciseSearchChange(e) {
    e.preventDefault()
    Store.setExerciseSearchTerm(e.target.value)
  }

  handleExerciseSearchClick() {
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: {
        exercise: Store.getExerciseSearchTerm(),
        page: 1,
        category: Store.getSelectedCategory(),
        pageSize: 99
      }
    })
  }

  toggleMenu() {
    this.setState({ toggleActive: !this.state.toggleActive })
  }

  ensureNoMappingError(myDetails) {
    if (myDetails['completed_details'] == undefined) {
      myDetails['completed_details'] = []
    }
    if (myDetails['setup_progress'] == undefined) {
      myDetails['setup_progress'] = {
        goal_setup: false,
        routines_setup: false,
        routine_completed: false
      }
    }
    if (myDetails['routines'] == undefined) {
      myDetails['routines'] = []
    }
    return myDetails
  }

  handleRoutineCategorySearch(event) {
    const category = event.value
    Store.setSelectedCategory(category)
    Dispatcher.dispatch({
      actionType: Constants.SET_ROUTINE_SEARCH_RESULTS,
      payload: { results: [] }
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_ROUTINES,
      payload: { routine: '', page: 1, category: category }
    })
  }

  handleExerciseCategorySearch(event) {
    let category = event.value
    Store.setSelectedCategory(category)
    Dispatcher.dispatch({
      actionType: Constants.SET_EXERCISE_SEARCH_RESULTS,
      payload: { results: [] }
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: '', pageSize: 999, page: 1, category: category }
    })
  }

  toggleViewCreateExercise() {
    this.setState({ createExercise: !this.state.createExercise })
  }

  render() {
    this.forceLogin()
    this.check500()
    let categories = this.state.categories

    const options = []
    for (let i = 0; i < this.state.categories.length; i++) {
      options.push({
        value: this.state.categories[i].name,
        label: this.state.categories[i].name
      })
    }

    if (this.state.createExercise) {
      return (
        <NewExercise
          newRoutine={this.props.newRoutine}
          goBack={this.toggleViewCreateExercise}
        />
      )
    }

    return (
      <div>
        <div
          style={{
            padding: 15,
            textAlign: 'center',
            cursor: 'pointer',
            color: 'black',
            marginRight: 10
          }}
          onClick={() => {
            this.setState({ createExercise: true })
          }}
        >
          <CloudUploadIcon
            style={{
              color: 'lightseagreen',
              fontSize: 26,
              marginBottom: 3,
              marginRight: 5
            }}
          />
          Upload new exercise{' '}
        </div>
        <div
          // className="primary-colour"
          style={{
            // backgroundColor: "#79aec8",
            // padding: 1,

            backgroundColor: 'whitesmoke',
            // padding: 15,
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
            // borderTop: "1px solid #e8e9eb"
            // borderBottom: "1px solid #e8e9eb",
            // backgroundColor: "whitesmoke"
          }}
        >
          <div
            class='no-focus-color'
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <span style={{ width: '30%' }}>
              <Select
                isDisabled={false}
                onChange={event => this.handleExerciseCategorySearch(event)}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  border: 'none',
                  colors: {
                    ...theme.colors,
                    primary25: 'lavenar',
                    primary: 'transparent'
                  }
                })}
                styles={customStyles}
                placeholder={'Select category'}
                options={options}
              />
            </span>
            <input
              disabled={false}
              onChange={this.handleExerciseSearchChange}
              className='search-extra'
              value={this.state.exerciseSearchTerm}
              type='text'
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.handleExerciseSearchClick(event)
                }
              }}
              style={{
                fontSize: '15px',
                color: 'black',
                backgroundColor: 'whitesmoke',
                width: '50%',
                padding: '7.5px 14px',
                // height: "100%",
                borderRadius: '0px 0px 0px 0px',
                border: '1px solid #d9d9d9',
                borderWidth: '0px 0px 0px 0px',
                height: 38,

                borderColor: 'lightgrey',
                textAlign: 'left'
              }}
              placeholder='Search Exercises'
            />
          </div>
        </div>

        <div
          style={{
            padding: 10,
            backgroundColor: '#f0f2f5'
            // borderTop: "1px solid #d9d9d9"
          }}
        >
          {this.getTable()}
          {/* <ExercisePagination /> */}
        </div>
      </div>
    )
  }
}

export default withWindowDimensions(ExerciseLibrary)
