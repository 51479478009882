import { Row, Col, Container } from 'shards-react'

import React, { Component } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Store, Dispatcher, Constants } from '../../flux'

const SortableItem = SortableElement(
  ({ value, sortIndex, props, removeExercise, editExercise, copyExercise }) => (
    <a
      title={value.name}
      style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 200,
        width: 200,
        backgroundImage: 'url( ' + value.image + ')',
        backgroundColor: 'white',
        borderRadius: 5
        // border: "1px solid #e8e9eb"
      }}
    >
      <div
        style={{
          cursor: props.routineEditMode ? 'pointer' : '',
          height: 200,
          width: 200,
          flexDirection: 'column',
          flex: '1',
          display: 'flex',
          backgroundColor: 'transparent',
          borderRadius: 5
        }}
      >
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex'
          }}
        >
          <span
            style={{
              padding: 3,
              paddingLeft: 0,
              backgroundColor: 'lightseagreen',
              borderRadius: 3,
              color: 'white',
              fontSize: 12
              // border: "1px solid black"
            }}
          >
            <div>
              {/* <span
                style={{
                  padding: 5,
                  // paddingLeft: 10,
                  backgroundColor: "black",
                  color: "white"
                  // border: "1px solid #20b2aa"
                }}
              >
                {sortIndex + 1}
              </span>{" "} */}
              <span style={{ marginLeft: 5 }}> {value.length}</span>{' '}
              {value.type_name}
            </div>
            <div className='name-tooltip'>{value.name}</div>
          </span>

          <span
            style={{
              cursor: 'pointer',
              // margin: 2,
              color: 'lightseagreen'
              // borderRadius: 15,
              // backgroundColor: "#ececec"
            }}
          >
            {props.routineEditMode ? (
              <i
                size={2}
                style={{ color: 'black' }}
                onClick={() => removeExercise(value.order)}
                class='material-icons sm-12'
              >
                delete
              </i>
            ) : (
              ''
            )}
          </span>
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            flexDirection: 'column.',
            display: 'flex',
            flexGrow: '1',
            alignItems: 'flex-end'
          }}
        >
          <span
            style={{
              cursor: 'pointer',
              color: 'black'
              // backgroundColor: "white"
              // borderRadius: 15
            }}
          >
            {props.routineEditMode ? (
              <i
                size={2}
                onClick={() => editExercise(value)}
                class='material-icons sm-12'
              >
                edit
              </i>
            ) : (
              ''
            )}
          </span>
          <span
            style={{
              cursor: 'pointer',
              margin: 0,
              color: 'black'

              // backgroundColor: "white"
              // borderRadius: 15
            }}
          >
            {props.routineEditMode ? (
              <i
                size={2}
                style={{ color: 'black' }}
                onClick={() => copyExercise(value)}
                class='material-icons sm-12'
              >
                content_copy
              </i>
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
    </a>
  )
)

const SortableList = SortableContainer(
  ({ items, props, removeExercise, editExercise, copyExercise }) => {
    return (
      <div
        className='grid-responsive'
        style={{
          display: 'inline-grid'
        }}
      >
        {items.map((value, index) => (
          <SortableItem
            editExercise={editExercise}
            removeExercise={removeExercise}
            copyExercise={copyExercise}
            disabled={!props.routineEditMode}
            key={`item-${value}`}
            index={index}
            value={value}
            sortIndex={index}
            props={props}
          />
        ))}
      </div>
    )
  }
)

class CurrentExercises extends Component {
  constructor(props) {
    super(props)

    console.log(Store.getSelectedRoutine())
    this.state = {
      items: []
    }
    this.getAppState = this.getAppState.bind(this)
    this._onChange = this._onChange.bind(this)
    this.removeExercise = this.removeExercise.bind(this)
    this.copyExercise = this.copyExercise.bind(this)
  }

  componentDidMount() {
    Store.addChangeListener(this._onChange)
    this.setState(this.getAppState)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  getAppState() {
    return {
      selectedRoutine: Store.getSelectedRoutine(),
      items: Store.getSelectedRoutine().exercises,
      routineEditMode: Store.getRoutineEditMode()
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex)
    }))

    let routineCopy = Store.getSelectedRoutine()
    routineCopy.exercises = this.state.items

    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_ROUTINE,
      payload: routineCopy
    })

    let exerciseIDs = []
    for (let i = 0; i < this.state.items.length; i++) {
      exerciseIDs.push(this.state.items[i].uuid)
    }

    Dispatcher.dispatch({
      actionType: Constants.PATCH_ROUTINE_EXERCISES,
      payload: {
        routineExerciseUpdates: { exercises: exerciseIDs },
        selectedRoutine: Store.getSelectedRoutine()
      }
    })
  }

  removeExercise(exerciseOrder) {
    let newRoutine = Store.getSelectedRoutine()
    let newItems = [...this.state.items]
    newRoutine.exercises = newItems

    for (let i = 0; i < newRoutine.exercises.length; i++) {
      if (newRoutine.exercises[i].order === exerciseOrder) {
        newRoutine.exercises.splice(i, 1)
      }
    }
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_ROUTINE,
      payload: newRoutine
    })

    let exerciseIDs = []
    for (let i = 0; i < newRoutine.exercises.length; i++) {
      exerciseIDs.push(newRoutine.exercises[i].uuid)
    }

    Dispatcher.dispatch({
      actionType: Constants.PATCH_ROUTINE_EXERCISES,
      payload: {
        routineExerciseUpdates: { exercises: exerciseIDs },
        selectedRoutine: newRoutine
      }
    })
  }

  copyExercise(exercise) {
    if (!this.state.selectedRoutine.uuid) {
      let selectedRoutine = Store.getSelectedRoutine()
      exercise.order = selectedRoutine.exercises.length + 1
      selectedRoutine.exercises.push(exercise)
      Dispatcher.dispatch({
        actionType: Constants.SET_SELECTED_ROUTINE,
        payload: selectedRoutine
      })
    } else {
      Dispatcher.dispatch({
        actionType: Constants.PATCH_ROUTINE,
        payload: {
          routineUpdates: { exercise: exercise.uuid },
          selectedRoutine: Store.getSelectedRoutine()
        }
      })
    }
  }

  render() {
    if (this.state.items && this.state.items.length > 0) {
      return (
        <div style={{}}>
          <SortableList
            axis={'xy'}
            items={this.state.items}
            onSortEnd={this.onSortEnd}
            props={this.props}
            disabled={true}
            removeExercise={this.removeExercise}
            copyExercise={this.copyExercise}
            editExercise={this.props.editExercise}
            distance={1}
          />
        </div>
      )
    } else {
      return (
        <div style={{ paddingTop: 40, textAlign: 'center' }}>
          To add exercises click the
          <span style={{ fontWeight: 'bold' }}> Add Exercise</span> button
          above.
        </div>
      )
    }
  }
}

export default CurrentExercises
