import { EventEmitter } from 'events'

import Dispatcher from './dispatcher'
import Constants from './constants'
import API from '../utils/api.js'

let _store = {
  // Authentication
  token: '',
  prevToken: '',
  personalUse: false,
  selectClient: false,

  // My Recovery
  currentClient: {},
  myDetails: {
    goal: 0,
    goal_statement: '',
    completed: 0,
    completed_details: [],
    username: '',
    setup_progress: {
      goal_setup: false,
      routines_setup: false,
      routine_completed: false
    },
    routines: []
  },

  // Filters
  categories: [],
  selectedCategory: null,

  // Exercise Library
  exerciseLibrary: [],
  exerciseSearchTerm: '',
  exercisePagination: {},
  selectedExercise: {},
  exerciseTotal: 0,
  exerciseLibraryOpen: false,

  // Library
  routineLibrary: [],
  routineSearchTerm: '',
  selectedRoutine: { exercises: [] },
  routinePagination: {},
  usedInApp: {},
  routineTotal: 0,
  routineEditMode: false,
  routineCurrentName: '',

  // Coach
  students: [],

  loading: false,
  menuVisible: false,

  // Waiting on APIs
  registerWorking: false,
  createClientWorking: false,
  routineCreateWorking: false,
  exerciseCreateWorking: false,
  updateExerciseWorking: false,
  updateExerciseComplete: false,
  addingExerciseWorking: false,
  routineLibraryWorking: false,
  exerciseLibraryWorking: false,
  pdfDownloadWorking: false,
  routineSelectWorking: false,
  studentsWorking: false,
  routineCopyWorking: false,

  // Errors
  loginWorking: false,
  clientRegisterErrors: [],
  loginErrors: '',
  registerErrors: '',
  api500: false
}

class Store extends EventEmitter {
  constructor() {
    super()

    this.registerToActions = this.registerToActions.bind(this)
    this.toggleSidebar = this.toggleSidebar.bind(this)

    Dispatcher.register(this.registerToActions.bind(this))
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      // Internal
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar()
        break
      case Constants.SET_MENU_INVISIBLE:
        this.setMenuInvisible()
        break

      // Authentication
      case Constants.LOGIN_ERRORS:
        this.setLoginErrors(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.REGISTER_ERRORS:
        this.setRegisterErrors(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.LOGIN:
        this.wipeTokens()
        API.login(payload.username, payload.password, payload.history)
        this.emit(Constants.CHANGE)
        break
      case Constants.REGISTER:
        this.wipeTokens()
        API.register(
          payload.username,
          payload.email,
          payload.password1,
          payload.password2,
          payload.history
        )
        this.emit(Constants.CHANGE)
        break
      case Constants.SET_CURRENT_TOKEN:
        this.setToken(payload.token)
        break

      case Constants.SET_PREV_TOKEN:
        this.setPrevToken(payload.token)
        break

      // Totals
      case Constants.FETCH_TOTALS:
        API.getTotals()
        break

      case Constants.SET_TOTALS:
        this.setTotals(payload)
        this.emit(Constants.CHANGE)
        break

      // My Recovery
      case Constants.SET_CLIENT_NAME:
        this.setUsername(payload.username)
        break
      case Constants.GET_MY_DETAILS:
        API.getMyDetails()
        this.emit(Constants.CHANGE)
        break
      case Constants.SET_MY_DETAILS:
        this.setMyDetails(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.PATCH_MY_DETAILS:
        API.patchMyDetails(payload)
        break
      case Constants.ASSIGN_ROUTINE:
        API.assignRoutine(payload.routine_uuid, payload.history)
        this.emit(Constants.CHANGE)
        break

      case Constants.DOWNLOAD_PDFS:
        API.downloadRoutinePDFs(payload.routine)
        this.emit(Constants.CHANGE)
        break

      // Exercise Library
      case Constants.SEARCH_EXERCISES:
        API.searchExercises(
          payload.exercise,
          payload.page,
          payload.pageSize,
          payload.category
        )
        this.setExerciseSearchTerm(payload.exercise)
        this.emit(Constants.CHANGE)
        break
      case Constants.SET_EXERCISE_SEARCH_RESULTS:
        this.setExerciseSearchResults(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.SET_SELECTED_EXERCISE:
        this.setSelectedExercise(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.GET_CATEGORIES:
        API.getCategories(this.getPrevToken())
        break
      case Constants.SET_CATEGORIES:
        this.setCategories(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.PATCH_EXERCISE:
        API.patchExercise(
          payload.exerciseUpdates,
          payload.selectedExercise,
          payload.coach
        )
        break
      case Constants.POST_EXERCISE:
        API.postExercise(payload)
        break

      // Routine Library
      case Constants.SEARCH_ROUTINES:
        API.searchRoutines(payload.routine, payload.page, payload.category)
        this.setRoutineSearchTerm(payload.routine)
        this.emit(Constants.CHANGE)
        break

      case Constants.SET_ROUTINE_SEARCH_RESULTS:
        this.setRoutineSearchResults(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.SET_SELECTED_ROUTINE:
        this.setSelectedRoutine(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.PATCH_ROUTINE:
        API.AddExerciseToRoutine(
          payload.routineUpdates,
          payload.selectedRoutine
        )
        this.emit(Constants.CHANGE)
        break
      case Constants.PATCH_ROUTINE_EXERCISES:
        if (payload.selectedRoutine.uuid) {
          API.patchRoutineExercises(
            payload.routineExerciseUpdates,
            payload.selectedRoutine
          )
        }
        break
      case Constants.PATCH_ROUTINE_NAME:
        API.patchRoutineName(payload.routineUpdates, payload.selectedRoutine)
        break
      case Constants.DELETE_ROUTINE:
        API.deleteRoutine(payload.selectedRoutine, payload.history)
        break
      case Constants.POST_ROUTINE:
        API.postRoutine(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.TOGGLE_LOADING:
        this.toggleLoading()
        break
      case Constants.ENSURE_CLIENT_COPY:
        API.ensureRoutineCopy(payload.uuid, payload.history)
        break
      case Constants.GET_ROUTINE_DETAILS:
        if (payload.uuid && !payload.author && !payload.name) {
          API.getRoutineDetails(payload.uuid)
        } else {
          API.getPublicRoutineDetails(payload.client, payload.slug)
        }

        break
      // Coach Others

      case Constants.SET_STUDENT_LIST:
        this.setStudentList(payload)
        this.emit(Constants.CHANGE)
        break
      case Constants.GET_STUDENT_LIST:
        API.getMyStudents()
        break
      case Constants.REGISTER_STUDENT:
        API.registerStudent(
          payload.username,
          payload.email,
          payload.password1,
          payload.password2,
          payload.history
        )
        break

      // Errors
      case Constants.TOGGLE_API_500:
        this.toggleAPI500()
        break
      default:
    }
  }

  // Authentication

  wipeTokens() {
    localStorage.removeItem('token')
    localStorage.removeItem('prevToken')
  }

  getPrevToken() {
    return localStorage.getItem('prevToken')
  }

  getToken() {
    _store.token = localStorage.getItem('token')
    return _store.token
  }

  setPrevToken(token) {
    localStorage.setItem('prevToken', token)
    this.emit(Constants.CHANGE)
  }

  setToken(token) {
    localStorage.setItem('token', token)
    this.emit(Constants.CHANGE)
  }

  getPersonalUse() {
    return _store.personalUse
  }

  switchPersonalUse() {
    _store.personalUse = !_store.personalUse
    this.emit(Constants.CHANGE)
  }

  setRegisterErrors(errors) {
    console.log(errors)
    return (_store.registerErrors = errors)
  }

  getRegisterErrors() {
    return _store.registerErrors
  }

  setLoginErrors(errors) {
    return (_store.loginErrors = errors)
  }

  getLoginErrors() {
    return _store.loginErrors
  }

  getLoginWorking() {
    return _store.loginWorking
  }

  setLoginWorking(working) {
    _store.loginWorking = working
    this.emit(Constants.CHANGE)
  }

  getRegisterWorking() {
    return _store.registerWorking
  }

  setRegisterWorking(working) {
    _store.registerWorking = working
    this.emit(Constants.CHANGE)
  }

  // My Recovery

  setUsername(username) {
    _store.myDetails['username'] = username
  }

  setMyDetails(details) {
    _store.myDetails = details
  }

  getMyDetails() {
    return _store.myDetails
  }

  // Exercise Library

  getExerciseLibrary() {
    return _store.exerciseLibrary
  }

  setExerciseSearchResults(exercises) {
    _store.exerciseLibrary = exercises.results
    _store.exercisePagination = exercises
    delete exercises.results
  }

  getExerciseSearchTerm() {
    return _store.exerciseSearchTerm
  }

  getExercisePagination() {
    return _store.exercisePagination
  }

  setExerciseSearchTerm(searchTerm) {
    _store.exerciseSearchTerm = searchTerm
    this.emit(Constants.CHANGE)
  }

  setCategories(categories) {
    _store.categories = categories
  }

  getCategories() {
    return _store.categories
  }

  getSelectedExercise() {
    return JSON.parse(localStorage.getItem('selectedExercise'))
  }

  setSelectedExercise(exercise) {
    localStorage.setItem('selectedExercise', JSON.stringify(exercise))
  }

  toggleExerciseLibraryOpen() {
    _store.exerciseLibraryOpen = !_store.exerciseLibraryOpen
    this.emit(Constants.CHANGE)
  }

  closeExerciseModal() {
    _store.exerciseLibraryOpen = false
    this.emit(Constants.CHANGE)
  }

  getExerciseLibraryOpen() {
    return _store.exerciseLibraryOpen
  }

  // Routine Library

  getRoutineCurrentCategory() {
    return _store.routineCurrentCategory
  }

  setRoutineCurrentCategory(category) {
    _store.routineCurrentCategory = category
    this.emit(Constants.CHANGE)
  }

  getRoutineCurrentName() {
    return _store.routineCurrentName
  }

  setRoutineCurrentName(name) {
    _store.routineCurrentName = name
    this.emit(Constants.CHANGE)
  }

  getRoutineEditMode() {
    return _store.routineEditMode
  }

  setRoutineEditMode(mode) {
    _store.routineEditMode = mode
    this.emit(Constants.CHANGE)
  }

  getRoutineSearchTerm() {
    return _store.routineSearchTerm
  }

  setRoutineSearchResults(routines) {
    _store.routineLibrary = routines.results
    _store.routinePagination = routines
    delete routines.results
    this.calcualteUsedInApp()
  }

  getRoutineSearchTerm() {
    return _store.routineSearchTerm
  }

  getRoutinePagination() {
    return _store.routinePagination
  }

  getRoutines() {
    return _store.routineLibrary
  }

  setRoutines(routines) {
    _store.routineLibrary = routines
  }

  getSelectedRoutine() {
    return _store.selectedRoutine
  }

  setSelectedRoutine(routine) {
    _store.selectedRoutine = routine
  }

  setRoutineSearchTerm(searchTerm) {
    _store.routineSearchTerm = searchTerm
  }

  getSelectedRoutine() {
    return JSON.parse(localStorage.getItem('selectedRoutine'))
  }

  setSelectedRoutine(routine) {
    localStorage.setItem('selectedRoutine', JSON.stringify(routine))
  }

  setRoutineSearchTerm(searchTerm) {
    _store.routineSearchTerm = searchTerm
    this.emit(Constants.CHANGE)
  }

  calcualteUsedInApp() {
    if (!_store.routineLibrary) {
      return
    }
    let usedInApp = {}
    for (let i = 0; i < _store.routineLibrary.length; i++) {
      // change type from str (uuid) to bool to make toggling possible
      if (_store.routineLibrary[i]['used_in_app_by']) {
        usedInApp[_store.routineLibrary[i]['uuid']] = true
      } else {
        usedInApp[_store.routineLibrary[i]['uuid']] = false
      }
      _store.usedInApp = usedInApp
    }
  }

  getUsedInApp() {
    return _store.usedInApp
  }

  toggleUsedInApp(uuid) {
    _store.usedInApp[uuid] = !_store.usedInApp
    this.emit(Constants.CHANGE)
  }

  // Totals

  setTotals(totals) {
    _store.exerciseTotal = totals.exercises
    _store.routineTotal = totals.routines
  }

  getExerciseTotal() {
    return _store.exerciseTotal
  }

  getRoutineTotal() {
    return _store.routineTotal
  }

  // Coach

  setStudentList(studentList) {
    _store.students = studentList
  }

  getStudentList() {
    return _store.students
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible
    this.emit(Constants.CHANGE)
  }

  setMenuInvisible() {
    _store.menuVisible = false
    this.emit(Constants.CHANGE)
  }

  // Working

  getUpdateExerciseComplete() {
    return _store.updateExerciseComplete
  }

  setUpdateExerciseComplete(complete) {
    _store.updateExerciseComplete = complete
    this.emit(Constants.CHANGE)
  }

  getUpdateExerciseWorking() {
    return _store.updateExerciseWorking
  }

  setUpdateExerciseWorking(working) {
    _store.updateExerciseWorking = working
    this.emit(Constants.CHANGE)
  }

  getExerciseLibraryWorking() {
    return _store.exerciseLibraryWorking
  }

  setExerciseLibraryWorking(working) {
    _store.exerciseLibraryWorking = working
    this.emit(Constants.CHANGE)
  }

  getRoutineCopyWorking() {
    return _store.routineCopyWorking
  }

  setRoutineCopyWorking(working) {
    _store.routineCopyWorking = working
    this.emit(Constants.CHANGE)
  }

  getStudentsWorking() {
    return _store.studentsWorking
  }

  setStudentsWorking(working) {
    _store.studentsWorking = working
    this.emit(Constants.CHANGE)
  }

  getSelectRoutineWorking() {
    return _store.selectRoutineWorking
  }

  setSelectRoutineWorking(working) {
    _store.selectRoutineWorking = working
    this.emit(Constants.CHANGE)
  }
  getPDFDownloadWorking() {
    return _store.pdfDownloadWorking
  }

  setPDFDownloadWorking(working) {
    _store.pdfDownloadWorking = working
    this.emit(Constants.CHANGE)
  }

  getCreateClientWorking() {
    return _store.createClientWorking
  }

  setCreateClientWorking(working) {
    _store.createClientWorking = working
    this.emit(Constants.CHANGE)
  }

  toggleLoading() {
    _store.loading = !_store.loading
    this.emit(Constants.CHANGE)
  }

  getLoading() {
    return _store.loading
  }

  getRoutineCreateWorking() {
    return _store.routineCreateWorking
  }

  setRoutineCreateWorking(working) {
    _store.routineCreateWorking = working
    this.emit(Constants.CHANGE)
  }

  getExerciseCreateWorking() {
    return _store.exerciseCreateWorking
  }

  setExerciseCreateWorking(working) {
    _store.exerciseCreateWorking = working
    this.emit(Constants.CHANGE)
  }

  getAddingExerciseWorking() {
    return _store.addingExerciseWorking
  }

  setAddingExerciseWorking(working) {
    _store.addingExerciseWorking = working
    this.emit(Constants.CHANGE)
  }

  getRoutineLibraryWorking() {
    return _store.routineLibraryWorking
  }

  setRoutineLibraryWorking(working) {
    _store.routineLibraryWorking = working
    this.emit(Constants.CHANGE)
  }

  // Errors

  toggleAPI500() {
    _store.api500 = !_store.api500
    this.emit(Constants.CHANGE)
  }

  getAPI500() {
    return _store.api500
  }

  setClientRegisterErrors(errors) {
    _store.clientRegisterErrors = errors
    this.emit(Constants.CHANGE)
  }

  getClientRegisterErrors() {
    return _store.clientRegisterErrors
  }

  // Filters

  getSelectedCategory() {
    return _store.selectedCategory
  }

  setSelectedCategory(category) {
    _store.selectedCategory = category
    this.emit(Constants.CHANGE)
  }

  // Internal

  setSelectClientToggle(option) {
    _store.selectClient = option
    this.emit(Constants.CHANGE)
  }

  getSelectClient() {
    return _store.selectClient
  }

  getMenuState() {
    return _store.menuVisible
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback)
  }
}

export default new Store()
