import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container, Navbar, NavbarBrand } from 'shards-react'

import NavbarSearch from './NavbarSearch'
import NavbarNav from './NavbarNav/NavbarNav'
import NavbarToggle from './NavbarToggle'
import { Store, Dispatcher, Constants } from '../../../flux'
import { Button } from '@material-ui/core'

import { Link } from 'react-router-dom'

class MainNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getAppState()
    this.getAppState = this.getAppState.bind(this)
    this._onChange = this._onChange.bind(this)
    this.onSwitch = this.onSwitch.bind(this)
  }

  componentDidMount() {
    Store.addChangeListener(this._onChange)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  getAppState() {
    return { clientDetails: Store.getMyDetails() }
  }

  onSwitch() {
    const prevToken = localStorage.getItem('prevToken')
    if (localStorage.getItem('prevToken') != null) {
      localStorage.setItem('token', prevToken)
      localStorage.removeItem('prevToken')
      this.props.history.push('/clients/')
    }
  }

  render() {
    const classes = classNames(
      'main-navbar',
      'bg-white',
      this.props.stickyTop && 'sticky-top'
    )

    return (
      <div className={classes}>
        <Navbar
          type='light'
          className='align-items-stretch flex-md-nowrap p-0'
          style={{
            height: 70,

            webkitBoxShadow: '0px 5px 15px -9px rgba(0, 0, 0, 0.75)',
            mozBoxShadow: '0px 5px 15px -9px rgba(0, 0, 0, 0.75)',
            boxShadow: '0px 5px 15px -9px rgba(0, 0, 0, 0.75)'
          }}
        >
          <NavbarBrand href='/setup/'>
            <div>
              <span
                style={{
                  color: '#00a652',
                  fontSize: 30,
                  paddingTop: 10,
                  paddingLeft: 130,
                  fontFamily: 'AzoSans'
                }}
              >
                Recover <span style={{ color: 'black' }}>Hub </span>{' '}
              </span>
            </div>
          </NavbarBrand>
          <div>
            <span style={{ fontSize: 30, paddingTop: 10 }}>
              <Link style={{ color: 'black' }} to='/setup/'>
                {this.state.clientDetails.username
                  ? this.state.clientDetails.username
                  : '...'}
              </Link>
            </span>
            <span>
              <a href='/clients/' onClick={() => this.onSwitch()}>
                {this.state.clientDetails.username ? 'Switch' : ''}{' '}
              </a>
            </span>
          </div>

          <NavbarNav />
        </Navbar>
      </div>
    )
  }
}

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
}

MainNavbar.defaultProps = {
  stickyTop: true
}

export default MainNavbar
