import React, { Component } from 'react'
import {
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Container,
  Row,
  Col,
  Alert,
  Button,
  Card
} from 'shards-react'

import CurrentExercises from '../components/routine_library/CurrentExercises'
import { Store, Dispatcher, Constants } from '../flux'
import PropTypes from 'prop-types'
import api from '../utils/api'
import ScrollableFeed from 'react-scrollable-feed'

class UpdateRoutine extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.renderAddingAlert = this.renderAddingAlert.bind(this)
    this.handleAddExercise = this.handleAddExercise.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    console.log(this.state)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.updateRoutine = this.updateRoutine.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleDoubleClick = this.handleDoubleClick.bind(this)
  }
  getAppState() {
    return {
      exercises: Store.getExerciseLibrary(),
      selectedRoutine: Store.getSelectedRoutine(),
      name: Store.getRoutineCurrentName(),
      exerciseCategories: Store.getCategories(),
      shouldDisplayAddingAlert: false,
      addExerciseWorking: Store.getAddingExerciseWorking(),
      selectRoutineWorking: Store.getSelectRoutineWorking(),
      category: Store.getRoutineCurrentCategory(),
      categories: Store.getCategories()
    }
  }
  componentDidMount() {
    Store.setRoutineEditMode(true)
    Store.addChangeListener(this._onChange)
    Store.setExerciseSearchTerm('')
    Dispatcher.dispatch({ actionType: Constants.GET_CATEGORIES })

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.hardRefresh
    ) {
      Dispatcher.dispatch({
        actionType: Constants.SET_SELECTED_ROUTINE,
        payload: { name: '', exercises: [] }
      })
    }
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  handleDoubleClick(exercise) {
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_EXERCISE,
      payload: exercise
    })
    this.props.history.push('/exercise-details/', { newRoutine: true })
  }

  handleAddExercise(event, values) {
    if (!values) {
      return
    }
    if ('uuid' in values == false) {
      return
    }
    this.setState(
      {
        exerciseToAdd: values
      },
      () => {
        Dispatcher.dispatch({
          actionType: Constants.PATCH_ROUTINE,
          payload: {
            routineUpdates: { exercise: values.uuid },
            selectedRoutine: this.state.selectedRoutine
          }
        })
      }
    )
  }

  handleDoubleClick(exercise) {
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_EXERCISE,
      payload: exercise
    })
    this.props.history.push('/exercise-details/', { newRoutine: true })
  }

  handleNameChange(e) {
    Store.setRoutineCurrentName(e.target.value)
  }

  handleDescriptionChange(e) {
    this.setState({
      description: e.target.value
    })
    console.log(this.state.description)
  }

  deleteRoutine(e) {
    Dispatcher.dispatch({
      actionType: Constants.DELETE_ROUTINE,
      payload: {
        selectedRoutine: this.state.selectedRoutine
      }
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_ROUTINES,
      payload: { routine: '', page: 1 }
    })
  }

  updateRoutine(e) {
    const routineUpdates = this.state.selectedRoutine
    routineUpdates.name = this.state.name
    routineUpdates.description = this.state.description

    Dispatcher.dispatch({
      actionType: Constants.PATCH_ROUTINE_NAME,
      payload: {
        routineUpdates: {
          name: this.state.name,
          description: this.state.description
        },
        selectedRoutine: this.state.selectedRoutine
      }
    })
  }

  getEventHandlers() {
    return {
      addExercise: (e, values) => this.handleAddExercise(e, values),
      addRoutine: (e, values) => this.handleAddExercise(e, values),
      handleNameChange: e => this.handleNameChange(e),
      handleDescriptionChange: e => this.handleDescriptionChange(e),
      createOrUpdate: e => this.updateRoutine(e),
      deleteRoutine: e => this.deleteRoutine(e),
      doubleClick: e => this.handleDoubleClick(e)
    }
  }

  handleUsedInAppChange(routine) {
    console.log(routine.uuid)
    const usedInAppNew = this.state.usedInApp
    usedInAppNew[routine.uuid] = !usedInAppNew[routine.uuid]
    console.log(usedInAppNew[routine.uuid])
    this.setState({
      usedInApp: usedInAppNew
    })
    if (usedInAppNew[routine.uuid]) {
      this.handleAddRoutine(routine.uuid)
    } else {
      this.handleDeleteRoutine(routine)
    }
  }

  handleAddRoutine() {
    let routine = Store.getSelectedRoutine()
    routine.name = this.state.name
    routine.category = this.state.category
    let exercise_uuids = []
    for (let i = 0; i < routine.exercises.length; i++) {
      exercise_uuids.push(routine.exercises[i].uuid)
    }
    routine.exercise_uuids = exercise_uuids
    if (!routine.uuid) {
      console.log('POSTED')
      api.postRoutine(routine, this.props.history)
    } else {
      console.log('PATCHED')
      api.patchRoutineExercises(
        { exercises: exercise_uuids },
        routine,
        this.props.history
      )
    }
    Store.setRoutineCurrentName('')
    Store.setRoutineCurrentCategory('')
  }

  renderAddingAlert() {
    if (this.state.shouldDisplayAddingAlert) {
      return (
        <Alert className='mb-0'>
          <i className='fa fa-info mx-2' />
          Adding ...{' '}
        </Alert>
      )
    }
  }

  getRoutineImage(routine) {
    if (routine.exercises.length > 0) {
      return routine.exercises[0].image
    } else {
      return null
    }
  }

  handleCategoryChange(e) {
    Store.setRoutineCurrentCategory(e.target.value)
  }

  render() {
    const { selectedRoutine, exercises, addExerciseWorking } = this.state
    const eventHandlers = this.getEventHandlers()
    return (
      <div>
        <div>
          <div
            style={{ display: 'flex', flexDirection: 'column', paddingLeft: 5 }}
          >
            <span style={{ paddingTop: 0 }}>
              <Button
                theme='light'
                style={{
                  width: '20%',
                  border: '1px lightgrey solid'
                }}
                onClick={() => {
                  this.props.history.push('/routines')
                }}
              >
                Discard Routine
              </Button>
              <FormInput
                style={{ display: 'inline-block', width: '20%' }}
                value={Store.getRoutineCurrentName()}
                onChange={this.handleNameChange}
                id='feFirstName'
                placeholder={'Name (required)'}
              />

              {/* Catgeory */}

              <FormSelect
                style={{ width: '20%' }}
                id='feInputState'
                value={Store.getRoutineCurrentCategory()}
                onChange={this.handleCategoryChange}
              >
                <option value='none' selected disabled hidden>
                  {' '}
                  Select a Category
                </option>
                {this.state.categories.map((category, idx) => (
                  <option value={category.uuid}>{category.name}</option>
                ))}
              </FormSelect>

              <Button
                onClick={() => {
                  this.props.history.push('/exercises/', {
                    newRoutine: true
                  })
                }}
                theme='light'
                size='md'
                style={{
                  width: '20%',
                  border: '1px lightgrey solid'
                }}
              >
                Add Exercise
              </Button>

              <Button
                size='md'
                style={{
                  width: '20%',
                  border: '1px lightgrey solid'
                }}
                onClick={() => this.handleAddRoutine()}
                theme='success'
                disabled={!this.state.name}
              >
                {this.state.selectRoutineWorking ? 'Working' : 'Save Routine'}
              </Button>
            </span>
          </div>

          <div style={{ width: '100%' }}>
            <CurrentExercises
              routine={selectedRoutine}
              eventHandlers={eventHandlers}
              routineEditMode={true}
              editExercise={this.handleDoubleClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

UpdateRoutine.propTypes = {
  /**
   * The component's title.
   */
  selectedRoutine: PropTypes.object
}

export default UpdateRoutine
