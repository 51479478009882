import React, { Component } from 'react'
import {
  Container,
  Button,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea
} from 'shards-react'

import PageTitle from '../components/common/PageTitle'

import CreateRoutine from '../components/routine_library/CreateRoutine'
import { Store, Dispatcher, Constants } from '../flux'
import PropTypes from 'prop-types'
import API from '../utils/api.js'

class NewRoutine extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.renderAddingAlert = this.renderAddingAlert.bind(this)
    this.handleAddExercise = this.handleAddExercise.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.createRoutine = this.createRoutine.bind(this)
    this.check500 = this.check500.bind(this)
  }
  getAppState() {
    return {
      exercises: Store.getExerciseLibrary(),
      selectedRoutine: Store.getSelectedRoutine(),
      categories: Store.getCategories(),
      shouldDisplayAddingAlert: false,
      name: '',
      description: '',
      category: null,
      routineCreateWorking: Store.getRoutineCreateWorking()
    }
  }
  componentDidMount() {
    Store.addChangeListener(this._onChange)
    Dispatcher.dispatch({ actionType: Constants.GET_CATEGORIES })
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  handleAddExercise = (event, values) => {
    this.setState(
      {
        exerciseToAdd: values
      },
      () => {
        Dispatcher.dispatch({
          actionType: Constants.PATCH_ROUTINE,
          payload: {
            routineUpdates: { exercise: values.uuid },
            selectedRoutine: this.state.selectedRoutine
          }
        })
      }
    )
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value
    })
  }

  handleDescriptionChange(e) {
    this.setState({
      description: e.target.value
    })
    console.log(this.state.description)
  }

  handleCategoryChange(e) {
    console.log(e.target.value)
    this.setState({ category: e.target.value })
  }

  deleteRoutine(e) {
    Dispatcher.dispatch({
      actionType: Constants.DELETE_ROUTINE,
      payload: {
        selectedRoutine: this.state.selectedRoutine
      }
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_ROUTINES,
      payload: { routine: '', page: 1 }
    })
  }

  createRoutine(e) {
    const payload = {
      name: this.state.name,
      description: this.state.description,
      category: this.state.category,
      exercises: []
    }
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_ROUTINE,
      payload: payload
    })
    this.props.history.push('/create-routine-exercises')
  }

  getEventHandlers() {
    return {
      addExercise: e => this.handleAddExercise(e),
      handleNameChange: e => this.handleNameChange(e),
      handleDescriptionChange: e => this.handleDescriptionChange(e),
      createRoutine: e => this.createRoutine(e),
      deleteRoutine: e => this.deleteRoutine(e)
    }
  }

  renderAddingAlert() {
    if (this.state.shouldDisplayAddingAlert) {
      return (
        <Alert className='mb-0'>
          <i className='fa fa-info mx-2' />
          Adding ...{' '}
        </Alert>
      )
    }
  }

  forceLogin() {
    if (localStorage.getItem('token') == null) {
      this.props.history.push('/auth/')
    }
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  render() {
    this.forceLogin()
    this.check500()
    const { routineCreateWorking } = this.state
    return (
      <Container
        style={{ justifyContent: 'center', alignItems: 'center' }}
        fluid
        className='main-content-container px-4'
      >
        {this.renderAddingAlert()}
        <Row
          style={{
            marginTop: 10,
            padding: 20,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h3>Create Routine</h3>
        </Row>
        <Row
          style={{
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            theme='light'
            onClick={() => {
              this.props.history.push('/routines')
            }}
          >
            {' '}
            Go back
          </Button>
        </Row>
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Col sm='5' md='5' lg='5'>
            <Card small className='h-100'>
              {/* Card Header */}

              <CardBody className='d-flex flex-column'>
                <Form className='quick-post-form'>
                  {/* Title */}
                  <FormGroup>
                    <FormInput
                      style={{ marginBottom: 10 }}
                      onChange={this.handleNameChange}
                      id='feFirstName'
                      placeholder={'Enter a new name'}
                    />

                    {/* Catgeory */}

                    <FormSelect
                      style={{ marginBottom: 10 }}
                      id='feInputState'
                      onChange={this.handleCategoryChange}
                    >
                      <option value='none' selected disabled hidden>
                        {' '}
                        Select a Category
                      </option>
                      {this.state.categories.map((category, idx) => (
                        <option value={category.uuid}>{category.name}</option>
                      ))}
                    </FormSelect>

                    <FormTextarea
                      style={{ marginBottom: 10 }}
                      onChange={this.handleDescriptionChange}
                      placeholder='Description'
                    />
                  </FormGroup>

                  {/* Create Draft */}
                  <FormGroup className='mb-0'>
                    <Button theme='accent' onClick={this.createRoutine}>
                      {this.state.routineCreateWorking ? 'Working' : 'Next'}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

NewRoutine.propTypes = {
  /**
   * The component's title.
   */
  selectedRoutine: PropTypes.object
}

export default NewRoutine
