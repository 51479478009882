/* eslint jsx-a11y/anchor-is-valid: 0 */
import Highlighter from 'react-highlight-words'

import React from 'react'
import { Button, Container, Row, Col, Card } from 'shards-react'

import 'rc-pagination/assets/index.css'
import { Store, Dispatcher, Constants } from '../flux'

import ExercisePagination from '../components/exercise_library/ExercisePagination'
import withWindowDimensions from '../components/common/withWindowDimensions'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ReactLoading from 'react-loading'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

class ExerciseLibrary extends React.Component {
  constructor(props) {
    super(props)
    this.handlePageChange = this.handlePageChange.bind(this)
    this._onChange = this._onChange.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    this.forceLogin = this.forceLogin.bind(this)
    this.getTableItems = this.getTableItems.bind(this)
    this.getTable = this.getTable.bind(this)
    this.assignToRoutine = this.assignToRoutine.bind(this)
    this.state.categoriesOpen = false
    this.renderCategories = this.renderCategories.bind(this)
  }

  getAppState() {
    return {
      exerciseLibraryWorking: Store.getExerciseLibraryWorking(),
      exerciseLibrary: Store.getExerciseLibrary(),
      exerciseSearchTerm: Store.getExerciseSearchTerm(),
      exercisePagination: Store.getExercisePagination(),
      categories: Store.getCategories(),
      selectedCategory: Store.getSelectedCategory(),
      selectedRoutine: Store.getSelectedRoutine(),
      page: 1,
      exerciseTotal: Store.getExerciseTotal()
    }
  }
  componentDidMount() {
    Store.addChangeListener(this._onChange)
    Dispatcher.dispatch({
      actionType: Constants.SET_MENU_INVISIBLE
    })
    Dispatcher.dispatch({
      actionType: Constants.FETCH_TOTALS
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: {
        exercise: this.state.exerciseSearchTerm,
        page: 1,
        pageSize: 1000,
        category: null
      }
    })
    Dispatcher.dispatch({
      actionType: Constants.GET_CATEGORIES
    })
    // Store.setSelectedCategory(null);
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  handlePageChange(page, pageSize) {
    this.setState({
      page: page
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: '', page: page, pageSize: 1000 }
    })
  }

  getExerciseCount() {
    if ('count' in this.state.exercisePagination) {
      return this.state.exercisePagination.count
    }
    return 0
  }

  onRoutineChange(e) {
    console.log(e.target.value)
  }

  navigateToExerciseDetail(exercise) {
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_EXERCISE,
      payload: exercise
    })

    this.props.history.push('/exercise-details')
  }

  assignToRoutine(exercise) {
    if (!this.state.selectedRoutine.uuid) {
      console.log('LOCOAL ROUTINE')
      let selectedRoutine = Store.getSelectedRoutine()
      exercise.order = selectedRoutine.exercises.length + 1
      selectedRoutine.exercises.push(exercise)
      Dispatcher.dispatch({
        actionType: Constants.SET_SELECTED_ROUTINE,
        payload: selectedRoutine
      })
      this.props.history.push('/create-routine-exercises')
    } else {
      Dispatcher.dispatch({
        actionType: Constants.PATCH_ROUTINE,
        payload: {
          routineUpdates: { exercise: exercise.uuid },
          selectedRoutine: this.state.selectedRoutine
        }
      })
      this.props.history.push('/routine-details')
    }
  }

  forceLogin() {
    if (Store.getToken() == null) {
      this.props.history.push('/landing/')
    }
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  handleCategorySearch(category) {
    Store.setSelectedCategory(category)
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: '', page: 1, category: category, pageSize: 1000 }
    })
  }

  getTableItems() {
    if (!this.state.exerciseLibrary) {
      return []
    }

    return this.state.exerciseLibrary.map(exercise => (
      <figure class='grid_item grid_item-2'>
        <div class='text'>{exercise.name}</div>

        {/* <div class="text-details">
            Contains {exercise.exercises.length} Exercise(s) <br></br> 
          </div> */}
        <img
          onClick={() => this.assignToRoutine(exercise)}
          class='grid_image'
          src={exercise.image}
          alt=''
        />
      </figure>
    ))
  }

  getTable() {
    if (!this.state.exerciseLibrary) {
      return (
        <div
          style={{
            textAlign: 'center',
            // borderTop: "1px solid #d9d9d9",
            paddingTop: 50
          }}
        >
          <div style={{ paddingTop: 10 }} className='error__content'>
            <ReactLoading
              className='searchLoading'
              type='bubbles'
              color='lightseagreen'
              width={100}
              height={100}
            />
          </div>
        </div>
      )
    }
    if (
      (this.state.exerciseLibrary.length == 0) &
      this.state.exerciseLibraryWorking
    ) {
      return (
        <div
          style={{
            textAlign: 'center',
            // borderTop: "1px solid #d9d9d9",
            paddingTop: 50
          }}
        >
          <div style={{ paddingTop: 10 }} className='error__content'>
            <ReactLoading
              className='searchLoading'
              type='bubbles'
              color='lightseagreen'
              width={100}
              height={100}
            />
          </div>
        </div>
      )
    }

    if (this.state.exerciseLibrary.length == 0) {
      return (
        <div
          style={{
            textAlign: 'center',

            paddingTop: 50
          }}
        >
          <div className='error__content'>
            <h2>No Results</h2>
            {/* <h3>{Store.getRoutineSearchTerm()}</h3> */}
            <p>We could not find any exercises matching your search</p>
          </div>
        </div>
      )
    }

    return <div class='grid-container-small'> {this.getTableItems()} </div>
  }

  changeVisibility(category) {
    // if (category.number_of_routines) {
    return (
      <span style={{ padding: 5 }}>
        <span class='dot' style={{ backgroundColor: category.colour }} />
        <span style={{ color: 'black' }}> {category.name} </span>
        <span
          style={{
            fontSize: 10,
            color: 'black',
            marginTop: 5,
            marginRight: 5,
            float: 'right'
          }}
        >
          {category.number_of_exercises}
        </span>
      </span>
    )
    // }
    return ''
  }
  renderCategories(categories) {
    if (this.state.categoriesOpen || !this.props.isMobileSized) {
      return (
        <ul
          style={{
            paddingLeft: 0,
            textAlign: 'left',
            listStyleType: 'none',
            cursor: 'pointer',
            color: '#9e9e9e'
          }}
        >
          <li
            class='category-highlight'
            style={{
              // color: "#058C42",
              color: this.state.selectedCategory == null ? 'black' : 'black',
              width: '90%',
              backgroundColor:
                this.state.selectedCategory == null ? 'white' : 'white',
              fontSize: 22,
              borderRadius: 5
            }}
            onClick={() => this.handleCategorySearch(null)}
          >
            <span style={{ padding: 5 }}>
              <span
                class='dot'
                style={{ marginRight: 10, backgroundColor: 'pink' }}
              />
              <span>All </span>

              <span
                style={{
                  fontSize: 12,
                  color: 'black',
                  marginTop: 5,
                  marginRight: 10,
                  float: 'right'
                }}
              >
                {this.state.exerciseTotal}
              </span>
            </span>
          </li>
          {categories.map(category => (
            <li
              class='category-highlight'
              style={{
                color:
                  this.state.selectedCategory == category.name
                    ? 'black'
                    : '#636970',
                backgroundColor:
                  this.state.selectedCategory == category.name
                    ? 'white'
                    : 'white',
                width: '90%',
                fontSize: 20,
                borderRadius: 5
                // color: "#058C42"
              }}
              onClick={() => this.handleCategorySearch(category.name)}
            >
              {this.changeVisibility(category)}
            </li>
          ))}
        </ul>
      )
    }
  }

  render() {
    this.forceLogin()
    this.check500()
    let categories = this.state.categories

    return (
      <div>
        <div
          // className="primary-colour"
          style={{
            // backgroundColor: "#79aec8",
            // padding: 1,

            backgroundColor: 'whitesmoke',
            // padding: 15,
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
            // borderTop: "1px solid #e8e9eb"
            // borderBottom: "1px solid #e8e9eb",
            // backgroundColor: "whitesmoke"
          }}
        >
          <span
            onClick={() => {
              if (!Store.getSelectedRoutine().uuid) {
                this.props.history.push('/create-routine-exercises/')
              } else {
                this.props.history.push('/routine-details/')
              }
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              // flexDirection: "row",
              width: '8.3%',
              padding: 10,
              justifyContent: 'center',
              backgroundColor: 'whitesmoke',

              color: 'black',
              fontSize: '14px',
              // textAlign: "center",

              fontWeight: '400'
              // height: "100%",
              // borderRadius: 5,
              // height: "100%"
              // borderLeft: "1px solid #e8e9eb",
              // borderBottom:  this.state.selectedCategory == category.name
              // ? "1px solid black"
              // : "1px solid whitesmoke",

              // borderRadius: 5,
            }}
          >
            <ArrowBackIosIcon style={{ marginTop: 4, fontSize: '12px' }} />
            Back
          </span>

          <span
            onClick={() => {
              this.props.history.push('/create-exercise/', {
                newRoutine:
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.newRoutine
                    ? true
                    : false
              })
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              // flexDirection: "row",
              width: '8.1%',
              padding: 10,
              justifyContent: 'center',
              backgroundColor: 'lightseagreen',
              borderWidth: '1px',
              color: 'white',
              fontSize: '14px',
              // textAlign: "center",

              // fontWeight: "400"
              // height: "100%",
              // borderRadius: 5,
              // height: "100%"
              // borderLeft: "1px solid #e8e9eb",
              // borderBottom:  this.state.selectedCategory == category.name
              // ? "1px solid black"
              // : "1px solid whitesmoke",

              borderRadius: 3,

              border: '1px solid #d9d9d9'
            }}
          >
            New Exercise
          </span>
        </div>

        <div
          style={{
            padding: 10,
            backgroundColor: '#f0f2f5'
            // borderTop: "1px solid #d9d9d9"
          }}
        >
          {this.getTable()}
          {/* <ExercisePagination /> */}
        </div>
      </div>
    )
  }
}

export default withWindowDimensions(ExerciseLibrary)
