import React from 'react'
import { Store, Dispatcher, Constants } from '../flux'

import {
  FormCheckbox,
  Container,
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  FormTextarea,
  FormInput,
  CardHeader
} from 'shards-react'

class ClientDetails extends React.Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.handleGoalChange = this.handleGoalChange.bind(this)
    this.handleGoalStatementChange = this.handleGoalStatementChange.bind(this)
    this.handleGoalUpdateClick = this.handleGoalUpdateClick.bind(this)
    this.handleConditionDescriptionChange = this.handleConditionDescriptionChange.bind(
      this
    )

    this.state = {
      myDetails: Store.getMyDetails()
    }
  }

  getAppState() {
    return { myDetails: Store.getMyDetails() }
  }

  handleGoalChange(e) {
    const myDetailsNew = this.state.myDetails
    myDetailsNew.goal = e.target.value
    this.setState({
      myDetails: myDetailsNew
    })
  }

  handleConditionDescriptionChange(e) {
    const myDetailsNew = this.state.myDetails
    myDetailsNew.condition_description = e.target.value
    this.setState({
      myDetails: myDetailsNew
    })
  }

  handleGoalStatementChange(e) {
    const myDetailsNew = this.state.myDetails
    myDetailsNew.goal_statement = e.target.value
    this.setState({
      myDetails: myDetailsNew
    })
  }

  handleGoalUpdateClick(e) {
    const { myDetails } = this.state
    Dispatcher.dispatch({
      actionType: Constants.PATCH_MY_DETAILS,
      payload: {
        goal: myDetails.goal,
        goal_statement: myDetails.goal_statement,
        condition_description: myDetails.condition_description
      }
    })
  }

  componentDidMount() {
    Store.addChangeListener(this._onChange)
    Dispatcher.dispatch({
      actionType: Constants.GET_MY_DETAILS
    })
    this.setState(this.getAppState)
  }

  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }

  _onChange() {
    this.setState(this.getAppState)
  }
  render() {
    const { myDetails } = this.state
    return (
      <div
        style={{ paddingTop: 10, display: 'flex', justifyContent: 'center' }}
      >
        <Card
          style={{
            paddingBottom: 15,
            width: '80%'
          }}
        >
          <CardHeader>Details of {myDetails.username}</CardHeader>
          {/* <CardImg src="https://place-hold.it/300x200" /> */}
          <CardBody>
            <Row form>
              <Col md='12' className='form-group'>
                <label style={{ paddingBottom: 15, paddingTop: 15 }}>
                  Diagnosis{' '}
                </label>
                <FormTextarea
                  onChange={this.handleConditionDescriptionChange}
                  value={myDetails.condition_description}
                />

                <label style={{ paddingBottom: 15, paddingTop: 15 }}>
                  Goal
                </label>
                <FormTextarea
                  onChange={this.handleGoalStatementChange}
                  value={myDetails.goal_statement}
                />

                <label style={{ paddingBottom: 15, paddingTop: 15 }}>
                  Number of exercise programs to complete
                </label>
                <FormInput
                  id='feEmail'
                  type='number'
                  onChange={this.handleGoalChange}
                  value={myDetails.goal}
                />
              </Col>
            </Row>
            <Button
              style={{ marginRight: 5 }}
              theme='light'
              onClick={() => {
                this.props.history.push('/routines/')
              }}
            >
              Go Back To Routines
            </Button>
            <Button theme='success' onClick={this.handleGoalUpdateClick}>
              {!myDetails.setup_progress.goal_setup ? 'Save' : 'Save'}
            </Button>
          </CardBody>
        </Card>
      </div>
    )
  }
}

export default ClientDetails
