import React, { Component } from 'react'
import { Container, Alert } from 'shards-react'
import { Store } from '../flux'

class Logout extends Component {
  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
  }
  logout() {
    localStorage.removeItem('prevToken')
    localStorage.removeItem('token')
    this.props.history.push('/landing/')
  }

  forceLogin() {
    if (Store.getToken() === '') {
      this.props.history.push('/auth/')
    }
  }

  render() {
    this.forceLogin()
    this.logout()
    return (
      <Container fluid className='main-content-container px-4'>
        <Alert className='mb-0' theme='error'>
          <i className='fa fa-info mx-2' />
          Something went wrong.{' '}
        </Alert>
      </Container>
    )
  }
}

export default Logout
