import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'shards-react'

import MainNavbarNoSearch from '../components/layout/MainNavbar/MainNavBarNoSearch'

import MainNavbar from '../components/layout/MainNavbar/MainNavbar'
import MainSidebar from '../components/layout/MainSidebar/MainSidebar'
import MainFooter from '../components/layout/MainFooter'
import TopNav from '../components/layout/MainNavbar/TopNav/TopNav'

const MainLayout = ({ children, noNavbar, noFooter }) => (
  <Container fluid style={{ padding: 0 }}>
    {<TopNav />}

    {children}
  </Container>
)

MainLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
}

MainLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
}

export default MainLayout
