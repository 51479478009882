import React from 'react'
import { Container, Button } from 'shards-react'

import { Link } from 'react-router-dom'

const Errors = () => (
  <Container fluid className='main-content-container px-4 pb-4'>
    <div className='error'>
      <div className='error__content'>
        <h2>Error</h2>
        <h3>Something went wrong</h3>
        <p>
          The server could not connect. If you have an active connection it is
          likely a problem on our end. Please try again later.
        </p>
        <Link to={{ pathname: '/routines/' }}>
          <Button theme='success' pill>
            &larr; Go Back
          </Button>
        </Link>
      </div>
    </div>
  </Container>
)

export default Errors
