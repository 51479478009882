import Highlighter from 'react-highlight-words'

import React from 'react'
import {
  FormCheckbox,
  Container,
  Row,
  Col,
  Button,
  FormTextarea,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  Alert
} from 'shards-react'
import Details from '@material-ui/icons/Details'
import ReactLoading from 'react-loading'
import 'rc-pagination/assets/index.css'
import { Store, Dispatcher, Constants } from '../flux'
import RoutinePagination from '../components/routine_library/RoutinePagination'
import withWindowDimensions from '../components/common/withWindowDimensions'
import AddIcon from '@material-ui/icons/Add'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

class ListRoutines extends React.Component {
  constructor(props) {
    super(props)
    this.handlePageChange = this.handlePageChange.bind(this)
    this._onChange = this._onChange.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    this.handlePageChange = this.handlePageChange.bind(this)
    this.getTableItems = this.getTableItems.bind(this)
    this.getUsedInApp = this.getUsedInApp.bind(this)
    this.handleUsedInAppChange = this.handleUsedInAppChange.bind(this)
    this.handleDeleteRoutine = this.handleDeleteRoutine.bind(this)
    this.getTable = this.getTable.bind(this)
    this.state.open = false
    this.state.routineHoverUUID = ''
    this.state.categoriesOpen = false
    this.state.clientDetailsOpen = false
    this.toggle = this.toggle.bind(this)
    this.toggleClientDetails = this.toggleClientDetails.bind(this)
    this.renderCategories = this.renderCategories.bind(this)
    this.getRoutineImageHover = this.getRoutineImageHover.bind(this)
  }

  getAppState() {
    return {
      usedInApp: Store.getUsedInApp(),
      routineLibrary: Store.getRoutines(),
      routineSearchTerm: Store.getRoutineSearchTerm(),
      routinePagination: Store.getRoutinePagination(),
      page: 1,
      routineLibraryWorking: Store.getRoutineLibraryWorking(),
      categories: Store.getCategories(),
      selectedCategory: Store.getSelectedCategory(),
      routineTotal: Store.getRoutineTotal(),
      myDetails: Store.getMyDetails(),
      pdfDownloadWorking: Store.getPDFDownloadWorking(),
      token: Store.getToken()
    }
  }
  componentDidMount() {
    Store.addChangeListener(this._onChange)
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.hardRefresh
    ) {
      Dispatcher.dispatch({
        actionType: Constants.SET_ROUTINE_SEARCH_RESULTS,
        payload: { results: [] }
      })
    }
    if (!this.state.token) {
      Store.setSelectClientToggle(true)
    } else {
      Dispatcher.dispatch({
        actionType: Constants.SET_MENU_INVISIBLE
      })
      Dispatcher.dispatch({
        actionType: Constants.GET_CATEGORIES
      })
      Dispatcher.dispatch({
        actionType: Constants.SEARCH_ROUTINES,
        payload: {
          routine: this.state.routineSearchTerm,
          pageSize: 1000,
          page: 1
        }
      })
    }
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  handlePageChange(page) {
    this.setState({
      page: page
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: '', page: page, pageSize: 1000 }
    })
  }

  getRoutineCount() {
    if ('count' in this.state.routinePagination) {
      return this.state.routinePagination.count
    }
    return 0
  }

  onRoutineChange(e) {
    // console.log(e.target.value);
  }

  toggle() {
    this.setState({
      open: !this.state.open
    })
  }

  toggleClientDetails() {
    this.setState({
      clientDetailsOpen: !this.state.clientDetailsOpen
    })
  }

  navigateToRoutineDetail(routine) {
    Dispatcher.dispatch({
      actionType: Constants.SET_SELECTED_ROUTINE,
      payload: routine
    })
    Store.setRoutineEditMode(false)
    this.props.history.push('/editor/')
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  forceLogin() {
    if (localStorage.getItem('prevToken') == null) {
      console.log('Kicked out')
      this.props.history.push('/landing/')
    }
  }

  getRoutineImage(routine) {
    if (routine.exercises.length > 0) {
      // console.log(routine.exercises[0].image);
      return routine.exercises[0].image
    } else {
      return null
    }
  }

  getUsedInApp(routine) {
    if (this.state.usedInApp[routine.uuid]) {
      return true
    }
    return false
  }

  handleUsedInAppChange(routine) {
    const usedInAppNew = this.state.usedInApp
    usedInAppNew[routine.uuid] = !usedInAppNew[routine.uuid]
    // console.log(usedInAppNew[routine.uuid]);
    this.setState({
      usedInApp: usedInAppNew
    })
    if (usedInAppNew[routine.uuid]) {
      this.handleAddRoutine(routine.uuid)
    } else {
      this.handleDeleteRoutine(routine)
    }
  }

  handleAddRoutine(uuid) {
    Dispatcher.dispatch({
      actionType: Constants.ASSIGN_ROUTINE,
      payload: { routine_uuid: uuid }
    })
  }

  handleDeleteRoutine(routine) {
    Dispatcher.dispatch({
      actionType: Constants.DELETE_ROUTINE,
      payload: {
        selectedRoutine: routine
      }
    })
  }

  renderGoBackToSetup() {
    return (
      <Button
        theme='light'
        onClick={() => {
          this.props.history.push('/setup')
        }}
      >
        {' '}
        <i className='material-icons'>arrow_back</i> Go back to setup
      </Button>
    )
  }

  getRoutineImageHover(routine) {
    return (
      <table
        style={{
          margin: 0,
          borderCollapse: 'separate',
          borderSpacing: '0 15px',
          width: '100%',
          paddingTop: '5px'
        }}
      >
        {routine.exercises.map(value => (
          <tr>
            <td
              style={{
                verticalAlign: 'top',
                borderBottom: '1px solid #ddd',
                paddingRight: '30px'
              }}
            >
              <img
                src={value.image}
                style={{ width: '50px', height: 'auto' }}
              />{' '}
            </td>
            <td
              style={{
                color: '#191919',
                verticalAlign: 'top',
                borderBottom: '1px solid #ddd',
                width: '100%'
              }}
            >
              <div
                style={{
                  fontSize: '5px',
                  color: 'lightseagreen',
                  fontWeight: 'bold',
                  padding: '0px'
                }}
              >
                {value.name}
              </div>
              <div
                style={{
                  fontSize: '5px',
//                  backgroundColor: 'whitesmoke',
                  color: 'grey'
                }}
              >
                {' '}
                {value.length} {value.type_name}{' '}
              </div>
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  fontSize: '5px',
                  color: '#606264',
                  paddingTop: '2px'
                }}
              >
                {' '}
                {value.description}{' '}
              </div>{' '}
            </td>
          </tr>
        ))}
      </table>
    )
    // }
  }

  getTableItems() {
    if (!this.state.routineLibrary) {
      return []
    }
    const boldHighlight = ({ children, highlightIndex }) => (
      <strong style={{ fontWeight: 'bold' }}>{children}</strong>
    )

    return this.state.routineLibrary.map(routine => (
      <figure
        // onMouseExit={() => this.setState({ routineHoverUUID: "XXX" })}
        // onMouseEnter={() => this.setState({ routineHoverUUID: routine.uuid })}
        class='grid_item grid_item-2'
        onClick={() => this.navigateToRoutineDetail(routine)}
        style={{
          border: this.getUsedInApp(routine) ? '2px solid lightseagreen' : ''
        }}
      >
        {/* <div class="sent-tag">
          {" "}
          <span> {this.getUsedInApp(routine) ? "" : ""}</span>
        </div> */}
        <div class='text'>
          <span>
            {' '}
            {routine.name.length > 20
              ? routine.name.substring(0, 20) + '..'
              : routine.name}{' '}
          </span>
          <span style={{ float: 'right' }}>
            {/* <label class="switch">
              <input
                onClick={() => {
                  this.handleUsedInAppChange(routine);
                }}
                checked={this.getUsedInApp(routine)}
                type="checkbox"
              ></input>
              <span class="slider round"></span>
            </label> */}

            <span
              style={{ zIndex: 99 }}
              class='round'
              onClick={() => {
                this.handleUsedInAppChange(routine)
              }}
            >
              <input checked={this.getUsedInApp(routine)} type='checkbox' />
              <label for='checkbox' />
            </span>
          </span>
        </div>

        {/* <div class="text-details"> */}
        {/* Contains {routine.exercises.length} Exercise(s) <br></br> */}
        {/* <button class="online_view">View Site</button> */}
        {/* </div> */}
        {/*        {routine.exercises.map(

            exercise => (
              <img
              onClick={() => this.navigateToRoutineDetail(routine)}
              class="grid__exercise_image"
              src={exercise.image}
              alt=""
            />
            )
        ) */}
        {this.getRoutineImageHover(routine)}
      </figure>
    ))
  }

  getRoutineLibraryLoading() {
    return (
      <div
        style={{
          textAlign: 'center',
          // borderTop: "1px solid #d9d9d9",
          paddingTop: 50
        }}
      >
        <div style={{ paddingTop: 10 }} className='error__content'>
          <ReactLoading
            className='searchLoading'
            type='bubbles'
            color='lightseagreen'
            width={100}
            height={100}
          />
        </div>
      </div>
    )
  }

  getTable() {
    if (!this.state.routineLibrary) {
      return (
        <Row
          style={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px solid #d9d9d9'
          }}
        >
          {' '}
          {this.getRoutineLibraryLoading()}{' '}
        </Row>
      )
    }
    if (
      this.state.routineLibraryWorking &&
      this.state.routineLibrary.length == 0
    ) {
      return (
        <Row
          style={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center'
            // borderTop: "1px solid #d9d9d9"
          }}
        >
          {' '}
          {this.getRoutineLibraryLoading()}{' '}
        </Row>
      )
    }
    if (this.state.routineLibrary.length == 0) {
      return (
        <div style={{ textAlign: 'center', paddingTop: 50 }}>
          {/* <h2>No Results</h2> */}
          {/* <h3>{Store.getRoutineSearchTerm()}</h3> */}
          <p>We could not find any routines matching your search</p>
        </div>
      )
    }
    // return this.getRoutineLibraryLoading()

    // boxShadow: "2px 18px 10px -11px rgba(153,153,153,0.75)",
    // webkitBoxShadow: "2px 18px 10px -11px rgba(153,153,153,0.75)",
    // mozBoxShadow: "2px 18px 10px -11px rgba(153,153,153,0.75)"

    return <div class='grid-container'>{this.getTableItems()}</div>
  }

  handleCategorySearch(category) {
    Store.setSelectedCategory(category)
    Dispatcher.dispatch({
      actionType: Constants.SET_ROUTINE_SEARCH_RESULTS,
      payload: { results: [] }
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_ROUTINES,
      payload: { routine: '', page: 1, category: category }
    })
  }

  ensureNoMappingError(myDetails) {
    if (myDetails['completed_details'] == undefined) {
      myDetails['completed_details'] = []
    }
    if (myDetails['setup_progress'] == undefined) {
      myDetails['setup_progress'] = {
        goal_setup: false,
        routines_setup: false,
        routine_completed: false
      }
    }
    if (myDetails['routines'] == undefined) {
      myDetails['routines'] = []
    }
    return myDetails
  }

  changeVisibility(category) {
    // if (category.number_of_routines) {
    return (
      <span style={{ padding: 5 }}>
        <span
          class='dot'
          style={{ marginRight: 10, backgroundColor: category.colour }}
        />
        <span style={{ color: 'black' }}> {category.name} </span>
        <span
          style={{
            fontSize: 10,
            color: 'black',
            marginTop: 5,
            marginRight: 10,
            float: 'right'
          }}
        >
          {category.number_of_routines}
        </span>
      </span>
    )
    // }
    return ''
  }

  getHints() {
    if (!this.props.isMobileSized) {
      return (
        <div style={{ paddingBottom: 20 }}>
          <h5>James Client</h5>
          <hr />
          <span
            style={{
              fontSize: 14,
              color: '#343233'
            }}
          >
            Completed 0/10 Routines
            <br />
            <br />
            Goal statement is unset delivery.
            <br />
            <span style={{ color: 'green' }}>edit</span>
          </span>
        </div>
      )
    }
  }

  renderCategories(categories) {
    if (this.state.categoriesOpen || !this.props.isMobileSized) {
      return (
        <ul
          style={{
            paddingLeft: 0,
            textAlign: 'left',
            listStyleType: 'none',
            cursor: 'pointer',
            color: '#9e9e9e'
          }}
        >
          <li
            class='category-highlight'
            style={{
              // color: "#058C42",
              color: this.state.selectedCategory == null ? 'black' : 'black',
              width: '90%',
              backgroundColor:
                this.state.selectedCategory == null ? '#ececec' : '',
              fontSize: 22,
              borderRadius: 5
            }}
            onClick={() => this.handleCategorySearch(null)}
          >
            <span style={{ padding: 5 }}>
              <span
                class='dot'
                style={{ marginRight: 10, backgroundColor: 'pink' }}
              />
              <span>All </span>

              <span
                style={{
                  fontSize: 12,
                  color: 'black',
                  marginTop: 5,
                  marginRight: 10,
                  float: 'right'
                }}
              >
                {this.state.routineTotal}
              </span>
            </span>
          </li>
          {categories.map(category => (
            <li
              class='category-highlight'
              style={{
                color:
                  this.state.selectedCategory == category.name
                    ? 'black'
                    : '#636970',
                backgroundColor:
                  this.state.selectedCategory == category.name ? '#ececec' : '',
                width: '90%',
                fontSize: 20,
                borderRadius: 5,
                // color: "#058C42",
                border: '1px solid black'
              }}
              onClick={() => this.handleCategorySearch(category.name)}
            >
              {this.changeVisibility(category)}
            </li>
          ))}
        </ul>
      )
    }
  }

  render() {
    this.check500()
    this.forceLogin()
    let { myDetails, open } = this.state
    myDetails = this.ensureNoMappingError(this.state.myDetails)
    let categories = this.state.categories ? this.state.categories : []

    return (
      <div>
        <div
          // className="primary-colour"
          style={{
            // backgroundColor: "#79aec8",
            // padding: 1,

            backgroundColor: 'white',
            // padding: 15,
            display: 'flex',
            width: '100%',
            justifyContent: 'center'

            // borderTop: "1px solid #e8e9eb"
            // borderBottom: "1px solid #e8e9eb",
            // backgroundColor: "whitesmoke"
          }}
        >
          {/* <Button
              onClick={() => {
                this.props.history.push("/create-exercise/", {
                  newRoutine:
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.newRoutine
                      ? true
                      : false
                });
              }}
              style={{ fontSize: 14, margin: 0, marginTop: 0, border: "1px" }}
              theme="success"
              size="md"
            >
              Create Exercise
            </Button> */}
        </div>

        <div>
          {/* <Alert>Hint. To select a routine, click the circular toggle on</Alert> */}

          {this.getTable()}
          {/* <RoutinePagination /> */}
        </div>
      </div>
    )
  }
}

export default withWindowDimensions(ListRoutines)
