import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Store } from '../../../flux'

class MainSidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuVisible: false
    }

    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange)
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange)
  }

  onChange() {
    this.setState({
      ...this.state
    })
  }

  render() {
    const classes = classNames(
      'main-sidebar',
      'px-0',
      'col-12',
      this.state.menuVisible && 'open'
    )

    return <div />
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
}

MainSidebar.defaultProps = {
  hideLogoText: false
}

export default MainSidebar
