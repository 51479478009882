export default {
  // Internal
  CHANGE: 'CHANGE',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  SET_MENU_INVISIBLE: 'SET_MENU_INVISIBLE',

  // Authentication
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  SET_CURRENT_TOKEN: 'SET_CURRENT_TOKEN',
  SET_PREV_TOKEN: 'SET_PREV_TOKEN',
  LOGIN_ERRORS: 'LOGIN_ERRORS',
  REGISTER_ERRORS: 'REGISTER_ERRORS',
  TOGGLE_REGISTER_WORKING: 'TOGGLE_REGISTER_WORKING',
  TOGGLE_LOGIN_WORKING: 'TOGGLE_LOGIN_WORKING',

  // My Recovery
  SET_CLIENT_NAME: 'SET_CLIENT_NAME',
  GET_MY_DETAILS: 'GET_MY_DETAILS',
  SET_MY_DETAILS: 'SET_MY_DETAILS',
  PATCH_MY_DETAILS: 'PATCH_MY_DETAILS',
  ASSIGN_ROUTINE: 'ASSIGN_ROUTINE',
  DOWNLOAD_PDFS: 'DOWNLOAD_PDFS',

  // ??
  GET_MY_ROUTINES: 'GET_MY_ROUTINES',
  SET_MY_ROUTINES: 'SET_MY_ROUTINES',

  // Filters
  GET_CATEGORIES: 'GET_CATEGORIES',
  SET_CATEGORIES: 'SET_CATEGORIES',

  // Exercise Library
  SEARCH_EXERCISES: 'SEARCH_EXERCISES',
  SET_EXERCISE_SEARCH_RESULTS: 'SET_EXERCISE_SEARCH_RESULTS',
  TOGGLE_EXERCISE_DETAIL: 'TOGGLE_EXERCISE_DETAIL',
  SET_SELECTED_EXERCISE: 'SET_SELECTED_EXERCISE',
  PATCH_EXERCISE: 'PATCH_EXERCISE',

  // Routine Library
  SEARCH_ROUTINES: 'SEARCH_ROUTINES',
  SET_ROUTINE_SEARCH_RESULTS: 'SET_ROUTINE_SEARCH_RESULTS',
  SET_SELECTED_ROUTINE: 'SET_SELECTED_ROUTINE',
  PATCH_ROUTINE: 'PATCH_ROUTINE',
  PATCH_ROUTINE_EXERCISES: 'PATCH_ROUTINE_EXERCISES',
  PATCH_ROUTINE_NAME: 'PATCH_ROUTINE_NAME',
  DELETE_ROUTINE: 'DELETE_ROUTINE',
  ENSURE_CLIENT_COPY: 'ENSURE_CLIENT_COPY',
  GET_ROUTINE_DETAILS: 'GET_ROUTINE_DETAILS',

  // TOTALS
  FETCH_TOTALS: 'FETCH_TOTALS',
  SET_TOTALS: 'SET_TOTALS',

  // Create Exercise
  POST_EXERCISE: 'POST_EXERCISE',

  // Create Routine
  POST_ROUTINE: 'POST_ROUTINE',
  SET_CURRENT_CLIENT: 'SET_CURRENT_CLIENT',

  // Coach Others
  REGISTER_STUDENT: 'REGISTER_STUDENT',
  SET_STUDENT_LIST: 'STUDENT_LIST',
  GET_STUDENT_LIST: 'GET_STUDENT_LIST',

  // Loading
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  // Errors
  TOGGLE_API_500: 'TOGGLE_API_500',

  PREDEFINED_ROUTINE_AUTHOR: 'general'
}
