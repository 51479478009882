import React, { Component } from 'react'
import { Alert } from 'shards-react'

import { Store, Dispatcher, Constants } from '../flux'
import PropTypes from 'prop-types'
import CurrentExercises from '../components/routine_library/CurrentExercises'

class UpdateRoutine extends Component {
  constructor(props) {
    super(props)
    this._onChange = this._onChange.bind(this)
    this.renderAddingAlert = this.renderAddingAlert.bind(this)
    this.handleAddExercise = this.handleAddExercise.bind(this)
    this.getAppState = this.getAppState.bind(this)
    this.state = this.getAppState()
    this.handleNameChange = this.handleNameChange.bind(this)
    this.updateRoutine = this.updateRoutine.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.renderRoutineButton = this.renderRoutineButton.bind(this)
    this.handleDoubleClick = this.handleDoubleClick.bind(this)
    this.toggleEditMode = this.toggleEditMode.bind(this)
  }
  getAppState() {
    return {
      exercises: Store.getExerciseLibrary(),
      name: '',
      selectedRoutine: Store.getSelectedRoutine(),
      name:
        'name' in Store.getSelectedRoutine()
          ? Store.getSelectedRoutine().name
          : '',
      description:
        'description' in Store.getSelectedRoutine()
          ? Store.getSelectedRoutine().description
          : '',
      exerciseCategories: Store.getCategories(),
      shouldDisplayAddingAlert: false,
      addExerciseWorking: Store.getAddingExerciseWorking(),
      selectRoutineWorking: Store.getSelectRoutineWorking(),
      selectButtonText: this.renderRoutineButton(),
      routineCopyWorking: Store.getRoutineCopyWorking(),
      routineEditMode: Store.getRoutineEditMode()
    }
  }
  componentDidMount() {
    Store.addChangeListener(this._onChange)
    Dispatcher.dispatch({
      actionType: Constants.SET_MENU_INVISIBLE
    })

    Dispatcher.dispatch({
      actionType: Constants.GET_ROUTINE_DETAILS,
      payload: {
        uuid: this.state.selectedRoutine.uuid
      }
    })

    Store.setExerciseSearchTerm('')
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  renderRoutineButton() {
    // not working
    if (
      this.state &&
      this.state.selectRoutine &&
      this.state.selectedRoutine.used_in_app_by
    ) {
      return 'Unselect'
    } else if (this.state && this.state.selectRoutineWorking) {
      return 'Working'
    } else {
      return 'Select'
    }
  }

  handleDoubleClick(exercise) {
    if (this.state.routineEditMode) {
      Dispatcher.dispatch({
        actionType: Constants.SET_SELECTED_EXERCISE,
        payload: exercise
      })
      this.props.history.push('/exercise-details/', { goBackToRoutine: true })
    }
  }

  handleAddExercise(event, values) {
    if (!values) {
      return
    }
    if ('uuid' in values == false) {
      return
    }
    this.setState(
      {
        exerciseToAdd: values
      },
      () => {
        Dispatcher.dispatch({
          actionType: Constants.PATCH_ROUTINE,
          payload: {
            routineUpdates: { exercise: values.uuid },
            selectedRoutine: this.state.selectedRoutine
          }
        })
      }
    )
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value
    })
  }

  handleDescriptionChange(e) {
    this.setState({
      description: e.target.value
    })
    console.log(this.state.description)
  }

  updateRoutine(e) {
    const routineUpdates = this.state.selectedRoutine
    routineUpdates.name = this.state.name
    routineUpdates.description = this.state.description

    Dispatcher.dispatch({
      actionType: Constants.PATCH_ROUTINE_NAME,
      payload: {
        routineUpdates: {
          name: this.state.name,
          description: this.state.description
        },
        selectedRoutine: this.state.selectedRoutine
      }
    })
  }

  getEventHandlers() {
    return {
      addExercise: (e, values) => this.handleAddExercise(e, values),
      addRoutine: (e, values) => this.handleAddExercise(e, values),
      handleNameChange: e => this.handleNameChange(e),
      handleDescriptionChange: e => this.handleDescriptionChange(e),
      createOrUpdate: e => this.updateRoutine(e),
      deleteRoutine: e => this.deleteRoutine(e),
      doubleClick: e => this.handleDoubleClick(e)
    }
  }

  handleUsedInAppChange(routine) {
    if (!routine.used_in_app_by) {
      this.handleAddRoutine(routine.uuid)
    } else {
      this.deleteRoutine(routine)
    }
  }

  deleteRoutine(routine) {
    Dispatcher.dispatch({
      actionType: Constants.DELETE_ROUTINE,
      payload: {
        selectedRoutine: routine,
        history: this.props.history
      }
    })
  }

  handleAddRoutine(uuid) {
    Dispatcher.dispatch({
      actionType: Constants.ASSIGN_ROUTINE,
      payload: { routine_uuid: uuid, history: this.props.history }
    })
  }

  renderAddingAlert() {
    if (this.state.shouldDisplayAddingAlert) {
      return (
        <Alert className='mb-0'>
          <i className='fa fa-info mx-2' />
          Adding ...{' '}
        </Alert>
      )
    }
  }

  getRoutineImage(routine) {
    if (routine.exercises.length > 0) {
      return routine.exercises[0].image
    } else {
      return null
    }
  }

  toggleEditMode(routine) {
    if (this.state.routineEditMode) {
      Store.setRoutineEditMode(false)
      return
    }
    Dispatcher.dispatch({
      actionType: Constants.ENSURE_CLIENT_COPY,
      payload: { uuid: routine.uuid, history: this.props.history }
    })
  }

  renderExercises(selectedRoutine, eventHandlers) {
    return (
      // <ScrollableFeed >
      <div
        style={{
          backgroundColor: '#f0f2f5',
          // borderTop: "1px solid #d9d9d9",
          padding: 15,
          textAlign: 'center'
        }}
      >
        <CurrentExercises
          routine={selectedRoutine}
          eventHandlers={eventHandlers}
          routineEditMode={this.state.routineEditMode}
          editExercise={this.handleDoubleClick}
        />
      </div>
    )
  }

  render() {
    const { selectedRoutine, exercises, addExerciseWorking } = this.state
    const eventHandlers = this.getEventHandlers()
    let addExerciseButton
    if (this.state.routineEditMode) {
      addExerciseButton = (
        <span
          onClick={() => {
            this.props.history.push('/exercises/')
          }}
          style={{
            width: '20%',
            backgroundColor: 'white',
            color: 'black',
            // margin: 5,
            display: 'inline',
            fontSize: '12px',
            textAlign: 'center',
            padding: 8,
            // borderRadius: 5,
            cursor: 'pointer',
            borderLeft: '1px solid #d5d5d5',
            borderTop: '1px solid #d5d5d5'
          }}
        >
          Add Exercise
        </span>
      )
    }
    return (
      // <Row>

      //     <div
      //       style={{
      //         padding: 15,
      //         textAlign: "center",
      //         // borderTop: "1px solid #d9d9d9"
      //         backgroundColor: "#20B2AA"
      //       }}
      //     >
      //       <Row style={{ textAlign: "center" }}>
      //         <Col style={{ textAlign: "left" }} sm="12" md="4" lg="3">
      //           <ArrowBackIosIcon
      //             style={{ cursor: "pointer" }}
      // onClick={() => {
      //   if (
      //     this.props.location &&
      //     this.props.location.state &&
      //     this.props.location.state.goBackToSetup
      //   ) {
      //     this.props.history.push("/setup/");
      //   } else {
      //     this.props.history.push("/routines");
      //   }
      //             }}
      //           />
      //         </Col>
      //         <Col sm="12" md="4" lg="6">
      //           <Button
      //   onClick={() => {
      //     this.toggleEditMode(selectedRoutine);
      //   }}
      //   theme="light"
      //   size="md"
      //   style={{
      //     width: "30%",
      //     marginRight: 5,
      //     fontSize: 14,
      //     border: "1px lightgrey solid"
      //   }}
      // >
      //   {this.state.routineCopyWorking
      //     ? "Opening Editor"
      //     : this.state.routineEditMode
      //     ? "Close Editor"
      //     : "Edit Routine"}
      //           </Button>

      //           {addExerciseButton}
      //           <Button
      //             size="md"
      //             style={{
      //               fontSize: 14,
      //               width: "30%"
      //             }}
      //   onClick={() => this.handleAddRoutine(selectedRoutine.uuid)}
      //   theme="success"
      // >
      //   {this.state.selectRoutineWorking ? "Working" : "Select"}
      //           </Button>
      //         </Col>
      //         <Col sm="12" md="4" lg="3"></Col>
      //       </Row>
      //     </div>

      //   <Col lg="12">
      //     {this.renderExercises(selectedRoutine, eventHandlers)}
      //   </Col>
      // </Row>

      <div>
        <div
          style={{
            padding: 0,
            textAlign: 'center',
            // backgroundColor: "#1dc089",
            justifyContent: 'center',
            // paddingTop: 20,
            alignItems: 'center',
            width: '100%',
            display: 'flex'

            // border: "1px solid #d9d9d9"
            // borderTop: "1px solid #d9d9d9"
          }}
        >
          {/* <div style={{ width: "30%" }}>
            <span
              onClick={() => {
                this.props.history.push("/routines/");
              }}
              style={{ float: "left", color: "white", cursor: "pointer" }}
            >
              <ArrowBackIosIcon
                style={{
                  marginBottom: 2,
                  fontSize: "16px",
                  cursor: "pointer",
                  color: "white"
                }}
              />
              back to routines
            </span>
          </div> */}

          <span
            style={{
              width: '20%',
              backgroundColor: 'white',
              color: 'black',
              // marginRight: 5,
              display: 'inline',
              fontSize: '12px',
              textAlign: 'center',
              padding: 8,
              // borderRadius: 5,
              cursor: 'pointer',
              borderTop: '1px solid #d5d5d5'
              // border: "1px solid #d5d5d5"
            }}
            onClick={() => {
              if (
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.goBackToSetup
              ) {
                this.props.history.push('/setup/')
              } else {
                this.props.history.push('/routines')
              }
            }}
          >
            Exit Routine
          </span>

          <span
            style={{
              width: '20%',
              backgroundColor: 'white',
              color: 'black',
              // margin: 5,
              display: 'inline',
              fontSize: '12px',
              textAlign: 'center',
              padding: 8,
              // borderRadius: 5,
              cursor: 'pointer',
              borderLeft: '1px solid lightgrey',
              borderTop: '1px solid #d5d5d5'
            }}
            onClick={() => {
              this.toggleEditMode(selectedRoutine)
            }}
          >
            {this.state.routineCopyWorking
              ? 'Opening Editor'
              : this.state.routineEditMode
              ? 'Close Editor'
              : 'Edit Routine'}
          </span>
          {addExerciseButton}
          <span
            style={{
              width: '20%',
              backgroundColor: 'white',
              color: 'black',
              marginRight: 30,
              display: 'inline',
              fontSize: '12px',
              textAlign: 'center',
              padding: 8,
              // borderRadius: 5,
              cursor: 'pointer',
              borderLeft: '1px solid lightgrey',
              borderTop: '1px solid #d5d5d5'
            }}
            onClick={() => this.handleAddRoutine(selectedRoutine.uuid)}
            theme='success'
          >
            {this.state.selectRoutineWorking ? 'Working' : 'Select Routine'}
          </span>
        </div>
        <div style={{ paddingLeft: 40, backgroundColor: '#f0f2f5' }}>
          {this.renderExercises(selectedRoutine, eventHandlers)}
        </div>
      </div>
    )
  }
}

UpdateRoutine.propTypes = {
  /**
   * The component's title.
   */
  selectedRoutine: PropTypes.object
}

export default UpdateRoutine
