import React from 'react'
import { Redirect } from 'react-router-dom'

// Layout Types
import {
  LandingLayout,
  DefaultLayout,
  MainLayout,
  EmptyLayout
} from './layouts'
// Route Views
import UpdateRoutine from './views/UpdateRoutine'
import UpdateExercise from './views/UpdateExercise'
import NewExercise from './views/NewExercise'
import Errors from './views/Errors'
import ExerciseLibrary from './views/ExerciseLibrary'
import RoutineLibrary from './views/RoutineLibrary'
import NewRoutine from './views/NewRoutine'
import NewRoutineExercises from './views/NewRoutineExercises'
import MyCurrentRoutines from './views/MyRecovery'
import Logout from './views/Logout'
import Disclaimer from './views/Disclaimer'
import Landing from './views/Landing'
import Setup from './views/Setup'
import MyClients from './views/MyClients'
import NewClient from './views/NewClient'
import DoRoutine from './views/DoRoutine'
import NotFoundPage from './views/NotFoundPage'
import Privacy from './views/Privacy'
import ClientDetails from './views/ClientDetails'
import Editor from './views/RoutineEditor'
import UpdateExerciseNew from './views/UpdateExerciseNew'
import CreateRoutine from './views/CreateRoutine'
import PDFGenerator from './views/PDFGenerator'

export default [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to='/landing' />
  },
  {
    path: '/my-recovery',
    layout: MainLayout,
    component: MyCurrentRoutines
  },
  {
    path: '/exercises',
    layout: MainLayout,
    component: ExerciseLibrary
  },
  {
    path: '/routines',
    layout: MainLayout,
    component: RoutineLibrary
  },
  {
    path: '/create-exercise',
    layout: MainLayout,
    component: NewExercise
  },
  {
    path: '/exercise-details',
    layout: MainLayout,
    component: UpdateExercise
  },
  {
    path: '/routine-details',
    layout: MainLayout,
    component: UpdateRoutine
  },
  {
    path: '/routine/:client/:slug',
    layout: LandingLayout,
    component: DoRoutine
  },
  {
    path: '/create-routine',
    layout: MainLayout,
    component: NewRoutine
  },
  {
    path: '/create-routine-exercises',
    layout: MainLayout,
    component: NewRoutineExercises
  },
  {
    path: '/clients',
    layout: LandingLayout,
    component: MyClients
  },
  {
    path: '/new-client',
    layout: LandingLayout,
    component: NewClient
  },
  {
    path: '/logout',
    layout: MainLayout,
    component: Logout
  },
  {
    path: '/errors',
    layout: MainLayout,
    component: Errors
  },
  {
    path: '/disclaimer',
    layout: LandingLayout,
    component: Disclaimer
  },
  {
    path: '/landing',
    layout: LandingLayout,
    component: Landing
  },
  {
    path: '/client-details',
    layout: MainLayout,
    component: ClientDetails
  },
  {
    path: '/privacy',
    layout: LandingLayout,
    component: Privacy
  },
  {
    path: '/editor',
    layout: LandingLayout,
    component: Editor
  },
  {
    path: '/new',
    layout: LandingLayout,
    component: CreateRoutine
  },
  {
    path: '/edit-exercise',
    layout: LandingLayout,
    component: UpdateExerciseNew
  },
  {
    path: '/pdf',
    layout: LandingLayout,
    component: PDFGenerator
  }
]
