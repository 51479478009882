import React, { Component } from 'react'

import { Alert, Row, Col, Form, FormInput, Button } from 'shards-react'

import { Store, Dispatcher, Constants } from '../flux'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      password1: '',
      password2: '',
      token: '',
      registerText: 'Register',
      loginText: 'Login',
      loginErrors: '',
      registerErrors: '',
      createClientWorking: false,
      clientRegisterErrors: []
    }
    this._onChange = this._onChange.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onRegister = this.onRegister.bind(this)
    this.onLogin = this.onLogin.bind(this)
    this.getAlert = this.getAlert.bind(this)
    this.check500 = this.check500.bind(this)
  }

  componentDidMount() {
    Dispatcher.dispatch({
      actionType: Constants.REGISTER_ERRORS,
      payload: ''
    })
    Dispatcher.dispatch({
      actionType: Constants.LOGIN_ERRORS,
      payload: ''
    })
    Store.addChangeListener(this._onChange)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }

  onEmailChange(e) {
    this.setState({
      email: e.target.value
    })
  }

  onPasswordChange(e) {
    this.setState({
      password: e.target.value
    })
  }

  onLogin(e) {
    Dispatcher.dispatch({ actionType: Constants.TOGGLE_LOGIN_WORKING })
    Dispatcher.dispatch({
      actionType: Constants.LOGIN,
      payload: { username: this.state.email, password: this.state.password }
    })
  }

  signInSuccess() {
    this.props.history.push('/setup/')
  }

  _onChange() {
    this.setState({
      createClientWorking: Store.getCreateClientWorking(),
      clientRegisterErrors: Store.getClientRegisterErrors()
    })
  }

  getAlert() {
    const { clientRegisterErrors } = this.state
    if (clientRegisterErrors.length > 0) {
      let errors = ''
      for (let i = 0; i < clientRegisterErrors.length; i++) {
        errors += '\n ' + clientRegisterErrors[i]
      }

      return (
        <Alert className='mb-0' theme='warning'>
          {' '}
          <i className='fa fa-info mx-2' /> {errors}
        </Alert>
      )
    }
  }

  check500() {
    if (Store.getAPI500() == true) {
      this.props.history.push('/errors/')
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_API_500
        })
      }, 1)
    }
  }

  onRegister(e) {
    e.preventDefault()
    Dispatcher.dispatch({
      actionType: Constants.REGISTER_ERRORS,
      payload: ''
    })
    Dispatcher.dispatch({ actionType: Constants.TOGGLE_REGISTER_WORKING })
    Dispatcher.dispatch({
      actionType: Constants.REGISTER_STUDENT,
      payload: {
        email: this.state.email,
        username: this.state.username,
        password1: this.state.password1,
        password2: this.state.password2,
        history: this.props.history
      }
    })
  }

  render() {
    const alert = this.getAlert()
    return (
      <div class='auth-main-container'>
        <div style={{ paddingLeft: 15 }}>{this.getAlert()}</div>

        <div class='auth-logo'>
          <span style={{ fontSize: 32, fontFamily: 'Montserrat, sans-serif' }}>
            Recover <span> </span>
            <span
              style={{
                fontSize: 28,
                borderRadius: 8,
                padding: 8,
                backgroundColor: '#1abb9a',
                color: 'white',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: 10
              }}
            >
              Hub
            </span>
          </span>

          <span style={{ marginBottom: 20, marginTop: 20 }}>New Client</span>
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div style={{ width: 300 }}>
            <form class='auth-form' onSubmit={e => this.onRegister(e)}>
              <input
                class='auth-form-control'
                type='text'
                name='username'
                id='username'
                placeholder='Username'
                required
                onChange={e => {
                  this.setState({ username: e.target.value })
                }}
              />

              <input
                class='auth-form-control'
                type='password'
                name='password'
                id='password'
                placeholder='Password'
                required
                onChange={e => {
                  this.setState({ password1: e.target.value })
                }}
              />

              <input
                class='auth-form-control'
                type='password'
                name='passwordRepeat'
                id='passwordRepeat'
                placeholder='Password (again)'
                required
                onChange={e => {
                  this.setState({ password2: e.target.value })
                }}
              />

              <Button
                theme='success'
                type='submit'
                style={{ fontSize: 16, margin: 5, width: '100%' }}
              >
                {this.state.createClientWorking ? 'Adding' : 'Add Client'}
              </Button>

              <Button
                theme='light'
                onClick={() => this.props.history.push('/clients/')}
                href='javascript:void(0)'
                style={{
                  fontSize: 16,
                  width: '100%',

                  border: '1px lightgrey solid'
                }}
              >
                Go Back
              </Button>
            </form>
          </div>
        </div>
        <div class='push' />

        <div class='bottom-links' />
      </div>
    )
  }
}

export default App
