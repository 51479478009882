import React from 'react'
import { Nav, NavItem, NavLink } from 'shards-react'

import Notifications from './Notifications'
import UserActions from './UserActions'

export default () => (
  <Nav navbar className='flex-row' style={{ paddingRight: 150 }}>
    <NavItem>
      <NavLink
        style={{
          fontSize: 12,
          fontWeight: 'bold',
          paddingTop: 25,
          paddingRight: 10
        }}
        active
        href='/my-recovery/'
      >
        PROGRESS
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        style={{
          fontSize: 12,
          fontWeight: 'bold',
          paddingTop: 25,
          paddingRight: 40
        }}
        active
        href='/logout/'
      >
        LOGOUT
      </NavLink>
    </NavItem>
  </Nav>
)
