import { Dispatcher, Constants, Store } from '../flux'
import store from '../flux/store'

const PRODUCTION = true
const VERSION = 1
console.log('PRODUCTION: ' + PRODUCTION)
console.log('VERSION: ' + VERSION)
const BASE_URL = 'https://miniatureweasle.pythonanywhere.com/'
class API {
  // Authentication

  login(username, password, history) {
    var url = '/api-token-auth/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    Store.setLoginWorking(true)
    const response = fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username: username, password: password })
    })
      .then(res => res.json())
      .then(data => {
        if ('non_field_errors' in data) {
          Dispatcher.dispatch({
            actionType: Constants.LOGIN_ERRORS,
            payload: data['non_field_errors']
          })
        }
        Store.setLoginWorking(false)
        if ('token' in data) {
          Dispatcher.dispatch({
            actionType: Constants.SET_PREV_TOKEN,
            payload: data
          })
          Dispatcher.dispatch({
            actionType: Constants.LOGIN_ERRORS,
            payload: ''
          })
          if (history && Store.getPersonalUse()) {
            store.setUsername(username)
            history.push('/routines/')
          } else {
            history.push('/routines/')
          }
        }
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  register(username, email, password1, password2, history) {
    var url = '/dashboard/register/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    console.log('USERNAME ' + username)
    console.log('PASSWORD' + password1)

    Store.setRegisterWorking(true)
    const response = fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        email: email,
        password1: password1,
        password2: password2
      })
    })
      .then(res => res.json())
      .then(data => {
        store.setRegisterWorking(false)
        if ('key' in data) {
          const token = { token: data.key }
          Dispatcher.dispatch({
            actionType: Constants.SET_PREV_TOKEN,
            payload: token
          })
          if ('client_key' in data) {
            const client_token = { token: data.client_key }
            Dispatcher.dispatch({
              actionType: Constants.SET_CURRENT_TOKEN,
              payload: client_token
            })
          }
          history.push('/routines/')
          Dispatcher.dispatch({
            actionType: Constants.REGISTER_ERRORS,
            payload: ''
          })
        } else {
          var errors = []
          for (var key in data) {
            errors.push(data[key])
          }
          Dispatcher.dispatch({
            actionType: Constants.REGISTER_ERRORS,
            payload: errors
          })
        }
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  // My Recovery

  getMyDetails() {
    var url = '/dashboard/client/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + Store.getToken()
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_MY_DETAILS,
          payload: data
        })
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  patchMyDetails(clientUpdates) {
    var url = '/client/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + Store.getToken()

    fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(clientUpdates)
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_MY_DETAILS,
          payload: data
        })
        return data
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  assignRoutine(routine_uuid, history = null) {
    var url = '/dashboard/client/assign-routine/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + Store.getToken()
    Store.setSelectRoutineWorking(true)
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify({ routine_uuid: routine_uuid })
    })
      .then(res => res.json())
      .then(data => {
        Store.setSelectRoutineWorking(false)
        Dispatcher.dispatch({
          actionType: Constants.SET_MY_DETAILS,
          payload: data
        })
        if (history) {
          history.push('/routines/')
        }
        return data
      })
      .catch(error => {
        console.log(error)
        Store.setSelectRoutineWorking(false)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  downloadRoutinePDFs(routine = null) {
    var url = '/dashboard/client/pdf/?token=' + Store.getToken()
    if (PRODUCTION == true) {
      url = BASE_URL + url
    } else {
      url = 'http://localhost:8000' + url
    }

    if (localStorage.getItem('prevToken')) {
      url += '&coach=' + localStorage.getItem('prevToken')
    }
    console.log(routine)
    if (routine) {
      url += '&routine=' + routine
    }

    console.log(url)

    Store.setPDFDownloadWorking(true)

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.blob()
      })
      .then(blob => {
        const href = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = 'test.pdf'
        a.click()
        Store.setPDFDownloadWorking(false)
      })
      .catch(error => {
        console.log(error)
        Store.setPDFDownloadWorking(false)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  // Exercise Library

  searchExercises(exercise, page, pageSize, category = null) {
    var url =
      '/dashboard/exercises/?search=' +
      exercise +
      '&page=' +
      page +
      '&page_size=' +
      pageSize

    if (category) {
      url += '&category=' + category
    }

    if (localStorage.getItem('prevToken')) {
      url += '&coach=' + localStorage.getItem('prevToken')
    }

    const token = 'Token ' + localStorage.getItem('token')

    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    Store.setExerciseLibraryWorking(true)
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Store.setExerciseLibraryWorking(false)
        Dispatcher.dispatch({
          actionType: Constants.SET_EXERCISE_SEARCH_RESULTS,
          payload: data
        })
      })
      .catch(error => {
        console.log(error)
        Store.setExerciseLibraryWorking(false)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  postExercise(newExercise, currentRoutine, newRoutine = false) {
    let formData = new FormData()
    for (var key in newExercise) {
      formData.append(key, newExercise[key])
    }
    let token = null
    if (localStorage.getItem('prevToken')) {
      token = 'Token ' + localStorage.getItem('prevToken')
    } else {
      token = 'Token ' + localStorage.getItem('token')
    }
    var url = '/dashboard/exercises/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    Store.setExerciseCreateWorking(true)
    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_SELECTED_EXERCISE,
          payload: data
        })
        if (!newRoutine) {
          Dispatcher.dispatch({
            actionType: Constants.PATCH_ROUTINE,
            payload: {
              routineUpdates: { exercise: data.uuid },
              selectedRoutine: currentRoutine
            }
          })
          Store.setExerciseCreateWorking(false)
          Store.closeExerciseModal()
        } else if (newRoutine) {
          let selectedRoutine = Store.getSelectedRoutine()
          data.order = selectedRoutine.exercises.length + 1
          selectedRoutine.exercises.push(data)
          Dispatcher.dispatch({
            actionType: Constants.SET_SELECTED_ROUTINE,
            payload: selectedRoutine
          })
          Store.setExerciseCreateWorking(false)
          Store.closeExerciseModal()
          // history.push("/create-routine-exercises/");
        }
      })
      .catch(error => {
        Store.setExerciseCreateWorking(false)
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  patchExercise(exerciseUpdates, selectedExercise, coach = false) {
    let formData = new FormData()
    for (var key in exerciseUpdates) {
      if (exerciseUpdates[key] != null) {
        formData.append(key, exerciseUpdates[key])
      }
    }
    let token = null
    if (coach) {
      token = 'Token ' + localStorage.getItem('prevToken')
    } else {
      token = 'Token ' + localStorage.getItem('token')
    }

    var url = '/dashboard/exercise/' + selectedExercise.uuid
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    Store.setUpdateExerciseWorking(true)
    Store.setUpdateExerciseComplete(false)
    fetch(url, {
      method: 'PATCH',
      body: formData,
      headers: {
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Store.setUpdateExerciseWorking(false)
        Store.setUpdateExerciseComplete(true)
        Dispatcher.dispatch({
          actionType: Constants.SET_SELECTED_EXERCISE,
          payload: data
        })
        let routine = Store.getSelectedRoutine()
        if (!routine.uuid) {
          for (let i = 0; i < routine.exercises.length; i++) {
            routine.exercises[i].order = i
            if (
              (routine.exercises[i].uuid == data.original) |
              (routine.exercises[i].uuid == data.uuid)
            ) {
              let deepCloneData = JSON.parse(JSON.stringify(data))
              deepCloneData.order = i
              routine.exercises[i] = deepCloneData
            }
          }
          Dispatcher.dispatch({
            actionType: Constants.SET_SELECTED_ROUTINE,
            payload: routine
          })
        }

        console.log(routine)
        return data
      })

      .catch(error => {
        Store.setUpdateExerciseWorking(false)
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  getCategories(coachToken = null) {
    var url = '/dashboard/exercise/categories/'

    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    if (coachToken) {
      url += '?coach=' + coachToken
    }

    const token = 'Token ' + localStorage.getItem('token')

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        // error
        if ('detail' in data) {
          Dispatcher.dispatch({
            actionType: Constants.SET_CATEGORIES,
            payload: []
          })
        } else {
          data.unshift({ name: 'All', uuid: null })
          Dispatcher.dispatch({
            actionType: Constants.SET_CATEGORIES,
            payload: data
          })
        }
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }
  // Routine Library

  searchRoutines(exercise, page, category = null, pageSize = 20) {
    var url =
      '/dashboard/routines/?search=' +
      exercise +
      '&page=' +
      page +
      '&page_size=' +
      pageSize

    if (category) {
      url += '&category=' + category
    }

    if (localStorage.getItem('prevToken')) {
      url += '&coach=' + localStorage.getItem('prevToken')
    }

    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    const token = 'Token ' + localStorage.getItem('token')
    Store.setRoutineLibraryWorking(true)
    fetch(url, {
      headers: { Authorization: token }
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_ROUTINE_SEARCH_RESULTS,
          payload: data
        })
        Store.setRoutineLibraryWorking(false)
      })
      .catch(error => {
        Store.setRoutineLibraryWorking(false)
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  AddExerciseToRoutine(routineUpdates, selectedRoutine) {
    var url = '/dashboard/routine/exercises/' + selectedRoutine.uuid
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    const token = 'Token ' + localStorage.getItem('token')
    Store.setAddingExerciseWorking(true)
    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(routineUpdates)
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_SELECTED_ROUTINE,
          payload: data
        })

        Store.setAddingExerciseWorking(false)
        Store.closeExerciseModal()
        return data
      })

      .catch(error => {
        Store.setAddingExerciseWorking(false)
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  patchRoutineExercises(routineExerciseUpdates, selectedRoutine) {
    var url = '/dashboard/routine/exercises/' + selectedRoutine.uuid
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    const token = 'Token ' + localStorage.getItem('token')

    fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(routineExerciseUpdates)
    })
      .then(res => res.json())
      .then(data => {
        // Dispatcher.dispatch({
        //   actionType: Constants.SET_SELECTED_ROUTINE,
        //   payload: data
        // });
        return data
      })

      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  ensureRoutineCopy(uuid, history) {
    var url = '/dashboard/routine/ensure-copy/' + uuid
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    const token = 'Token ' + localStorage.getItem('token')
    Store.setRoutineCopyWorking(true)
    fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_SELECTED_ROUTINE,
          payload: data
        })
        Store.setRoutineCopyWorking(false)
        Store.setRoutineEditMode(true)
        return data
      })
      .catch(error => {
        Store.setRoutineCopyWorking(false)
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  patchRoutineName(routineUpdates, selectedRoutine) {
    var url = '/dashboard/routine/details/' + selectedRoutine.uuid
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    const token = 'Token ' + localStorage.getItem('token')

    fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(routineUpdates)
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_SELECTED_ROUTINE,
          payload: data
        })
        return data
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  deleteRoutine(selectedRoutine, history = null) {
    var url = '/dashboard/routine/details/' + selectedRoutine.uuid
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + localStorage.getItem('token')
    fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: token
      }
    })
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.GET_MY_DETAILS,
          payload: {}
        })
        if (history) {
          history.push('/routines/')
        }
      })
      .catch(error => {
        console.log(error)
        console.log('Error in delete Routine')
      })
  }

  postRoutine(newRoutine, history) {
    var url = '/dashboard/routines/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }

    const prevToken = localStorage.getItem('prevToken')
    if (prevToken) {
      newRoutine.coach = localStorage.getItem('prevToken')
    }
    const token = 'Token ' + localStorage.getItem('token')
    Store.setRoutineCreateWorking(true)
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(newRoutine)
    })
      .then(res => res.json())
      .then(data => {
        if ('name' in data && data.name == 'This field may not be blank.') {
          Store.setRoutineCreateWorking(false)
          return
        }

        if (history) {
          history.push('/routines/')
          Store.setRoutineCreateWorking(false)
        }
      })
      .catch(error => {
        console.log(error)
        Store.setRoutineCreateWorking(false)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  getRoutineDetails(uuid) {
    var url = '/dashboard/routine/details/' + uuid
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + localStorage.getItem('token')
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_SELECTED_ROUTINE,
          payload: data
        })
      })
      .catch(error => {
        console.log(error)
        console.log('Error in retrieve routine')
      })
  }

  getPublicRoutineDetails(client, slug) {
    var url = '/dashboard/routine/details/' + client + '/' + slug
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + localStorage.getItem('token')
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_SELECTED_ROUTINE,
          payload: data
        })
      })
      .catch(error => {
        console.log(error)
        console.log('Error in retrieve routine')
      })
  }

  // Clients

  registerStudent(username, email, password1, password2, history) {
    var url = '/dashboard/register-student/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + localStorage.getItem('prevToken')
    Store.setCreateClientWorking(true)
    const response = fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify({
        username: username,
        // email: email,
        password1: password1,
        password2: password2
      })
    })
      .then(res => res.json())
      .then(data => {
        Store.setCreateClientWorking(false)
        if ('key' in data) {
          console.log(data)
          Dispatcher.dispatch({
            actionType: Constants.SET_CURRENT_TOKEN,
            payload: { token: data.key }
          })
          Dispatcher.dispatch({
            actionType: Constants.SET_ROUTINE_SEARCH_RESULTS,
            payload: { results: [] }
          })
          Dispatcher.dispatch({
            actionType: Constants.GET_CATEGORIES
          })
          Dispatcher.dispatch({
            actionType: Constants.SEARCH_ROUTINES,
            payload: {
              routine: '',
              pageSize: 1000,
              page: 1
            }
          })
          Dispatcher.dispatch({
            actionType: Constants.GET_MY_DETAILS
          })
        } else {
          var errors = []
          for (var key in data) {
            errors.push(data[key])
          }
          Store.setClientRegisterErrors(errors)
        }
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  getMyStudents() {
    var url = '/dashboard/client/students/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + localStorage.getItem('prevToken')
    console.log(token)
    Store.setStudentsWorking(true)
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Store.setStudentsWorking(false)
        Dispatcher.dispatch({
          actionType: Constants.SET_STUDENT_LIST,
          payload: data
        })
      })
      .catch(error => {
        console.log(error)
        Store.getStudentsWorking(false)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  getMyRoutines() {
    var url = '/routines/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + localStorage.getItem('token')
    console.log(token)
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_MY_ROUTINES,
          payload: data
        })
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }

  getTotals() {
    var url = '/dashboard/totals/'
    if (PRODUCTION == true) {
      url = BASE_URL + url
    }
    const token = 'Token ' + localStorage.getItem('token')
    console.log(token)
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(data => {
        Dispatcher.dispatch({
          actionType: Constants.SET_TOTALS,
          payload: data
        })
      })
      .catch(error => {
        console.log(error)
        Dispatcher.dispatch({ actionType: Constants.TOGGLE_API_500 })
      })
  }
}

export default new API()
