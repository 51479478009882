import React from 'react'
import {
  Image,
  List,
  Item,
  ReactPDF,
  Document,
  Page,
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer'

import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { Store, Dispatcher, Constants } from '../flux'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  entryContainer: {
    marginBottom: 10
  },
  date: {
    fontSize: 11
    // fontFamily: 'Lato Italic',
  }
  //   detailContainer: {
  //     flexDirection: 'row',
  //   },
  //   detailLeftColumn: {
  //     flexDirection: 'column',
  //     marginLeft: 10,
  //     marginRight: 10,
  //   },
  //   detailRightColumn: {
  //     flexDirection: 'column',
  //     flexGrow: 9,
  //   },
  //   bulletPoint: {
  //     fontSize: 10,
  //   },
  //   details: {
  //     fontSize: 10,
  //     // fontFamily: 'Lato',
  //   },
  //   headerContainer: {
  //     flexDirection: 'row',
  //     marginBottom: 10,
  //   },
  //   leftColumn: {
  //     flexDirection: 'column',
  //     flexGrow: 9,
  //   },
  //   rightColumn: {
  //     flexDirection: 'column',
  //     flexGrow: 1,
  //     alignItems: 'flex-end',
  //     justifySelf: 'flex-end',
  //   },
  //   title: {
  //     fontSize: 11,
  //     color: 'black',
  //     textDecoration: 'none',
  //     // fontFamily: 'Lato Bold',
  //   },
})

const delay = t => new Promise(resolve => setTimeout(resolve, t))

async function getProps() {
  await delay(1000)
  return {
    routine: Store.getSelectedRoutine()
  }
}

const ExperienceEntry = ({ company, details, position, date }) => {
  const title = `${company} | ${position}`
  return (
    <View style={styles.entryContainer}>
      <View style={styles.headerContainer}>
        <View style={styles.leftColumn}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.date}>{date}</Text>
        </View>
      </View>
      {/* <List>
        {details.map((detail, i) => (
        <Item key={i} style={styles.detailContainer}>
            {detail}
        </Item>
        ))}
    </List> */}
    </View>
  )
}

// Create Document Component
const MyDocument = ({ routine }) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.section}>
        {routine.exercises.map(exercise => (
          <View>
            <Image
              style={{ wdith: 100 }}
              src={{
                uri: exercise.image,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
                body: ''
              }}
            />
            <View>
              <Text>{exercise.name}</Text>
            </View>
            <View style={styles.rightColumn}>
              <Text style={styles.date}>{exercise.length}</Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
)

const PDFGenerator = () => (
  <button
    onClick={async () => {
      const props = await getProps()
      const doc = <MyDocument {...props} />
      const asPdf = pdf() // {} is important, throws without an argument
      asPdf.updateContainer(doc)
      const blob = await asPdf.toBlob()
      saveAs(blob, 'document.pdf')
    }}
  >
    Download PDF
  </button>
)

export default PDFGenerator
