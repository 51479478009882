import React from 'react'
import { Store, Dispatcher, Constants } from '../../../../flux'
import { withRouter } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { Alert, Row, Modal, ModalBody, ModalHeader, Button } from 'shards-react'

import withWindowDimensions from '../../../../components/common/withWindowDimensions'

import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PersonSharpIcon from '@material-ui/icons/PersonSharp'
import DescriptionIcon from '@material-ui/icons/Description'
import Select from 'react-select'
import AddIcon from '@material-ui/icons/Add'

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderWidth: 0,
    color: 'black'
    // padding: 20,
  }),
  control: (provided, state) => ({
    ...provided,
    borderWidth: 0,
    color: 'lightseagreen',
    backgroundColor: 'whitesmoke'
    // padding: 20,
  })
}

class TopNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getAppState()
    this.state.searchOpen = false
    this.getAppState = this.getAppState.bind(this)
    this._onChange = this._onChange.bind(this)
    this.state.toggleActive = true
    this.onSwitch = this.onSwitch.bind(this)
    this.handleRoutineSearchChange = this.handleRoutineSearchChange.bind(this)
    this.handleRoutineSearchClick = this.handleRoutineSearchClick.bind(this)
    this.handleExerciseSearchChange = this.handleExerciseSearchChange.bind(this)
    this.handleExerciseSearchClick = this.handleExerciseSearchClick.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleMainMenu = this.toggleMainMenu.bind(this)
    this.renderFullMenu = this.renderFullMenu.bind(this)
    this.switchToStudent = this.switchToStudent.bind(this)
    this.renderSearchInput = this.renderSearchInput.bind(this)
    this.state.open = false
    this.state.menuOpen = false
  }

  componentDidMount() {
    if (!Store.getToken()) {
      console.log('No requests  yet')
    } else {
      setTimeout(function() {
        Dispatcher.dispatch({
          actionType: Constants.GET_MY_DETAILS
        })
      }, 1)
    }

    setTimeout(function() {
      Dispatcher.dispatch({ actionType: Constants.GET_STUDENT_LIST })
    }, 0.5)

    Store.addChangeListener(this._onChange)
    this.setState(this.getAppState)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this._onChange)
  }
  _onChange() {
    this.setState(this.getAppState)
  }

  getAppState() {
    return {
      routineSearchTerm: Store.getRoutineSearchTerm(),
      exerciseSearchTerm: Store.getExerciseSearchTerm(),
      routineLibraryWorking: Store.getRoutineLibraryWorking(),
      exerciseLibraryWorking: Store.getExerciseLibraryWorking(),
      routineEditMode: Store.getRoutineEditMode(),
      myDetails: Store.getMyDetails(),
      pdfDownloadWorking: Store.getPDFDownloadWorking(),
      clients: Store.getStudentList(),
      token: Store.getToken(),
      clientRegisterErrors: Store.getClientRegisterErrors(),
      categories: Store.getCategories()
    }
  }

  onSwitch() {
    const prevToken = localStorage.getItem('prevToken')
    if (localStorage.getItem('prevToken') != null) {
      localStorage.setItem('token', prevToken)
      localStorage.removeItem('prevToken')
      this.props.history.push('/clients/')
    } else {
      this.props.history.push('/clients/')
    }
  }

  handleRoutineSearchChange(e) {
    e.preventDefault()
    Store.setRoutineSearchTerm(e.target.value)
  }

  handleRoutineSearchClick(e) {
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_ROUTINES,
      payload: {
        routine: Store.getRoutineSearchTerm(),
        page: 1,
        category: Store.getSelectedCategory()
      }
    })
    if (this.props.location.pathname != '/routines/') {
      this.props.history.push('/routines/')
    }
  }

  toggle() {
    this.setState({
      open: !this.state.open
    })
  }

  toggleMainMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }
  handleExerciseSearchChange(e) {
    e.preventDefault()
    Store.setExerciseSearchTerm(e.target.value)
  }

  handleExerciseSearchClick() {
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: {
        exercise: Store.getExerciseSearchTerm(),
        page: 1,
        category: Store.getSelectedCategory(),
        pageSize: 999
      }
    })
    if (this.props.location.pathname != '/exercises/') {
      this.props.history.push('/exercises/')
    }
  }

  toggleMenu() {
    this.setState({ toggleActive: !this.state.toggleActive })
  }

  ensureNoMappingError(myDetails) {
    if (myDetails['completed_details'] == undefined) {
      myDetails['completed_details'] = []
    }
    if (myDetails['setup_progress'] == undefined) {
      myDetails['setup_progress'] = {
        goal_setup: false,
        routines_setup: false,
        routine_completed: false
      }
    }
    if (myDetails['routines'] == undefined) {
      myDetails['routines'] = []
    }
    return myDetails
  }

  handleRoutineCategorySearch(event) {
    const category = event.value
    Store.setSelectedCategory(category)
    Dispatcher.dispatch({
      actionType: Constants.SET_ROUTINE_SEARCH_RESULTS,
      payload: { results: [] }
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_ROUTINES,
      payload: { routine: '', page: 1, category: category }
    })
  }

  handleExerciseCategorySearch(event) {
    let category = event.value
    Store.setSelectedCategory(category)
    Dispatcher.dispatch({
      actionType: Constants.SET_EXERCISE_SEARCH_RESULTS,
      payload: { results: [] }
    })
    Dispatcher.dispatch({
      actionType: Constants.SEARCH_EXERCISES,
      payload: { exercise: '', pageSize: 999, page: 1, category: category }
    })
  }

  renderSearchInput(widthPercent = null) {
    let routineSearchPages = [
      '/routines/',
      '/my-recovery/',
      '/create-routine/',
      '/routines',
      '/my-recovery'
    ]

    const options = []
    for (let i = 0; i < this.state.categories.length; i++) {
      options.push({
        value: this.state.categories[i].name,
        label: this.state.categories[i].name
      })
    }

    // EXERCISE SEARCH
    if (!routineSearchPages.includes(this.props.location.pathname)) {
      // if (!this.state.routineEditMode){return null}
      return (
        <div
          class='no-focus-color'
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <span style={{ width: '30%' }}>
            <Select
              isDisabled={!this.state.routineEditMode}
              onChange={event => this.handleExerciseCategorySearch(event)}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                border: 'none',
                colors: {
                  ...theme.colors,
                  primary25: 'lavenar',
                  primary: 'lightseagreen'
                }
              })}
              styles={customStyles}
              placeholder={'Select category'}
              options={options}
            />
          </span>
          <input
            disabled={!this.state.routineEditMode}
            onChange={this.handleExerciseSearchChange}
            className='search-extra'
            value={this.state.exerciseSearchTerm}
            type='text'
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.handleExerciseSearchClick(event)
              }
            }}
            style={{
              fontSize: '15px',
              color: 'black',
              backgroundColor: 'whitesmoke',
              width: '50%',
              padding: '7.5px 14px',
              // height: "100%",
              borderRadius: '0px 0px 0px 0px',
              border: '1px solid #d9d9d9',
              borderWidth: '0px 0px 0px 0px',
              height: 38,

              borderColor: 'lightgrey',
              textAlign: 'left'
            }}
            placeholder='Search Exercises'
          />
          {/* <button
          onClick={this.handleRoutineSearchClick}
          type="submit"
          className="search-extra"
          style={{
            padding: "7px 20px",
            // border: "none",
            fontSize: "12px",
            // background: "whitesmoke",
            textAlign: "center",
            backgroundColor: "whitesmoke",
            // color: "whitesmoke",
            borderColor: "white",
            textDecoration: "none",

            border: "2px solid lightgrey",
            borderWidth: "0px 0px 2px 0px",
            borderRadius: "0px 5px 5px 0px",
            border: "none",
            height: 35
          }}
        >
          {this.state.routineLibraryWorking ? (
            <ReactLoading
              className="searchLoading"
              type="spokes"
              color="black"
              height={"100"}
              width={"31"}
            />
          ) : (
            <ReactLoading
              className="searchLoading"
              type="spokes"
              color="whitesmoke"
              height={"40"}
              width={"40"}
            />
          )}
        </button> */}
        </div>
      )

      // ROUTINE SEARCH
    } else {
      return (
        <div
          class='no-focus-color'
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <span style={{ width: '50%' }}>
            <Select
              onChange={event => this.handleRoutineCategorySearch(event)}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'lavenar',
                  primary: 'transaprent'
                }
              })}
              styles={customStyles}
              placeholder={'Select category'}
              options={options}
            />
          </span>
          <input
            onChange={this.handleRoutineSearchChange}
            className='search-extra'
            value={this.state.routineSearchTerm}
            type='text'
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.handleRoutineSearchClick(event)
              }
            }}
            style={{
              fontSize: '15px',
              color: 'black',
              backgroundColor: 'whitesmoke',
              width: '60%',
              padding: '7.5px 14px',
              // height: "100%",
              borderRadius: '0px 0px 0px 0px',
              border: '1px solid #d9d9d9',
              borderWidth: '0px 0px 0px 0px',
              height: 38,

              borderColor: 'lightgrey',
              textAlign: 'left'
            }}
            placeholder='Search Routines'
          />
          {/* <button
            onClick={this.handleRoutineSearchClick}
            type="submit"
            className="search-extra"
            style={{
              padding: "7px 20px",
              // border: "none",
              fontSize: "12px",
              // background: "whitesmoke",
              textAlign: "center",
              backgroundColor: "whitesmoke",
              // color: "whitesmoke",
              borderColor: "white",
              textDecoration: "none",

              border: "2px solid lightgrey",
              borderWidth: "0px 0px 2px 0px",
              borderRadius: "0px 5px 5px 0px",
              border: "none",
              height: 35
            }}
          >
            {this.state.routineLibraryWorking ? (
              <ReactLoading
                className="searchLoading"
                type="spokes"
                color="black"
                height={"100"}
                width={"31"}
              />
            ) : (
              <ReactLoading
                className="searchLoading"
                type="spokes"
                color="whitesmoke"
                height={"40"}
                width={"40"}
              />
            )}
          </button> */}
        </div>
      )
    }
  }

  renderSearchMenu() {
    return (
      <div>
        <div className='fixed-header'>
          <Row
            style={{
              // borderBottom: "1px solid #d9d9d9",
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: 'white'
            }}
          >
            <span
              onClick={() => {
                this.setState({ searchOpen: false })
              }}
              style={{
                marginLeft: 10,
                alignItems: 'center',
                display: 'flex',
                width: '10%'
              }}
            >
              <ion-icon style={{ fontSize: 22 }} name='arrow-back' />
            </span>
            <span style={{ display: 'flex', width: '80%' }}>
              {' '}
              {this.renderSearchInput('80%')}
            </span>
          </Row>
        </div>

        <div style={{ height: '70px' }} />
      </div>
    )
  }

  switchToStudent(e, client) {
    for (let i = 0; i < this.state.clients.length; i++) {
      console.log(this.state.clients[i].username)
      console.log(client.username)
      if (this.state.clients[i].username === client.username) {
        Dispatcher.dispatch({
          actionType: Constants.SET_CURRENT_TOKEN,
          payload: { token: this.state.clients[i].token }
        })
        Dispatcher.dispatch({
          actionType: Constants.SET_ROUTINE_SEARCH_RESULTS,
          payload: { results: [] }
        })
        Dispatcher.dispatch({
          actionType: Constants.GET_CATEGORIES
        })
        Dispatcher.dispatch({
          actionType: Constants.SEARCH_ROUTINES,
          payload: {
            routine: this.state.routineSearchTerm,
            pageSize: 1000,
            page: 1
          }
        })
        Dispatcher.dispatch({
          actionType: Constants.GET_MY_DETAILS
        })
      }
    }
  }

  onRegister(e) {
    e.preventDefault()
    Dispatcher.dispatch({
      actionType: Constants.REGISTER_ERRORS,
      payload: ''
    })
    Dispatcher.dispatch({ actionType: Constants.TOGGLE_REGISTER_WORKING })
    const password =
      'myroutine' +
      Math.floor(Math.random() * 10 + 1) +
      Math.floor(Math.random() * 10 + 1) +
      Math.floor(Math.random() * 10 + 1)
    Dispatcher.dispatch({
      actionType: Constants.REGISTER_STUDENT,
      payload: {
        email: 'test@gmail.com',
        username: this.state.clientUsername,
        password1: password,
        password2: password,
        history: this.props.history
      }
    })
  }

  getAlert() {
    const { clientRegisterErrors } = this.state
    if (clientRegisterErrors.length > 0) {
      let errors = ''
      for (let i = 0; i < clientRegisterErrors.length; i++) {
        errors += '\n ' + clientRegisterErrors[i]
      }

      return (
        <Alert className='mb-0' theme='warning'>
          {' '}
          <i className='fa fa-info mx-2' /> {errors}
        </Alert>
      )
    }
  }

  clientsListOrHint(clients, clientLength) {
    // Disable hint video
    if (clientLength != 0) {
      return (
        <div>
          {' '}
          <div class='styled-table-wrap'>
            <table class='styled-table'>
              <thead>
                <tr>
                  <th>CLIENT</th>
                  <th>PROGRESS </th>
                </tr>
              </thead>
              <tbody>{clients}</tbody>
            </table>
          </div>
        </div>
      )
    }

    return (
      <div>
        <p>
          To get started, create your first client by entering their name below.
        </p>
      </div>
    )
  }

  renderFullMenu() {
    const myDetails = this.ensureNoMappingError(this.state.myDetails)
    let clients = ''
    if (Array.isArray(this.state.clients)) {
      clients = this.state.clients.map(student => (
        <tr className='client-list-item'>
          <td
            theme='light'
            onClick={e => this.switchToStudent(e, student)}
            className='client-list-item'
            style={{
              cursor: 'pointer',
              padding: 15,
              fontSize: 14,
              width: '100%'
            }}
          >
            <span style={{ color: 'black' }} href={'javascript:void(0)'}>
              {student.username}
            </span>
          </td>

          <td
            onClick={e => this.switchToStudent(e, student)}
            style={{
              cursor: 'pointer',
              padding: 15,
              fontSize: 14,
              width: '100%',
              textAlign: 'left'
            }}
          >
            {' '}
            {student.completed}
          </td>
        </tr>
      ))
    }
    return (
      <div>
        <Modal open={!this.state.token} toggle={false}>
          <ModalBody>
            {this.clientsListOrHint(clients, this.state.clients.length)}
            <form style={{ paddingTop: 15 }} onSubmit={e => this.onRegister(e)}>
              <span>
                <input
                  class='client-add-button'
                  type='text'
                  name='username'
                  id='username'
                  placeholder='Enter clients name'
                  required
                  onChange={e => {
                    this.setState({ clientUsername: e.target.value })
                  }}
                />
              </span>
              <button
                style={{
                  backgroundColor: 'lightseagreen',
                  color: 'white',
                  margin: 5,
                  display: 'inline',
                  fontSize: '16px',
                  textAlign: 'center',
                  padding: 8,
                  borderRadius: 3,
                  cursor: 'pointer',
                  border: '1px solid #d9d9d9',
                  width: '20%',
                  float: 'right',
                  fontSize: 14
                }}
                // onClick={() => this.handleAddRoutine(selectedRoutine.uuid)}
                // theme="success"
                type='submit'
              >
                Add Client
              </button>
              {this.getAlert()}
            </form>
          </ModalBody>
        </Modal>

        <Modal open={this.state.menuOpen} toggle={this.toggleMainMenu}>
          {/* <ModalHeader>Menu</ModalHeader> */}
          <ModalBody style={{ textAlign: 'center' }}>
            <div>
              <a
                className='bm-item'
                href='javascript:void(0)'
                onClick={() => {
                  this.props.history.push('/routines/')
                }}
              >
                Home
              </a>
            </div>
            <div>
              <a
                className='bm-item'
                href='javascript:void(0)'
                onClick={() => {
                  this.props.history.push('/new/', {
                    hardRefresh: true
                  })
                }}
              >
                Create Routine
              </a>
            </div>
            <div>
              <a
                className='bm-item'
                href='javascript:void(0)'
                onClick={() => {
                  this.props.history.push('/client-details/')
                }}
              >
                Client Details
              </a>
            </div>
            <div>
              <a
                className='bm-item'
                href='javascript:void(0)'
                onClick={() => {
                  this.props.history.push('/my-recovery/')
                }}
              >
                Client Progress
              </a>
            </div>
            <div>
              <a
                className='bm-item'
                href='javascript:void(0)'
                onClick={() => {
                  this.props.history.push('/logout/')
                }}
              >
                Logout
              </a>
            </div>
          </ModalBody>
        </Modal>

        <Modal open={this.state.open} size='sm' toggle={this.toggle}>
          {/* <ModalHeader style={{justifyContent: "center"}}><h4>Install The RecoverHub app to use the program. </h4></ModalHeader>
           */}
          <ModalBody>
            <div style={{ color: 'black' }}>
              <b>username</b> {myDetails.username}
            </div>
            <div style={{ color: 'black' }}>
              <b>password</b> {myDetails.app_password}
            </div>

            <div style={{ marginTop: 15 }}>
              <div>
                {' '}
                <a
                  target='_blank'
                  rel='noopener'
                  style={{
                    display: 'inline-block',
                    overflow: 'hidden',
                    borderRadius: '13px',
                    width: '250px',
                    height: '83px'
                  }}
                  href='https://play.google.com/store/apps/details?id=com.jacobfionn.recover'
                >
                  <img
                    alt='Android app on Google Play'
                    src='https://developer.android.com/images/brand/en_app_rgb_wo_60.png'
                  />
                </a>
              </div>

              <img
                width={180}
                src='http://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.jacobfionn.recover&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L'
                alt='qr code'
              />
              <hr />

              <div style={{ marginBottom: 15 }}>
                <a
                  href='https://apps.apple.com/us/app/recoverhub/id1560641825?itsct=apps_box&itscg=30200'
                  target='_blank'
                  rel='noopener'
                >
                  <img
                    src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us/'
                    alt='Download on the App Store'
                    style={{ width: '170px' }}
                  />
                </a>
              </div>
            </div>

            <img
              width={180}
              src='https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/544b3a9ee4414eb5417ffc0c033fc4c1/577c3f4f9b6cce80e425c51f8f90ca4b.png'
            />
            <br />
          </ModalBody>
        </Modal>
        <div className='fixed-header'>
          <Row
            style={{
              // borderBottom: "1px solid #d9d9d9",
              // marginLeft: 10,
              height: 60,

              backgroundColor: 'white'
            }}
          >
            {/* <SideBar
              onSwitch={this.onSwitch}
              history={this.props.history}
              pageWrapId={"page-wrap"}
              outerContainerId={"App"}
            ></SideBar> */}

            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row'
              }}
            >
              <div
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  width: '30%'
                }}
              >
                <span
                  style={{
                    marginLeft: 10
                  }}
                >
                  <span
                    onClick={() => {
                      this.toggleMainMenu()
                    }}
                    style={{
                      color: 'white',
                      fontSize: 20
                    }}
                  >
                    <span
                      style={{
                        borderRadius: 50,
                        cursor: 'pointer',
                        marginRight: 4,
                        // backgroundColor: "#e4e6eb",
                        // padding: 3,

                        marginLeft: 15,
                        // backgroundColor: "#1ca099",
                        paddingLeft: 5,
                        paddingRight: 5,
                        display: 'inline-block',
                        height: 35,
                        textAlign: 'center',
                        color: 'black'
                      }}
                    >
                      <span
                        style={{
                          color: 'lightseagreen',
                          fontSize: 26,
                          fontFamily: 'Montserrat, sans-serif'
                        }}
                      >
                        RecoverHub
                      </span>
                    </span>
                  </span>
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40%'
                }}
              >
                {' '}
                {this.renderSearchInput()}
              </div>

              <div
                style={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: 15,
                  width: '30%'
                }}
              >
                <span
                  title='Switch Client'
                  onClick={() => {
                    Dispatcher.dispatch({
                      actionType: Constants.GET_STUDENT_LIST
                    })
                    Dispatcher.dispatch({
                      actionType: Constants.SET_CURRENT_TOKEN,
                      payload: { token: '' }
                    })
                  }}
                  style={{
                    // borderRadius: 50,
                    cursor: 'pointer',
                    marginRight: 4,
                    backgroundColor: 'whitesmoke',

                    paddingTop: 5,
                    color: 'black',
                    marginLeft: 0,
                    fontSize: 10,
                    // backgroundColor: "white",
                    width: 100,
                    height: 45,
                    textAlign: 'center',
                    fontWeight: '500'
                    // border: "1px solid #d9d9d9"
                  }}
                >
                  <span style={{ marginTop: 0 }}>
                    <PersonSharpIcon
                      style={{
                        color: 'lightseagreen',
                        fontSize: 22,
                        marginBottom: 3
                      }}
                    />{' '}
                  </span>
                  <div> {this.state.myDetails.username_clipped}</div>
                </span>

                <span
                  onClick={() => {
                    this.props.history.push('/new/')
                  }}
                  style={{
                    // borderRadius: 50,
                    cursor: 'pointer',
                    marginRight: 15,
                    // backgroundColor: "#e4e6eb",
                    // padding: 5,
                    color: 'dimgrey',
                    marginLeft: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                    fontSize: 12,
                    // backgroundColor: "whitesmoke",
                    display: 'inline-block',

                    textAlign: 'center'
                  }}
                >
                  <Button
                    theme='success'
                    style={{
                      border: 'none',
                      backgroundColor: 'whitesmoke',
                      color: 'black',
                      boxShadow: 'none'
                    }}
                  >
                    {' '}
                    <AddIcon />
                  </Button>
                </span>
                {/* <span
                  onClick={this.toggleMainMenu}
                  style={{
                    borderRadius: 50,
                    cursor: "pointer",
                    marginRight: 17,
                    // backgroundColor: "#e4e6eb",
                    padding: 5,
                    color: "white",
                    paddingLeft: 5,
                    paddingRight: 5,
                    backgroundColor: "#1ca099",
                    display: "inline-block",
                    height: 35,
                    textAlign: "center"
                  }}
                >
                  <Tooltip title="Send to App" aria-label="Send To App">
                    <MenuOutlinedIcon style={{ fontSize: 24 }} />
                  </Tooltip>
                </span> */}
                {/* <span
                  onClick={() => {
                    this.props.history.push("/logout/");
                  }}
                  style={{
                    cursor: "pointer",
                    marginRight: 40
                    // color: "#a7a7a7",
                  }}
                >
                  <Tooltip title="Logout" aria-label="Logout">
                    <ExitToAppSharpIcon
                      style={{ fontSize: 28, color: "white" }}
                    />
                  </Tooltip>
                </span> */}
              </div>
            </div>
          </Row>
        </div>

        <div
          style={{
            height: '60px'
          }}
        />
      </div>
    )
  }

  render() {
    return this.renderFullMenu()
  }
}

export default withRouter(withWindowDimensions(TopNav))
